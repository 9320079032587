._32qa button {
  opacity: .4
}

._59ov {
  height: 100%;
  height: 910px;
  position: relative;
  top: -10px;
  width: 100%
}

._5ti_ {
  background-size: cover;
  height: 100%;
  width: 100%
}

._5tj2 {
  height: 900px
}

._2mm3 ._5a8u .uiBoxGray {
  background: #fff;
  margin: 0;
  padding: 12px
}

._2494 {
  height: 100vh
}

._2495 {
  margin-top: -10px;
  top: 10px
}

._1m42 {
  display: block
}

._1w_m ._1m42 img,
._53s ._1m42 i {
  -webkit-filter: brightness(50%) blur(5px);
  transition: filter .5s ease-out
}

._5v3q ._1m42::before,
._1m42::before {
  animation: rotateSpinner 1.2s linear infinite;
  background-image: url(https://rsrc.php/v3/y2/r/onuUJj0tCqE.html);
  border: 0;
  content: '';
  display: inline-block;
  height: 24px;
  left: 50%;
  margin: -12px -12px;
  position: absolute;
  top: 50%;
  width: 24px;
  z-index: 10
}

#bootloader_px5CI {
  height: 42px;
}

.bootloader_px5CI {
  display: block !important;
}