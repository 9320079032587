._6vg {
  border-radius: 1px
}

._605a ._6vg {
  border-radius: 50%;
  overflow: hidden
}

._2wk7 {
  width: 16px
}

html {
  touch-action: manipulation
}

body {
  background: #fff;
  color: #1c1e21;
  direction: ltr;
  line-height: 1.34;
  margin: 0;
  padding: 0;
  unicode-bidi: embed
}

body,
button,
input,
label,
select,
td,
textarea {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1c1e21;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  padding: 0
}

h1 {
  font-size: 14px
}

h4,
h5,
h6 {
  font-size: 12px
}

p {
  margin: 1em 0
}

b,
strong {
  font-weight: 600
}

a {
  color: #385898;
  cursor: pointer;
  text-decoration: none
}

button {
  margin: 0
}

a:hover {
  text-decoration: underline
}

img {
  border: 0
}

td,
td.label {
  text-align: left
}

dd {
  color: #000
}

dt {
  color: #606770
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0
}

abbr {
  border-bottom: none;
  text-decoration: none
}

hr {
  background: #dadde1;
  border-width: 0;
  color: #dadde1;
  height: 1px
}

.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden
}

.clearfix {
  zoom: 1
}

.datawrap {
  word-wrap: break-word
}

.word_break {
  display: inline-block
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.aero {
  opacity: .5
}

.column {
  float: left
}

.center {
  margin-left: auto;
  margin-right: auto
}

#facebook .hidden_elem {
  display: none !important
}

#facebook .invisible_elem {
  visibility: hidden
}

#facebook .accessible_elem {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

#facebook .accessible_elem_offset {
  margin: -1px
}

.direction_ltr {
  direction: ltr
}

.direction_rtl {
  direction: rtl
}

.text_align_ltr {
  text-align: left
}

.text_align_rtl {
  text-align: right
}

._3bww,
._3bwv {
  height: 100%;
  width: 100%
}

._3bwv {
  display: table
}

._3bwv>._3bwy {
  display: table-cell;
  vertical-align: middle
}

._3bww>._3bwy>._3bwx {
  display: table;
  margin: 0 auto
}

._5bpf {
  height: 100%
}

._3bww>._3bwy>._2qgx {
  width: 700px
}

._5f0d {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: text-bottom
}

._5i4g {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0
}

._5i4g._5sjv {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: pixelated
}

._1qe- {
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2
}

._1qe_ {
  display: table-row
}

._1qf0 {
  display: table-cell;
  text-align: center;
  vertical-align: middle
}

._5lar {
  z-index: 1
}

._ezo {
  cursor: pointer
}

._1yib {
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 401
}

._2alo {
  position: absolute
}

._6st5 ._ezo {
  background-position-y: bottom;
  background-repeat: repeat no-repeat;
  background-size: 10px 4px;
  margin-bottom: -2px;
  padding-bottom: 2px;
  white-space: pre-line
}

._4-eo {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .41);
  display: block
}

._3-a6 ._4-eo {
  box-shadow: none
}

._5vsj ._4-eo {
  display: inline-block
}

._5v3q ._4-eo {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  position: relative
}

._5v3q ._3-a6 ._4-eo._2rn3 {
  box-shadow: none
}

._5v3q ._4-eo::after {
  border: 1px solid rgba(0, 0, 0, .1);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

._1ktf ._4-eo::after {
  border-left: none;
  border-right: none
}

._5v3q ._3-a6 ._4-eo::after {
  border: none
}

._5tmf ._2t9n img,
._5tmf ._2t9n._4-eo,
._5tmf ._2t9n._4-eo::after {
  border-radius: 5px 5px 0 0
}

._1ktf {
  margin-left: -12px
}

._1ktf._4-eo:hover::after {
  background-color: rgba(29, 33, 41, .04)
}

._1ktf._4-eo:active::after {
  background-color: rgba(29, 33, 41, .08)
}

._9_q ._4-eo {
  width: initial !important
}

._5v3q ._4-eo._9_q,
._9_q {
  background: #000;
  box-shadow: none
}

._9_q ._4-ep {
  margin: 0 auto
}

._3-a6 ._2rn3 ._4-ep .img {
  border-radius: 18px
}

._2rn3 {
  display: inline-block
}

._3-a6 ._2rn3 {
  border: 1px solid #ebedf0;
  border-radius: 18px;
  margin-top: 2px;
  overflow: hidden
}

._4iv9 {
  margin: 5px 0
}

._5-g_ ._8jnd {
  display: none
}

._8jnd {
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 16px;
  bottom: 8px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .3), 0 2px 4px 0 rgba(0, 0, 0, .1);
  cursor: pointer;
  display: flex;
  height: 32px;
  left: 8px;
  padding: 0 12px;
  pointer-events: none;
  position: absolute;
  transition: bottom .3s ease 0s;
  z-index: 2
}

._8o0h {
  bottom: 58px
}

._8jne {
  padding-right: 7px
}

._360g ._8jnd {
  display: none
}

._-iv {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 14px 0 2px 0;
  max-height: 44px;
  overflow: hidden;
  word-wrap: break-word
}

._-iw {
  color: #90949c
}

._-ix {
  margin-top: 14px
}

._d5z {
  max-width: 70%
}

._6m2 {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 0
}

.fbInternVideoPreview ._6m2 {
  z-index: 12
}

._2lhm ._6m2._6m2 {
  background-color: transparent
}

._3906._6m2 {
  overflow: visible
}

._3906._6m2 ._3907 {
  overflow: hidden;
  position: relative
}

._6m3 {
  font-size: 12px;
  height: 132px;
  margin: 10px 12px;
  position: relative
}

._59ap ._6m3._--6._k-h {
  font-size: 12px;
  height: 100px;
  margin: 10px 12px;
  position: relative
}

._59ap ._6m3._--6._7eb5 {
  font-size: 12px;
  height: 77px;
  margin: 10px 12px;
  position: relative
}

._6m3._5oi5 {
  height: 100%;
  margin: 0 12px 0 12px
}

._5oi5 ._522u {
  z-index: 1
}

._5oi5 ._6m6,
._5oi5 ._6m7 {
  text-overflow: ellipsis
}

._51cc ._6m3,
._3eqz._5cwb._51cc ._6m3 {
  height: 215px
}

._5ej3 ._6m3 {
  height: 88px
}

._4y_8 ._6m3 {
  height: 142px;
  margin: 6px 12px
}

._59ap ._6m3 {
  height: auto;
  margin: 10px 12px;
  max-height: 100px
}

._59ap ._6m3._649o,
._59ap._41u- ._6m3._649o {
  max-height: none
}

._649o {
  display: flex;
  flex-flow: column
}

._649o ._59tj {
  order: 0
}

._649o ._2dbt {
  margin-bottom: 0;
  order: 1
}

._649o ._6m7 {
  order: 2
}

._59ap ._649o {
  margin-top: 0
}

._59ap._6m4 ._649o ._59tj,
._5cwb._6m4 ._649o ._59tj {
  margin-top: 0
}

._649o ._2dbs {
  padding-top: 3px
}

._pb2 ._6m3 {
  height: 76px;
  margin: 6px 12px
}

._59ap._41u- ._6m3 {
  max-height: 110px
}

._3eqz._59ap._41u- ._6m3 {
  max-height: 190px
}

._6m2.exploded .lfloat {
  float: none
}

._6m2.exploded ._6m3 {
  float: none;
  height: auto;
  margin-bottom: 12px
}

._5cwb._5cwb._5cwb,
._6m4._6m4._6m4 {
  max-width: none
}

._37iz._37iz._37iz {
  max-width: -webkit-max-content;
  max-width: max-content
}

._6m4 ._6m3 {
  height: auto;
  margin-bottom: 9px
}

._6ks {
  line-height: 0;
  position: relative;
  z-index: 1
}

._6ks a:focus {
  outline: none
}

._63yw {
  position: relative
}

._6m5 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  height: 158px;
  width: 158px
}

._51cc ._6m5 {
  height: 237px;
  width: 158px
}

._5ej3 ._6m5 {
  height: 40px;
  width: 40px
}

._51cc .__fq {
  top: 206px
}

._6m2:hover ._6m5 {
  color: rgba(0, 0, 0, .15)
}

._6m6,
._6m6._6m6._6m6._6m6 {
  font-family: Georgia, Lucida Grande, Tahoma, Verdana, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
  max-height: 110px;
  word-wrap: break-word
}

._5cwb._359m:not(._3eqz) ._6m6 {
  padding-right: 10px
}

._pb2 ._6m6,
._pb2 ._6m6._6m6._6m6._6m6 {
  font-size: 16px;
  line-height: 20px
}

._6m6,
._6m6 a {
  text-decoration: none;
  transition: color .1s ease-in-out
}

._6m6 a {
  color: #1d2129
}

._6m2:hover ._275z {
  color: #1d2129
}

._6m2 ._6jx ._6m6 a {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px
}

._6m2 ._6jx ._6m6._6m6 {
  margin-bottom: 0
}

._6m4._6m8 ._6m6 {
  margin-bottom: 0
}

._6m8 ._6m6 {
  max-height: 88px
}

._6m6 ._w_i {
  margin-right: 5px;
  position: relative;
  top: 3px
}

._6m7._6m7._6m7._6m7 {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 16px;
  max-height: 80px;
  overflow: hidden
}

._6m7 a,
._20l4 a {
  color: inherit;
  position: relative;
  z-index: 1
}

._6m7 a:hover {
  cursor: pointer;
  text-decoration: none
}

._4rtc ._6m7 {
  text-overflow: ellipsis;
  white-space: nowrap
}

._59ap ._59tj {
  padding-top: 9px;
  position: relative
}

._59ap .__fq {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

._59ap .__fq .__fr {
  left: 20px
}

._59ap._6m8 ._59tj,
._41u- ._6m3 ._59tj,
._170x ._59ap ._59tj {
  padding-top: 5px
}

._170x ._59ap._6m8 ._59tj {
  padding-top: 21px
}

._59ap._6m8 ._6jx ._59tj {
  padding-top: 3px
}

._5cwb ._59tj {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0
}

._5cwb ._4eyp ._522u {
  bottom: 0;
  position: absolute;
  right: 0
}

._5cwb.exploded ._59tj {
  margin-top: 9px;
  position: static
}

._5cwb._6m4 ._59tj {
  margin-top: 10px;
  position: relative
}

._59ap ._59tj a:hover {
  text-decoration: none
}

._5cwb._6m4._6m8 ._59tj {
  margin-top: 5px
}

._6m3 ._6mb,
._6m3 ._d5q,
._6m3 ._59tj ._6mb a {
  color: #90949c
}

._6m3 ._6mb {
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase
}

._6m3 ._1t62 {
  color: #606770;
  font-size: 12px
}

._6m3 ._d5q {
  font-size: 12px;
  line-height: 12px
}

._6m3 ._5x4b {
  margin: -1px 1px -4px 0
}

._41u- ._6m3 ._6mb,
._170x ._41u- ._6m3 ._6mb,
._170x ._6m3 ._6mb {
  padding-top: 13px
}

._41u-._61bj._6m4 ._6m3 ._6mb {
  padding-top: 0
}

._41u- ._6m3 ._6mb.ellipsis {
  padding-right: 8px
}

.Locale_zh_HK ._6m3 ._6mb,
.Locale_zh_TW ._6m3 ._6mb,
.Locale_zh_CN ._6m3 ._6mb {
  line-height: 12px
}

._59tj ._522u {
  padding-left: 10px
}

._522u {
  position: relative
}

._41u- ._522u {
  bottom: -4px;
  z-index: 2
}

._pb2._41u- ._522u {
  bottom: -2px
}

._59ap._41u- ._522u {
  bottom: 0
}

._59ap._41u- ._522u,
._6m4._41u- ._522u {
  position: relative
}

._4eyp ._522u {
  margin-top: -8px
}

._59ap ._2r3x>._ohe {
  width: 100%
}

._170x ._6m2 {
  margin: 0 -12px
}

._170x ._6m3 {
  border-bottom: 1px solid #fff;
  margin-bottom: 0;
  padding-bottom: 10px
}

._hye ._6m7,
._170x ._6m7 {
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap
}

._hye ._6m6,
._170x ._6m6 {
  height: 22px;
  text-overflow: ellipsis
}

._5tmf ._6m8 ._2r3x>._ohe {
  width: 100%
}

._2r3x ._4g6m {
  background-color: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 50%;
  margin-left: -40px;
  margin-top: -15px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 70%;
  width: 30px;
  z-index: 2
}

._2r3x:hover ._4g6m {
  opacity: 1
}

._2r3x:hover ._4g6m:hover {
  transform: scale(1.1)
}

._2r3x ._1nu {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -78px -1748px;
  height: 18px;
  margin: 6px 6px;
  width: 18px
}

._2r3x ._4g6n {
  background-color: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 50%;
  margin-left: 10px;
  margin-top: -15px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 70%;
  width: 30px;
  z-index: 2
}

._2r3x:hover ._4g6n {
  opacity: 1
}

._2r3x:hover ._4g6n:hover {
  transform: scale(1.1)
}

._2r3x ._1nw {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -66px -1854px;
  height: 18px;
  margin: 6px 6px;
  width: 18px
}

._6m3 ._59tj ._6mb ._2iv2 {
  color: #3b5998;
  position: relative;
  text-decoration: none;
  z-index: 5
}

._6m3 ._59tj ._6mb ._2iv2:hover {
  text-decoration: underline
}

._6m3 ._59tj ._5tc6 {
  color: #90949c;
  margin-top: 5px
}

._6m3 ._59tj ._1cen {
  padding-left: 10px;
  padding-top: 5px
}

._6m3 ._59tj ._3vkw {
  float: left;
  margin-right: 5px
}

._41u- ._6m3 ._1cen ._6mb {
  padding-top: 0
}

._1cep {
  margin-bottom: 5px
}

._41u- ._6m3 ._59tj._2616 {
  padding-top: 0
}

._2iv3 {
  border-top: 1px solid #e5e5e5;
  height: auto;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 6px;
  position: relative;
  width: auto;
  z-index: 5
}

._2iv4 {
  float: left;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 4px;
  margin-top: 4px;
  max-width: 75%;
  width: auto
}

._2iv5 {
  float: right;
  margin-bottom: 7px;
  width: auto
}

._5qqr ._6m6,
._5qqr ._6m7 {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%
}

._2ikp {
  border-top: solid 1px #ebedf0;
  margin: 10px 12px;
  padding-top: 11px
}

._2iku {
  color: #4b4f56;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-top: 16px;
  position: relative;
  text-transform: uppercase;
  z-index: 10
}

._2iku a {
  color: #4b4f56;
  z-index: 10
}

._3-a6 ._pb2 ._2r3x {
  border: 1px solid #ebedf0;
  border-radius: 18px;
  margin-top: 2px;
  overflow: hidden
}

._3-a6 ._5pcr ._pb2 ._2r3x {
  border: none;
  border-radius: 0;
  margin-top: 0;
  overflow: hidden
}

._3eqz {
  border-radius: 5px
}

._3eqw {
  border-radius: 0;
  margin-right: -12px
}

._3b6i ._3eqw {
  margin-left: -11px;
  margin-right: -11px
}

._5p9g ._3eqw,
._3-a6 ._3eqw {
  margin-left: 0;
  margin-right: 0
}

._3b6i ._3eqw {
  margin-bottom: -11px
}

._6m2._3eqz._3eqw {
  max-width: none
}

._3eqz {
  background-color: #f2f3f5
}

._3eqz._267a {
  background-color: #fff
}

._3eqz._5cwb ._6m3 {
  display: flex;
  flex-direction: column;
  height: 136px;
  justify-content: center
}

._3eqz._6m4 ._6m3,
._3eqz._5ej3._6m4 ._6m3 {
  height: auto;
  max-height: 136px
}

.UFICommentContent ._3eqz._5cwb ._6m3 {
  height: 76px
}

.UFICommentContent ._3eqz._6m4._5cwb ._6m3 {
  height: auto
}

._3ekx {
  position: relative
}

._3eqz ._3ekx {
  border-bottom: 1px solid #dddfe2;
  border-right: 1px solid #dddfe2;
  margin: 0;
  padding: 10px 12px;
  position: relative
}

._3eqz._59ap ._3ekx {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #dddfe2
}

._3eqz._5cwb ._3ekx {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px
}

._3eqz._3eqw._5cwb ._3ekx,
._3eqz._3eqw ._3ekx {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left: none;
  border-right: none;
  border-top-right-radius: 0
}

._3eqz._5cwb ._3ekx {
  border-top: 1px solid #dddfe2
}

._3b6i ._3eqz._3eqw ._3ekx {
  border-bottom: none
}

._3b6i ._5cwb._3eqz ._3ekx {
  padding-bottom: 10px
}

._3b6i ._5cwb._3eqz._3eqw ._3ekx {
  padding-bottom: 11px
}

._3-a6 ._3eqz ._3ekx {
  border: none
}

._3eqz ._3ekx:empty {
  padding: 0
}

._3eqz ._44ae {
  display: flex;
  flex-direction: row;
  justify-content: flex-end
}

._3eqz ._6m3 {
  margin: 0
}

._3eqz._59ap ._6m5,
._3eqz._59ap ._6m5 .img {
  height: 100%;
  width: 100%
}

._3eqz._5cwb._51cc._6m4 ._6m3,
._3eqz._6m4 ._6m3 {
  height: auto
}

._3eqz._5ej3 ._6m3 {
  height: 117px
}

._3eqz ._44ae ._6m3 {
  flex-grow: 1;
  overflow-x: auto
}

.ie11 ._3eqz ._44ae ._6m3 {
  flex: 1;
  max-width: 95%;
  width: 45%
}

@supports (-ms-ime-align: auto) {
  ._3eqz ._44ae ._6m3 {
    flex: 1;
    max-width: 95%;
    width: 45%
  }
}

._3eqz._5cwb ._6m3 ._59tj {
  position: static
}

._3eqz ._6m3 ._59tj {
  color: #606770;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap
}

._3eqz._41u- ._6m3 ._6mb,
._3eqz._41u- ._6m3 ._59tj ._1t62 {
  padding: 0
}

._3eqz._5cwb._6m4._6m8 ._59tj,
._3eqz._5cwb._6m4 ._59tj {
  margin-top: 2px
}

._3eqz ._6m3 ._59tj ._5tc6 {
  color: #606770;
  margin-top: 3px
}

#facebook ._3eqz ._6m3 ._6m6 {
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 3px 0 0;
  padding-top: 2px
}

#facebook ._3eqz._2rk1 ._6m3 ._6m6 {
  font-weight: 600;
  overflow: hidden
}

._3eqz ._6m3 ._6m7._6m7,
._3eqz ._6m3 ._6m7._6m7 .fsm,
._3eqz ._6m3 ._6m7._6m7 .fwn,
._3eqz ._6m3 ._6m7._6m7 .fcg,
._3eqz ._6m3 ._20l4._20l4,
._3eqz ._6m3 ._20l4._20l4 .fsm,
._3eqz ._6m3 ._20l4._20l4 .fwn,
._3eqz ._6m3 ._20l4._20l4 .fcg {
  color: #606770;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word
}

._3eqz ._6m3 ._6m7._6m7 a:hover,
._3eqz ._6m3 ._20l4._20l4 a:hover {
  text-decoration: underline
}

._3eqz ._6m3 ._6m7._6m7,
._3eqz ._6m3 ._20l4._20l4 {
  margin-top: 3px
}

._3eqz ._44af {
  align-self: center;
  padding-left: 18px
}

._3n1j ._6m6,
._3n1j ._6m7 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal
}

._3n1j ._6m6 {
  -webkit-line-clamp: 2
}

._3n1j ._6m7 {
  -webkit-line-clamp: 1
}

._3n1j ._6m6:empty~._6m7 {
  -webkit-line-clamp: 2
}

._3n1j ._3n1k {
  max-height: 46px;
  overflow: hidden
}

.fixed_elem,
.fixed_always {
  position: fixed !important
}

.tinyHeight .fixed_elem {
  position: static !important
}

.chrome .fixed_elem,
.chrome .fixed_always {
  transform: translateZ(0)
}

.tinyHeight .chrome .fixed_elem {
  transform: none
}

._1k48 {
  cursor: default
}

._1k48:hover {
  text-decoration: none
}

._li._li._li {
  overflow: initial
}

._aj3e,
._aj3e video {
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: -1
}

._aowd._li._li._li {
  overflow: hidden
}

._9053 ._li._li._li {
  overflow-x: hidden
}

._72b0 {
  position: relative;
  z-index: 0
}

.registration ._li._9bpz {
  background-color: #f0f2f5
}

._li ._9bp- {
  padding-top: 5px;
  text-align: center
}

._li ._9bp- .fb_logo {
  height: 100px
}

._li ._a66f {
  padding-top: 5px;
  text-align: center
}

._li ._a66f .fb_logo {
  height: 80px;
  padding-top: 72px
}

._6kf_ {
  border: 1px solid #ebedf0;
  margin-left: 2px;
  margin-right: -4px
}

._6n1z {
  height: 44px;
  width: 60px
}

._6ljg {
  height: 40px;
  position: relative;
  width: 40px
}

._6rig {
  background-color: rgba(0, 0, 0, .3);
  background-size: cover;
  height: 40px;
  left: 0;
  margin-left: 3px;
  margin-top: 1px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: .5s ease;
  width: 40px;
  z-index: 1
}

._6ljg:hover ._6rig {
  opacity: 1
}

._6ljh {
  background-color: rgba(0, 0, 0, .3);
  background-size: cover;
  height: 40px;
  left: 0;
  margin-left: 3px;
  margin-top: 1px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
  z-index: 1
}

._605a ._6rig,
._6ljh {
  border-radius: 50%;
  overflow: hidden
}

._6lji {
  height: 40px;
  left: 0;
  margin-top: -1px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
  z-index: 1
}

._5_my {
  display: inline-block;
  min-height: 10px;
  min-width: 10px;
  width: 100%
}

._68qf:before {
  background: #373737;
  color: #fff;
  content: 'Wait Time';
  font-size: 8px;
  padding: 0 1px;
  pointer-events: all;
  position: absolute;
  z-index: 2
}

._46-h {
  overflow: hidden;
  position: relative
}

._46-i {
  position: absolute
}

body.plugin {
  background: transparent;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.28;
  overflow: hidden;
  -webkit-text-size-adjust: none
}

.plugin,
.plugin button,
.plugin input,
.plugin label,
.plugin select,
.plugin td,
.plugin textarea {
  font-size: 11px
}

._56q9 {
  font-size: 14px;
  line-height: 1.358;
  word-break: break-word;
  word-wrap: break-word
}

._44ri {
  border-left: 1px dotted #d3d6db
}

#facebook ._1e_f {
  overflow: visible
}

._2vq9 {
  font-size: 12px;
  line-height: 1.358;
  padding-top: 2px
}

._2vq9 a {
  color: #4267b2
}

._2vq9 .livetimestamp,
._2vq9 .uiLinkSubtle {
  color: #90949c
}

._4iy4 {
  bottom: -2px;
  position: relative
}

._5mlf {
  color: #ccc;
  font-style: italic
}

._4q1v {
  color: #90949c;
  display: inline
}

._4q1v a {
  color: #90949c
}

._5mdd {
  word-wrap: break-word
}

._5mdd ._5v47 {
  font-size: 14px
}

._5mdd a {
  color: #4267b2
}

@media (max-width: 480px) {
  ._4q1v {
    display: block;
    font-size: 11px
  }
}

._2392 {
  margin-bottom: 24px
}

._3j_p {
  margin-left: 56px
}

._4uyl ._1cb {
  border: 1px solid #d3d6db;
  word-wrap: break-word
}

._1zz8 ._5yk2,
._1zz8 ._1u9t {
  min-height: 40px
}

._1zz7 ._5yk2,
._1zz6 ._5yk2 {
  min-height: 16px
}

._1zz7 ._1u9t,
._1zz6 ._1u9t {
  min-height: 48px
}

._1u9t {
  box-sizing: border-box;
  vertical-align: top;
  width: 100%
}

._4uyl ._5yk2,
._4uyl ._1u9t {
  font-size: 16px;
  padding: 12px 8px
}

._4uyl ._1p1t {
  color: #a9a9a9
}

._5tr6 {
  background: #f5f6f7;
  text-align: right
}

._5tr6 button,
._5tr6 ._42fr:active,
._5tr6 ._42fs {
  font-size: 14px;
  white-space: normal
}

._5pu_ {
  color: #999
}

._5sge {
  background-color: #fafafa;
  border-bottom: 1px solid #d3d6db;
  color: #90949c;
  font-size: 11px
}

._4uym {
  border: 1px solid #d3d6db;
  border-top: 0
}

._1y5f {
  color: #90949c;
  font-size: 11px
}

._1y5g {
  max-width: 140px
}

@media (max-width: 480px) {
  ._2ern {
    font-size: 11px
  }
}

._5lm5 {
  border-top: 1px solid #e9ebee;
  font-size: 11px
}

._491z {
  border-bottom: 1px solid #e9ebee;
  line-height: 25px;
  margin-bottom: 24px;
  padding: 8px 0
}

._52bm {
  color: #e9ebee;
  padding: 0 8px
}

._pup {
  color: #4b4f56
}

._491z ._33ki {
  font-size: 16px
}

@media (max-width: 480px) {
  ._4k-6 ._5nz1:not(:first-child) {
    border-top: 1px dotted #d3d6db;
    padding-top: 12px
  }
}

._5o4h ._1gl3 {
  background: #3578e5;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
  padding: 12px;
  text-shadow: none;
  width: 100%
}

._ndl {
  margin-top: 24px;
  padding: 11px
}

._3uik {
  background: #f5f6f7;
  border-radius: 1px;
  color: #365899;
  display: block;
  font-weight: bold
}

._1ci {
  height: 48px;
  width: 48px
}

._1cj {
  height: 36px;
  width: 36px
}

@media (max-width: 480px) {

  ._1ci,
  ._1cj {
    height: 32px;
    width: 32px
  }
}

._5yct {
  background-color: #f5f6f7;
  color: #365899
}

._4cqr {
  position: relative
}

._4cqr:after {
  content: '\A';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

._64nf {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -1982px;
  height: 16px;
  margin-left: 2px;
  width: 16px
}

._64nf._5dzz {
  width: 16px
}

.profileLink ._64nf,
._2u0z ._64nf,
._5vra ._64nf,
._64-f ._64nf,
._52eh ._64nf {
  position: relative;
  top: 3px
}

._5_jv .profileLink ._64nf,
._64-f ._64nf {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -1730px;
  height: 24px;
  margin-left: 4px;
  position: relative;
  top: 5px;
  width: 24px
}

.highContrast ._59c6._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2035px 0 0 -53px;
  position: absolute
}

.highContrast ._59c6._5dzz {
  overflow: hidden;
  position: relative
}

._59c6._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -53px -2035px
}

.highContrast ._59c6._5d-3._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2035px 0 0 -53px;
  position: absolute
}

.highContrast ._59c6._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._59c6._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -53px -2035px
}

.highContrast ._59c6._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2035px 0 0 -53px;
  position: absolute
}

.highContrast ._59c6._5dz- {
  overflow: hidden;
  position: relative
}

._59c6._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -53px -2035px
}

.highContrast ._59c6._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2035px 0 0 -53px;
  position: absolute
}

.highContrast ._59c6._5dz_ {
  overflow: hidden;
  position: relative
}

._59c6._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -53px -2035px
}

._3qcr._5dzy {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -1982px
}

.highContrast ._9o_f._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -963px 0 0 -81px;
  position: absolute
}

.highContrast ._9o_f._5dz- {
  overflow: hidden;
  position: relative
}

._9o_f._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -963px;
  height: 14px;
  width: 14px
}

.uiTypeaheadView .basic ._5dzy {
  float: none
}

.uiTypeaheadView .compact ._5dzy {
  display: inline-block;
  position: static
}

._5dzy {
  display: inline-block
}

._5dzz {
  height: 14px;
  margin-left: 3px;
  vertical-align: -2px;
  width: 14px
}

._5d-3._5dzz {
  height: 16px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 16px
}

._5dz- {
  height: 12px;
  margin-left: 3px;
  vertical-align: -1px;
  width: 12px
}

._5dz_ {
  height: 17px;
  margin-left: 3px;
  vertical-align: -4px;
  width: 17px
}

._5d-0 {
  height: 19px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 19px
}

._5d-1 {
  height: 19px;
  margin-left: 4px;
  vertical-align: -2px;
  width: 19px
}

._6g4u {
  height: 20px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 20px
}

._5d-3._5d-1 {
  height: 26px;
  margin-left: 4px;
  vertical-align: -5px;
  width: 26px
}

._7xv0 {
  margin-left: 2px;
  position: relative;
  top: 1px
}

._7xv0._5dzy {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1062px;
  border-radius: 50%;
  height: 12px;
  top: -1px;
  width: 12px
}

.highContrast ._4fvy._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1919px 0 0 -43px;
  position: absolute
}

.highContrast ._4fvy._5dzz {
  overflow: hidden;
  position: relative
}

._4fvy._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1919px
}

.highContrast ._4fvy._5d-3._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1919px 0 0 -43px;
  position: absolute
}

.highContrast ._4fvy._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._4fvy._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1919px
}

.highContrast ._4fvy._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1919px 0 0 -43px;
  position: absolute
}

.highContrast ._4fvy._5dz- {
  overflow: hidden;
  position: relative
}

._4fvy._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1919px
}

.highContrast ._4fvy._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1919px 0 0 -43px;
  position: absolute
}

.highContrast ._4fvy._5dz_ {
  overflow: hidden;
  position: relative
}

._4fvy._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1919px
}

._59t2._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yX/r/XTBCJQ4brFv.png)
}

._59t2._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yt/r/9P4tFVoazCQ.png)
}

._59t2._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yl/r/seydRWcRiTg.png)
}

._59t2._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/ye/r/ui6i-KsMj_C.png)
}

._59t2._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y_/r/pAT_aWF8wR_.png)
}

.highContrast ._1gop._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2053px 0 0 -54px;
  position: absolute
}

.highContrast ._1gop._5dzz {
  overflow: hidden;
  position: relative
}

._1gop._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -2053px;
  height: 16px;
  width: 16px
}

.highContrast .uiTypeaheadView .selected ._1gop._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2053px 0 0 -36px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._1gop._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._1gop._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -2053px;
  -webkit-filter: brightness(200%);
  height: 16px;
  width: 16px
}

.highContrast ._56_f._5dzz:before,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1005px 0 0 -81px;
  position: absolute
}

.highContrast ._56_f._5dzz,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz {
  overflow: hidden;
  position: relative
}

._56_f._5dzz,
.uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1005px
}

.highContrast ._56_f._5d-3._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1921px 0 0 -65px;
  position: absolute
}

.highContrast ._56_f._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._56_f._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -65px -1921px
}

._56_f._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -950px
}

.highContrast ._56_f._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2055px 0 0 -1px;
  position: absolute
}

.highContrast ._56_f._5dz_ {
  overflow: hidden;
  position: relative
}

._56_f._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -1px -2055px
}

.highContrast ._56_f._5d-0:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1809px 0 0 -25px;
  position: absolute
}

.highContrast ._56_f._5d-0 {
  overflow: hidden;
  position: relative
}

._56_f._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -25px -1809px
}

.highContrast ._56_f._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1877px 0 0 -45px;
  position: absolute
}

.highContrast ._56_f._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -45px -1877px
}

.highContrast ._56_f._5d-3._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1611px 0 0 -1px;
  position: absolute
}

.highContrast ._56_f._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._5d-3._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -1px -1611px
}

.highContrast ._56_f._3vjg._5dzz:before,
.highContrast ._56_f._5d-3._3vjg ._5dzz:before,
.highContrast ._56_f._3vjg._5dz-:before,
.highContrast ._56_f._3vjg._5dz_:before,
.highContrast ._56_f._3vjg._5d-0:before,
.highContrast ._56_f._3vjg._5d-1:before,
.highContrast ._56_f._5d-3._3vjg ._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -821px 0 0 -81px;
  position: absolute
}

.highContrast ._56_f._3vjg._5dzz,
.highContrast ._56_f._5d-3._3vjg ._5dzz,
.highContrast ._56_f._3vjg._5dz-,
.highContrast ._56_f._3vjg._5dz_,
.highContrast ._56_f._3vjg._5d-0,
.highContrast ._56_f._3vjg._5d-1,
.highContrast ._56_f._5d-3._3vjg ._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._3vjg._5dzz,
._56_f._5d-3._3vjg ._5dzz,
._56_f._3vjg._5dz-,
._56_f._3vjg._5dz_,
._56_f._3vjg._5d-0,
._56_f._3vjg._5d-1,
._56_f._5d-3._3vjg ._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -821px
}

.highContrast .uiTypeaheadView .selected ._56_f._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2037px 0 0 -73px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._56_f._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._56_f._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -73px -2037px
}

.highContrast .selected ._56_f._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2055px 0 0 -19px;
  position: absolute
}

.highContrast .selected ._56_f._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._56_f._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -19px -2055px
}

.UFICommentContent ._56_f {
  margin-left: 1px
}

.tooltipContent ._4ag8 {
  overflow: hidden;
  white-space: normal;
  width: 164px
}

.highContrast ._5n3t._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -773px 0 0 -81px;
  position: absolute
}

.highContrast ._5n3t._5dzz {
  overflow: hidden;
  position: relative
}

._5n3t._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -773px
}

.highContrast ._5n3t._5d-3._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2017px 0 0 -17px;
  position: absolute
}

.highContrast ._5n3t._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._5n3t._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -17px -2017px
}

.highContrast ._5n3t._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -805px 0 0 -81px;
  position: absolute
}

.highContrast ._5n3t._5dz- {
  overflow: hidden;
  position: relative
}

._5n3t._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -805px
}

.highContrast ._5n3t._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2019px 0 0 -37px;
  position: absolute
}

.highContrast ._5n3t._5dz_ {
  overflow: hidden;
  position: relative
}

._5n3t._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -37px -2019px
}

.highContrast ._5n3t._5d-0:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1875px 0 0 1px;
  position: absolute
}

.highContrast ._5n3t._5d-0 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 1px -1875px
}

.highContrast ._5n3t._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1727px 0 0 -77px;
  position: absolute
}

.highContrast ._5n3t._5d-1 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -77px -1727px
}

.highContrast ._5n3t._5d-3._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1699px 0 0 -25px;
  position: absolute
}

.highContrast ._5n3t._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-3._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -25px -1699px
}

.highContrast .uiTypeaheadView .selected ._5n3t._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -789px 0 0 -81px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._5n3t._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._5n3t._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -789px
}

.highContrast .selected ._5n3t._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -2019px 0 0 -55px;
  position: absolute
}

.highContrast .selected ._5n3t._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._5n3t._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -55px -2019px
}

.UFICommentContent ._5n3t {
  margin-left: 1px
}

.highContrast ._6w81._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1019px 0 0 -81px;
  position: absolute
}

.highContrast ._6w81._5dzz {
  overflow: hidden;
  position: relative
}

._6w81._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1019px
}

.highContrast ._6w81._5d-3._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1019px 0 0 -81px;
  position: absolute
}

.highContrast ._6w81._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._6w81._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1019px
}

.highContrast ._6w81._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1019px 0 0 -81px;
  position: absolute
}

.highContrast ._6w81._5dz- {
  overflow: hidden;
  position: relative
}

._6w81._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1019px;
  vertical-align: -2px
}

.highContrast ._6w81._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1981px 0 0 -71px;
  position: absolute
}

.highContrast ._6w81._5dz_ {
  overflow: hidden;
  position: relative
}

._6w81._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -71px -1981px
}

.highContrast ._6w81._5d-0:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1853px 0 0 -43px;
  position: absolute
}

.highContrast ._6w81._5d-0 {
  overflow: hidden;
  position: relative
}

._6w81._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1853px
}

.highContrast ._6w81._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1853px 0 0 -43px;
  position: absolute
}

.highContrast ._6w81._5d-1 {
  overflow: hidden;
  position: relative
}

._6w81._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -43px -1853px
}

.highContrast ._6w81._5d-3._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1727px 0 0 -25px;
  position: absolute
}

.highContrast ._6w81._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._6w81._5d-3._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -25px -1727px
}

.highContrast .uiTypeaheadView .selected ._6w81._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1075px 0 0 -81px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._6w81._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._6w81._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1075px
}

.highContrast .selected ._6w81._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1075px 0 0 -81px;
  position: absolute
}

.highContrast .selected ._6w81._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._6w81._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -1075px
}

.UFICommentContent ._6w81 {
  margin-left: 1px
}

._7cto {
  margin: 4px 8px
}

._7ctr {
  border-bottom: 1px solid #dddfe2;
  padding: 4px 0;
  text-align: justify
}

._7cts {
  margin: 8px 0 0 auto
}

._5d62._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -1px -2037px
}

._5d62._5d-3._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -79px -1769px
}

._5d62._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -19px -2037px
}

._5d62._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -37px -2036px
}

._5d62._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -1px -1809px
}

._5d62._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -23px -1877px
}

._5d62._5d-3._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -51px -1555px
}

.highContrast ._8b0y._5d-3._5dzz:before,
.highContrast ._8b0y._5dzz:before,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz:before,
.highContrast .uiTypeaheadView .selected ._8b0y._5dzz:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -991px 0 0 -81px;
  position: absolute
}

.highContrast ._8b0y._5d-3._5dzz,
.highContrast ._8b0y._5dzz,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz,
.highContrast .uiTypeaheadView .selected ._8b0y._5dzz {
  overflow: hidden;
  position: relative
}

._8b0y._5d-3._5dzz,
._8b0y._5dzz,
.uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz,
.uiTypeaheadView .selected ._8b0y._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -991px
}

.highContrast ._8b0y._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -992px 0 0 -82px;
  position: absolute
}

.highContrast ._8b0y._5dz- {
  overflow: hidden;
  position: relative
}

._8b0y._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -992px;
  height: 12px;
  margin-bottom: 1px;
  width: 12px
}

.highContrast ._8b0y._5dz_:before,
.highContrast .selected ._8b0y._5dz_:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1942px 0 0 -80px;
  position: absolute
}

.highContrast ._8b0y._5dz_,
.highContrast .selected ._8b0y._5dz_ {
  overflow: hidden;
  position: relative
}

._8b0y._5dz_,
.selected ._8b0y._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -80px -1942px;
  height: 16px;
  margin-bottom: 1px;
  width: 16px
}

.highContrast ._8b0y._5d-0:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1809px 0 0 -25px;
  position: absolute
}

.highContrast ._8b0y._5d-0 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -25px -1809px
}

.highContrast ._8b0y._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1583px 0 0 -75px;
  position: absolute
}

.highContrast ._8b0y._5d-1 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -75px -1583px
}

.highContrast ._8b0y._5d-3._5d-1:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -1583px 0 0 -49px;
  position: absolute
}

.highContrast ._8b0y._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-3._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -49px -1583px
}

.UFICommentContent ._8b0y {
  margin-left: 1px
}

.tooltipContent ._8b11 {
  overflow: hidden;
  white-space: normal;
  width: 164px
}

.highContrast ._8b-m._5dz-:before {
  content: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  margin: -977px 0 0 -81px;
  position: absolute
}

.highContrast ._8b-m._5dz- {
  overflow: hidden;
  position: relative
}

._8b-m._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -81px -977px;
  height: 14px;
  margin-top: 2px
}

._39_n {
  color: #1d2129
}

._35mm {
  display: inline-block;
  margin-right: 3px
}

._35mm+span:not(.whitespace) {
  margin-left: -3px
}

._6nkz {
  height: 16px;
  outline: none;
  width: 16px
}

._6nkz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -60px -1962px
}

._r1a {
  align-items: center;
  cursor: pointer;
  display: flex !important;
  padding: 0 6px !important;
  position: relative
}

._u77 ._r1a {
  padding: 0 4px !important
}

._u77 ._r1a:last-child {
  padding-right: 6px !important
}

._r1a .UFICommentGifIcon {
  margin: 0 !important
}

._fmi {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dddfe2;
  display: flex;
  flex-wrap: wrap;
  position: relative
}

._613v {
  border-radius: 18px;
  padding-left: 5px
}

.UFICommentPhotoAttachedPreview._4soo {
  background-color: #fff;
  border-color: #ccd0d5;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  margin-top: 4px;
  min-height: 10px;
  padding: 10px;
  position: relative
}

.UFICommentPhotoAttachedPreview._4soo img {
  border-radius: 18px
}

.UFIReplyList ._613v {
  border-radius: 12px
}

._fmi ._2xwx {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word
}

._fmi ._2xww {
  border: 0 !important
}

._613v ._2xww {
  background-color: transparent !important;
  line-height: 1.5
}

._fmi ._5yk2 {
  padding-right: 0 !important
}

._53ij {
  background: #fff;
  background: var(--card-background, #FFFFFF);
  position: relative
}

._53io {
  overflow: hidden;
  position: absolute
}

._53ih ._53io {
  display: none
}

._54ni {
  overflow: hidden;
  white-space: nowrap
}

._54nc,
._54nc:hover,
._54nc:active,
._54nc:focus {
  display: block;
  outline: none;
  text-decoration: none
}

._54nh {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._54nu .img,
._54nu ._54nh {
  display: inline-block;
  vertical-align: middle
}

.uiContextualLayerBelowLeft ._54hy ._54ng,
.uiContextualLayerBelowRight ._54hy ._54ng {
  border-top: none
}

.uiContextualLayerAboveLeft ._54hy ._54ng,
.uiContextualLayerAboveRight ._54hy ._54ng {
  border-bottom: none
}

._2agf {
  word-wrap: normal
}

._2agf._4o_4 {
  display: inline-flex
}

._55pe {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap
}



._5f0v {
  outline: none
}

._3oxt {
  outline: 1px dotted #3b5998;
  outline-color: invert
}

.webkit ._3oxt {
  outline: 5px auto #5b9dd9
}

.win.webkit ._3oxt {
  outline-color: #e59700
}

i.img {
  -ms-high-contrast-adjust: none
}

i.img u {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  left: auto;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

.lfloat {
  float: left
}

.rfloat {
  float: right
}

a.uiLinkSubtle {
  color: #90949c
}

a.uiLinkDark {
  color: #1d2129
}

.uiCloseButton {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  zoom: 1
}

label.uiCloseButton {
  overflow: hidden
}

.uiCloseButton input {
  cursor: pointer;
  opacity: 0;
  outline: none;
  padding: 18px
}

.uiCloseButton {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/4WSewcWboV8.png);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px
}

.uiCloseButtonSmall {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yx/r/ogYrclupeJV.png);
  height: 11px;
  margin-top: 1px;
  width: 11px
}

.uiCloseButtonHuge {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OKMPqm4mvAI.png)
}

.uiCloseButtonDark {
  background-position: left -16px
}

.uiCloseButtonInverted {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yo/r/33RpiRygxAr.png);
  background-position: left -16px
}

.uiCloseButtonGray {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yv/r/BfyboeDDMtw.png)
}

.uiCloseButton:hover,
.uiCloseButton:focus {
  background-position: left -32px
}

.uiCloseButton:active {
  background-position: left -48px
}

.uiCloseButtonSmallDark {
  background-position: left -12px
}

.uiCloseButtonSmallGray {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yh/r/J70rRopvkuz.png)
}

.uiCloseButtonSmallInverted {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/Vi-KTnbdMKr.png);
  background-position: left -12px
}

.uiCloseButtonSmall:hover,
.uiCloseButtonSmall:focus {
  background-position: left -24px
}

.uiCloseButtonSmall:active {
  background-position: left -36px
}

.uiCloseButtonHighContrast {
  display: none
}

.highContrast .uiCloseButtonHighContrast {
  display: inline-block
}

.uiPopover .uiCloseButton {
  background-clip: padding-box;
  border: 1px solid transparent;
  margin: 0
}

.uiPopover.openToggler .uiCloseButton {
  background-position: left -32px
}

.uiPopover.openToggler .uiCloseButtonSmall {
  background-position: left -24px
}

.uiPopover.openToggler .uiCloseButton,
.uiPopover.openToggler .uiCloseButton:active,
.uiPopover.openToggler .uiCloseButton:focus,
.uiPopover.openToggler .uiCloseButton:hover {
  background-color: #6d84b4;
  border: 1px solid #365899;
  border-bottom-color: #4267b2;
  box-shadow: none
}

.uiChevronSelectorButton .uiCloseButton {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yI/r/mOfWmK5FOjt.png);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px
}

._m {
  position: relative
}

._m:focus-within {
  opacity: .6;
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

._m ._3jk {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

._m ._n {
  bottom: 0;
  cursor: inherit;
  font-size: 1000px !important;
  height: 300px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0
}

div._3qw {
  height: auto;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 400
}

._31e {
  position: fixed !important;
  width: 100%
}

.webkit ._42w {
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 1px
}

._3ixn {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0
}

._3qw ._3ixn {
  background-color: rgba(255, 255, 255, .8)
}

._3qx ._3ixn {
  background-color: rgba(0, 0, 0, .9)
}

._4-hy ._3ixn {
  background-color: rgba(0, 0, 0, .4)
}

._99rc ._3ixn {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, .5)
}

._10 {
  height: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 202
}

.platform_dialog ._10 {
  position: absolute
}

._1yv {
  box-shadow: 0 2px 26px rgba(0, 0, 0, .3), 0 0 0 1px rgba(0, 0, 0, .1);
  margin: 0 auto 40px;
  position: relative
}

._t {
  background-color: #fff;
  position: relative
}

._1yw {
  background-color: #6d84b4;
  border: 1px solid #365899;
  border-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold
}

._13,
._14 {
  border-color: #555;
  border-style: solid;
  border-width: 0 1px
}

._13:first-child {
  border-top-width: 1px
}

._13:last-child {
  border-bottom-width: 1px
}

._14 {
  border-bottom-width: 1px
}

.uiLayer {
  outline: none
}

._57-x {
  padding: 36px 0;
  text-align: center
}

._53ip ._53iv {
  padding: 15px
}

._53ip ._53ij {
  border: 1px solid #8c8c8c;
  border: 1px solid rgba(0, 0, 0, .45);
  border-bottom: 1px solid #666;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3)
}

._53ip ._572u {
  padding: 6px 7px 6px 8px
}

._53ip ._5v-0 {
  padding-bottom: 10px
}

._53ip ._53il {
  padding-top: 10px
}

._53ip ._53im {
  padding-right: 10px
}

._53ip ._53ik {
  padding-bottom: 10px
}

._53ip ._53in {
  padding-left: 10px
}

._53ip ._53il ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -2090px;
  height: 9px;
  top: 2px;
  width: 16px
}

._53ip ._53im ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -704px;
  height: 16px;
  right: 2px;
  width: 9px
}

._53ip ._53ik ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -80px -1572px;
  bottom: 2px;
  height: 9px;
  width: 16px
}

._53ip ._53ik._kc ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -18px -2090px
}

._53ip ._53in ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -686px;
  height: 16px;
  left: 2px;
  width: 9px
}

.uiContextualDialogFooterLink {
  background: #f5f6f7;
  border-top: 1px solid #e0e0e0;
  display: block;
  font-weight: bold;
  padding: 7px;
  text-align: center
}

.uiContextualDialogFooterLink:hover {
  background: #6d84b4;
  border-color: #365899;
  color: #fff;
  text-decoration: none
}

.uiScrollableArea {
  direction: ltr;
  height: 100%;
  overflow: hidden;
  position: relative
}

.uiScrollableAreaWrap {
  height: 100%;
  outline: none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative
}

.uiScrollableAreaWrapHorizontal {
  overflow-x: auto
}

.uiScrollableArea .uiScrollableAreaWrap {
  overflow-y: scroll
}

.uiScrollableArea.nofade .uiScrollableAreaWrap,
.uiScrollableArea.fade .uiScrollableAreaWrap {
  margin-right: -30px;
  padding-right: 30px
}

.uiScrollableArea.nofade .uiScrollableAreaBody {
  padding-right: 10px
}

.native .uiScrollableAreaBody,
.no_js .uiScrollableAreaBody {
  width: auto !important
}

.uiScrollableAreaBottomAligned .uiScrollableAreaShadow {
  display: block
}

.uiScrollableAreaBottomAligned .uiScrollableAreaBody {
  height: 100%
}

.uiScrollableAreaBottomAligned .uiScrollableAreaContent {
  bottom: 0;
  position: absolute;
  width: 100%
}

.uiScrollableAreaBody {
  direction: ltr;
  position: relative
}

.uiScrollableAreaTrack {
  bottom: 2px;
  display: block;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 2px;
  -webkit-user-select: none;
  width: 7px;
  z-index: 5
}

.contentAfter .uiScrollableAreaTrack:hover,
.contentBefore .uiScrollableAreaTrack:hover,
.contentAfter.uiScrollableAreaTrackOver .uiScrollableAreaTrack,
.contentBefore.uiScrollableAreaTrackOver .uiScrollableAreaTrack,
.uiScrollableAreaDragging .uiScrollableAreaTrack {
  background-color: rgba(0, 0, 0, .15);
  border-radius: 10px;
  width: 10px
}

.contentBefore.uiScrollableAreaTrackOver,
.contentAfter.uiScrollableAreaTrackOver,
.uiScrollableAreaDragging {
  cursor: default
}

.uiScrollableArea .uiContextualLayerPositioner {
  z-index: 4
}

.uiScrollableAreaShadow,
.native .uiScrollableAreaTrack,
.no_js .uiScrollableAreaTrack {
  display: none
}

.uiScrollableAreaGripper {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, .4);
  border: 1px solid rgba(85, 85, 85, .6);
  border-radius: 7px;
  position: absolute;
  right: 0;
  transition: width 250ms;
  width: 5px
}

.uiScrollableAreaDragging .uiScrollableAreaGripper,
.uiScrollableAreaTrackOver .uiScrollableAreaGripper,
.uiScrollableAreaTrack:hover .uiScrollableAreaGripper {
  width: 8px
}

.uiScrollableArea.contentBefore:before,
.uiScrollableArea.contentAfter:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  width: 100%;
  z-index: 99
}

.uiScrollableArea.contentBefore:before {
  top: 0
}

.uiScrollableArea.contentAfter:after {
  bottom: 0
}

.uiScrollableAreaWithTopShadow.contentBefore:before,
.uiScrollableAreaWithShadow.contentAfter:after {
  background-color: rgba(0, 0, 0, .07)
}

._569t ._54ng {
  background-color: #fff;
  border: 1px solid #777;
  border-bottom: 2px solid #293e6a
}

._569t ._54nf {
  padding: 3px 0 4px
}

._569t ._54ak {
  border-bottom: 1px solid #ddd;
  margin: 5px 7px 6px;
  padding-top: 1px
}

._569t ._54nc {
  border: solid #fff;
  border-width: 1px 0;
  color: #111;
  font-weight: normal;
  line-height: 16px;
  padding: 1px 16px 1px 22px
}

._569t ._5arm ._54nc {
  cursor: default;
  opacity: .55
}

._569t ._54ne ._54nc {
  background-color: #4267b2;
  border-color: #29487d;
  color: #fff;
  -webkit-font-smoothing: antialiased
}

._569t ._54nd ._54nc {
  background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/P47kLYnMad2.png) left 4px no-repeat;
  font-weight: bold
}

.highContrast ._569t ._54nd ._54nc::before {
  content: '\2713';
  left: 6px;
  position: absolute
}

._569t ._54nd._54ne ._54nc {
  background-color: #4267b2;
  background-position: left -56px
}

._569t ._54ah {
  color: #999
}

._569t ._54nh {
  max-width: 300px
}

._569t ._54hx {
  border-top: 1px solid #777;
  position: absolute;
  right: 0;
  top: -1px
}

.uiContextualLayerAboveLeft ._569t ._54hx,
.uiContextualLayerAboveRight ._569t ._54hx {
  border-top: 2px solid #293e6a;
  top: auto
}

.uiContextualLayerAboveRight ._569t ._54hx,
.uiContextualLayerBelowRight ._569t ._54hx {
  left: 0;
  right: auto
}

.uiContextualLayerPositioner {
  height: 0;
  position: absolute;
  z-index: 202
}

.uiContextualLayer {
  position: absolute
}

div.uiContextualLayerPositionerFixed {
  position: fixed
}

.uiContextualLayerParent {
  position: relative
}

#globalContainer.bizWebLoginContainer {
  overflow-x: hidden
}

.uiOverlay {
  position: relative;
  z-index: 202
}

.uiOverlayContent {
  background: #fff;
  border: 1px solid #8c8c8c;
  border: 1px solid rgba(0, 0, 0, .45);
  border-bottom: 1px solid #666;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
  position: relative
}

.uiOverlayFooter {
  text-align: right
}

.uiOverlayFooterGrid {
  width: 100%
}

.uiOverlayFooterMessage {
  width: 100%
}

.uiOverlayFooterButtons {
  white-space: nowrap
}

.uiTooltipX {
  max-width: 334px;
  word-wrap: break-word;
  position: relative;
  zoom: 1
}

.uiTooltipX .tooltipContent {
  background-color: #282828;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 8px;
  text-align: left
}

.uiTooltipX .tooltipText span {
  zoom: 1
}

.uiTooltipX i.arrow {
  border: 4px solid transparent;
  font-size: 0;
  height: 0;
  line-height: 0;
  position: absolute;
  width: 0
}

.uiContextualLayerAboveLeft>.uiTooltipX,
.uiContextualLayerAboveRight>.uiTooltipX,
.uiContextualLayerAboveCenter>.uiTooltipX {
  margin-bottom: 1px;
  padding-bottom: 4px
}

.uiContextualLayerAboveLeft>.uiTooltipX i.arrow,
.uiContextualLayerAboveRight>.uiTooltipX i.arrow,
.uiContextualLayerAboveCenter>.uiTooltipX i.arrow {
  border-bottom: 0;
  border-top-color: #282828;
  bottom: 0
}

.uiContextualLayerBelowLeft>.uiTooltipX,
.uiContextualLayerBelowRight>.uiTooltipX,
.uiContextualLayerBelowCenter>.uiTooltipX {
  margin-top: 1px;
  padding-top: 4px
}

.uiContextualLayerBelowLeft>.uiTooltipX i.arrow,
.uiContextualLayerBelowRight>.uiTooltipX i.arrow,
.uiContextualLayerBelowCenter>.uiTooltipX i.arrow {
  border-bottom-color: #282828;
  border-top: 0;
  top: 0
}

.uiContextualLayerAboveLeft>.uiTooltipX i.arrow,
.uiContextualLayerBelowLeft>.uiTooltipX i.arrow {
  left: 12px
}

.uiContextualLayerAboveRight>.uiTooltipX i.arrow,
.uiContextualLayerBelowRight>.uiTooltipX i.arrow {
  right: 12px
}

.uiContextualLayerAboveCenter>.uiTooltipX i.arrow,
.uiContextualLayerBelowCenter>.uiTooltipX i.arrow {
  left: 50%;
  margin-left: -4px
}

.uiContextualLayerLeft>.uiTooltipX {
  margin-right: 1px;
  padding-right: 4px
}

.uiContextualLayerLeft>.uiTooltipX i.arrow {
  border-left-color: #282828;
  border-right: 0;
  right: 0;
  top: 10px
}

.uiContextualLayerRight>.uiTooltipX {
  margin-left: 1px;
  padding-left: 4px
}

.uiContextualLayerRight>.uiTooltipX i.arrow {
  border-left: 0;
  border-right-color: #282828;
  left: 0;
  top: 10px
}

.uiScaledImageContainer {
  position: relative;
  overflow: hidden
}

.uiScaledImageCentered {
  background-color: #f5f6f7;
  text-align: center
}

.uiScaledImageContainer img {
  height: 100%;
  min-height: 100%;
  position: relative
}

.uiScaledImageContainer .scaledImageFitWidth {
  height: auto;
  min-height: initial;
  width: 100%
}

.uiScaledImageContainer .scaledImageFitHeight {
  height: 100%;
  min-height: initial;
  width: auto
}

.uiScaledImageContainer .verticallyAligned {
  min-height: 0;
  vertical-align: middle
}

a._p {
  display: block
}

.openToggler {
  z-index: 100
}

.uiToggleFlyout,
.toggleTargetClosed,
.openToggler .uiToggleFlyout .uiToggleFlyout {
  display: none
}

.openToggler .uiToggleFlyout,
.openToggler .uiToggleFlyout .openToggler .uiToggleFlyout {
  display: block
}

.hideToggler {
  border: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 0
}

._36gl {
  position: absolute;
  right: 12px;
  top: 14px
}

._21es {
  background: #3578e5;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  padding: 10px 34px 12px 10px;
  position: relative
}

._6dh- ._21es {
  background: #373737
}

._izg {
  padding-right: 10px
}

._2x6q ._53ij {
  background-color: transparent
}

._2x6q ._5v-0,
._2x6q ._53ik {
  padding-bottom: 13px
}

._2x6q ._53il {
  padding-top: 13px
}

._2x6q ._53im {
  padding-right: 13px
}

._2x6q ._53in {
  padding-left: 13px
}

._2x6q ._53il ._53io {
  border-bottom: 9px solid #3578e5;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  top: 4px
}

._2x6q._6dh- ._53il ._53io {
  border-bottom-color: #373737
}

._2x6q ._53ik ._53io {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #3578e5;
  bottom: 4px
}

._2x6q._6dh- ._53ik ._53io {
  border-top-color: #373737
}

._2x6q ._53in ._53io {
  border-bottom: 9px solid transparent;
  border-right: 9px solid #3578e5;
  border-top: 9px solid transparent;
  left: 4px
}

._2x6q._6dh- ._53in ._53io {
  border-right-color: #373737
}

._2x6q ._53im ._53io {
  border-bottom: 9px solid transparent;
  border-left: 9px solid #3578e5;
  border-top: 9px solid transparent;
  right: 4px
}

._2x6q._6dh- ._53im ._53io {
  border-left-color: #373737
}

.__xn,
.__xn a {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-shadow: 0 0 2px rgba(0, 0, 0, .3)
}

._8wpp {
  background-color: var(--fds-white-text);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--fds-highlight);
  display: inline-block;
  margin: 0 2px;
  min-width: 28px;
  padding: 0 5px;
  text-align: center;
  text-shadow: none
}

.__xn a {
  text-decoration: underline
}

._4jy0 {
  border: 1px solid;
  border-radius: 2px;
  box-sizing: content-box;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  justify-content: center;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-shadow: none;
  vertical-align: middle
}

.segoe ._4jy0 {
  font-weight: 600
}

._4jy0:before {
  content: '';
  display: inline-block;
  height: 20px;
  vertical-align: middle
}

html ._4jy0:focus {
  box-shadow: 0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15);
  outline: none
}

._19_u ._4jy0:focus,
._4jy0._5f0v:focus {
  box-shadow: none
}

._4jy0 {
  transition: 200ms cubic-bezier(.08, .52, .52, 1) background-color, 200ms cubic-bezier(.08, .52, .52, 1) box-shadow, 200ms cubic-bezier(.08, .52, .52, 1) transform
}

._4jy0:active {
  transition: none
}

.mac ._4jy0:not(._42fr):active {
  box-shadow: none;
  transform: scale(.98)
}

._4jy0 .img {
  bottom: 1px;
  position: relative;
  vertical-align: middle
}

form.async_saving ._4jy0 .img,
a.async_saving._4jy0 .img,
._4jy0._42fr .img {
  opacity: .5
}

._517h,
._59pe:focus,
._59pe:hover {
  background-color: #f5f6f7;
  border-color: #ccd0d5;
  color: #4b4f56
}

._64lx ._517h,
._64lx ._59pe:focus,
._64lx ._59pe:hover {
  background-color: #eff1f3;
  border-color: #bec3c9
}

._517h:hover {
  background-color: #ebedf0
}

._517h:active,
._517h._42fs {
  background-color: #dddfe2;
  border-color: #bec3c9
}

form.async_saving ._517h,
a.async_saving._517h,
._517h._42fr {
  background-color: #f5f6f7;
  border-color: #dddfe2;
  color: #bec3c9
}

._517h._42fs {
  color: #4080ff
}

._4jy1,
._9w8q,
._4jy2 {
  color: #fff
}

._4jy1 {
  background-color: #4267b2;
  border-color: #4267b2
}

._4jy1:hover {
  background-color: #365899;
  border-color: #365899
}

._4jy1:active,
._4jy1._42fs {
  background-color: #29487d;
  border-color: #29487d
}

form.async_saving ._4jy1,
a.async_saving._4jy1,
._4jy1._42fr {
  background-color: #9cb4d8;
  border-color: #9cb4d8
}

._4jy2 {
  background-color: #42b72a;
  border-color: #42b72a
}

._4jy2:hover {
  background-color: #36a420;
  border-color: #36a420
}

._4jy2:active,
._4jy2._42fs {
  background-color: #2b9217;
  border-color: #2b9217
}

form.async_saving ._4jy2,
a.async_saving._4jy2,
._4jy2._42fr {
  background-color: #ace0a2;
  border-color: #ace0a2
}

._9w8q {
  background-color: #fa3e3e;
  border-color: #fa3e3e
}

._9w8q:hover {
  background-color: #db1d24;
  border-color: #db1d24
}

._9w8q:active,
._9w8q._42fs {
  background-color: #c70b11;
  border-color: #c70b11
}

form.async_saving ._9w8q,
a.async_saving._9w8q,
._9w8q._42fr {
  background-color: #f77c7c;
  border-color: #f77c7c
}

._59pe,
form.async_saving ._59pe,
a.async_saving._59pe,
._59pe._42fr {
  background: none;
  border-color: transparent
}

._517i,
._517i._42fr:active,
._517i._42fr._42fs {
  height: 18px;
  line-height: 18px
}

._4jy3,
._4jy3._42fr:active,
._4jy3._42fr._42fs {
  line-height: 22px
}

._4jy4,
._4jy4._42fr:active,
._4jy4._42fr._42fs {
  line-height: 26px;
  padding: 0 10px
}

._4jy5,
._4jy5._42fr:active,
._4jy5._42fr._42fs {
  line-height: 34px;
  padding: 0 16px
}

._4jy6,
._4jy6._42fr:active,
._4jy6._42fr._42fs {
  line-height: 42px;
  padding: 0 24px
}

._51xa ._4jy0 {
  border-radius: 0;
  display: inline-block;
  margin: 0 !important;
  margin-left: -1px !important;
  position: relative;
  z-index: 1
}

._51xa>._4jy0:first-child,
._51xa>:first-child ._4jy0 {
  border-radius: 2px 0 0 2px;
  margin-left: 0 !important
}

._51xa>._4jy0:last-child,
._51xa>:last-child ._4jy0 {
  border-radius: 0 2px 2px 0
}

._51xa>._4jy0:only-child,
._51xa>:only-child ._4jy0 {
  border-radius: 2px
}

._51xa ._4jy0._42fr {
  z-index: 0
}

._51xa ._4jy0._4jy1,
._51xa ._4jy0._9w8q,
._51xa ._4jy0._4jy2 {
  z-index: 2
}

._51xa ._4jy0:focus {
  z-index: 3
}

._51xa ._4jy1+.uiPopover>._4jy1:not(:focus):after {
  background-color: #f5f6f7;
  bottom: -1px;
  content: '';
  display: block;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1px
}

._4jy0._52nf {
  cursor: default
}

._9c6._9c6 {
  background-clip: padding-box;
  border-color: rgba(0, 0, 0, .4)
}

._3y89 ._4jy0 {
  border-bottom-width: 0;
  border-top-width: 0
}

._3y89>._4jy0:first-child,
._3y89>:first-child ._4jy0 {
  border-left-width: 0;
  border-radius: 1px 0 0 1px
}

._3y89>._4jy0:last-child,
._3y89>:last-child ._4jy0 {
  border-radius: 0 1px 1px 0;
  border-right-width: 0
}

._6n1z._4jy6,
._6n1z._4jy6._42fr:active,
._6n1z._4jy6._42fr._42fs {
  padding: 0 0
}

._6n1z._517h,
._6n1z._59pe:focus,
._6n1z._59pe:hover {
  background-color: #fff;
  border-color: transparent
}

._aiv4 {
  opacity: .4
}

._aiv4::after {
  animation: xuiButtonLoadingSpinner 1s ease infinite;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: #000;
  bottom: 0;
  content: '';
  height: 12px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 12px
}

@keyframes xuiButtonLoadingSpinner {
  from {
    transform: rotate(0turn)
  }

  to {
    transform: rotate(1turn)
  }
}

._9l2i ._9l2g,
._9l2i ._1yv {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
  width: 565px !important
}

._9l2i ._4t2a,
._9l2i ._9l18 {
  background-color: transparent
}

._9l2i ._9l19,
._9l2i ._9l1d {
  box-sizing: border-box;
  margin: auto;
  width: 565px
}

._9l2i ._9l1d ._9l16,
._9l2i ._9l16 {
  background-color: #fff;
  border-bottom: none;
  padding: 18px 16px
}

._9l2i ._9l16 .clearfix {
  align-items: center;
  display: flex;
  justify-content: space-between
}

._9l2i ._9l16 .clearfix::after {
  display: none
}

._9l2i ._9l16 ._9l17 {
  font-size: 20px;
  line-height: 24px
}

._9l2i ._9l16 ._9l15,
._9l2i ._9l16 ._9l15:hover {
  background-color: #e4e6eb;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yn/r/J-J3z0h9x9f.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 50%;
  display: block;
  height: 36px;
  width: 36px
}

._9l2i ._9l16 ._9l15:hover {
  background-color: #bec3c9
}

._9l2i ._pig {
  padding: 12px 16px
}

._9l2i ._pig ._9l1a {
  color: #606770;
  font-size: 15px;
  line-height: 19px
}

._9l2i ._5a8u {
  border-top: none;
  display: flex;
  justify-content: flex-end;
  margin: 0 16px;
  padding: 12px 0
}

._9l2i ._5a8u ._9l2h,
._9l2i ._9l1d ._9l2j {
  align-items: center;
  background-color: #216fdb;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  font-size: 15px;
  height: 36px;
  justify-content: center;
  line-height: 20px;
  margin-left: 20px;
  width: 121px
}

._9l2i ._5a8u ._9l2k {
  align-items: center;
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: #216fdb;
  display: flex;
  font-size: 15px;
  height: 36px;
  justify-content: center;
  line-height: 20px;
  margin: 0;
  padding: 0
}

._9l2i ._5a8u ._9l2k:hover {
  background-color: transparent
}

._9l2i ._5a8u ._9l2k:after {
  display: none
}

._59s7 {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 26px rgba(0, 0, 0, .3), 0 0 0 1px rgba(0, 0, 0, .1);
  font-family: Helvetica, Arial, sans-serif;
  margin: 0 auto 40px;
  position: relative
}

._4t2a {
  background-color: #fff;
  border-radius: 3px;
  position: relative
}

._4-i0 {
  background-color: #f5f6f7;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 3px 3px 0 0;
  color: #1d2129;
  font-weight: bold;
  line-height: 19px;
  padding: 10px 12px
}

._4-i0 ._ohe {
  max-width: 100%
}

._2gb3 ._ohe {
  max-width: calc(100% - 40px)
}

._4-i0 ._52c9 {
  color: #1d2129;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._2g9z {
  padding: 6px 8px
}

._4-i0 ._2g9- {
  padding: 4px
}

._2g9_ {
  margin-top: 4px
}

._2qes {
  float: left
}

._2qet {
  display: inline-block;
  line-height: 25px;
  margin-left: 10px;
  vertical-align: middle
}

._4-i0._5dwa {
  line-height: 12px
}

._4-i0._5dwa ul {
  float: left
}

div._4-i2 {
  background-color: #fff;
  word-wrap: break-word
}

div._4-i2 div[role='document'] {
  outline: none
}

._5pfh {
  overflow-y: auto
}

._pig {
  padding: 12px
}

._4-i2:first-child {
  border-radius: 3px 3px 0 0
}

._4-i2:last-child {
  border-radius: 0 0 3px 3px
}

._4-i0.accessible_elem:first-child+._4-i2:last-child,
._4-i2:only-child {
  border-radius: 3px
}

div._5a8u {
  background-color: #fff;
  padding: 12px 0
}

html ._27qq {
  border-radius: 0 0 3px 3px;
  margin: 0;
  padding: 12px 12px
}

._3thl {
  overflow: hidden
}

html ._2z1w {
  padding-left: 12px;
  padding-right: 12px
}

._5lnf {
  border-top: 1px solid #dddfe2;
  margin: 0 12px;
  padding: 8px 0
}

.uiContextualLayerAboveLeft ._558b,
.uiContextualLayerAboveCenter ._558b,
.uiContextualLayerAboveRight ._558b {
  margin: 0 0 -1px
}

.uiContextualLayerBelowLeft ._558b,
.uiContextualLayerBelowCenter ._558b,
.uiContextualLayerBelowRight ._558b {
  margin: -1px 0 30px
}

._558b ._54ng {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 3px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3)
}

.uiContextualLayerAboveLeft ._2n_z ._54ng,
.uiContextualLayerAboveRight ._575s._2n_z ._54ng {
  border-bottom-left-radius: 0
}

.uiContextualLayerAboveRight ._2n_z ._54ng,
.uiContextualLayerAboveLeft ._575s._2n_z ._54ng {
  border-bottom-right-radius: 0
}

.uiContextualLayerBelowLeft ._2n_z ._54ng,
.uiContextualLayerBelowRight ._575s._2n_z ._54ng {
  border-top-left-radius: 0
}

.uiContextualLayerBelowRight ._2n_z ._54ng,
.uiContextualLayerBelowLeft ._575s._2n_z ._54ng {
  border-top-right-radius: 0
}

.uiContextualLayer._5v-0 ._558b ._54ng {
  border-radius: 3px
}

._558b ._54nf {
  padding: 5px 0
}

._558b ._54ak {
  border-bottom: 1px solid #e9ebee;
  margin: 5px 7px 6px;
  padding-top: 1px
}

._558b ._54nc {
  border: solid #fff;
  border-width: 1px 0;
  color: #1d2129;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 22px;
  padding: 0 12px
}

._558b ._5arm ._54nc {
  cursor: default;
  opacity: .55
}

._558b ._54ne ._54nc {
  background-color: #4267b2;
  border-color: #29487d;
  color: #fff
}

._558b ._54nd ._54nc {
  background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/P47kLYnMad2.png) left 7px no-repeat;
  font-weight: bold
}

._558b ._54nd._54ne ._54nc {
  background-color: #4267b2;
  background-position: left -53px
}

._558b._57di ._54nc {
  padding-left: 22px
}

._558b ._54ah {
  color: #4b4f56
}

._558b ._54nh {
  max-width: 300px
}

._53il ._558b {
  padding-top: 10px
}

._53ik ._558b {
  padding-bottom: 10px
}

._53il ._558b+._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -2100px;
  height: 8px;
  top: 2px;
  width: 16px
}

._53ik ._558b+._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -2102px;
  bottom: 0;
  height: 10px;
  width: 16px
}

._585n {
  background-color: #3578e5;
  border: 1px solid #3578e5;
  border-radius: 3px;
  overflow: hidden;
  padding: 0 0 0 40px
}

._585o {
  background-color: #fa3e3e;
  border-color: #fa3e3e
}

._3qh4 {
  background-color: #42b72a;
  border-color: #42b72a
}

._1wpa {
  background-color: #f79c2d;
  border-color: #f79c2d
}

._5d83 {
  background-color: #ccd0d5;
  border-color: #ccd0d5
}

._585p {
  float: left;
  margin: 8px 0 0 -30px
}

._585r {
  background: #fff;
  margin: 0;
  padding: 9px 10px
}

._2i-a {
  padding-right: 30px
}

._585q {
  float: right;
  margin: 12px 12px 0 0
}

._585n a {
  font-weight: bold
}

.openToggler>._5vto._5vto {
  background-color: #4267b2;
  border-color: #4267b2;
  box-shadow: none;
  color: #fff
}

.openToggler>._5vto:hover {
  background-color: #365899;
  border-color: #365899
}

.openToggler>._5vto:active {
  background-color: #29487d;
  border-color: #29487d
}

.openToggler>._55pi._nk,
.openToggler>._55pi._nl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.openToggler>._55pi._nn,
.openToggler>._55pi._no {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

._5xew {
  position: absolute;
  z-index: 202
}

._5xex {
  position: fixed
}

._599r {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 10px rgba(0, 0, 0, .35);
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  max-width: 640px;
  min-width: 240px;
  padding: 8px 0
}

._8xdi ._599r {
  font-family: inherit
}

._599s {
  display: none
}

._599m {
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 12px
}

._605a ._1u3_ .img {
  border-radius: 50%
}

._605a ._1u3_ ._39jx .img {
  border-radius: 0
}

._5mne ._599p {
  margin-top: 8px
}

._599o {
  -webkit-filter: none
}

._599p {
  color: #1d2129;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._599q {
  color: #4b4f56;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._4tw1 {
  opacity: .5
}

._599n {
  background-color: #365899;
  border-color: #20375f
}

._1c5r {
  direction: rtl
}

._599n ._599p,
._599n ._599q {
  color: #fff
}

._62w5 {
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px
}

._62w5 ._599m {
  width: 100%
}

._1tp7:not(:focus) {
  border: 1px solid #fa3e3e
}

._1tp9 {
  max-width: 214px;
  padding: 6px 9px
}

._1tpa {
  display: inline-block;
  height: 26px;
  vertical-align: middle
}

._1tp8 {
  color: #fa3e3e;
  display: inline-block;
  vertical-align: middle;
  word-break: break-word
}

._1tp8 a {
  color: #fa3e3e;
  font-weight: bold
}

._1tpb ._53iv {
  padding: 12px
}

._1tpb ._53ij {
  border: 1px solid #fa3e3e;
  border-radius: 2px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .35)
}

._1tpb ._5v-0 {
  padding-bottom: 14px
}

._1tpb ._53il {
  padding-top: 14px
}

._1tpb ._53im {
  padding-right: 14px
}

._1tpb ._53ik {
  padding-bottom: 14px
}

._1tpb ._53in {
  padding-left: 14px
}

._1tpb ._53il ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -18px -2102px;
  height: 8px;
  top: 7px;
  width: 16px
}

._1tpb ._53im ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -740px;
  height: 16px;
  right: 7px;
  width: 8px
}

._1tpb ._53ik ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -2108px;
  bottom: 7px;
  height: 8px;
  width: 16px
}

._1tpb ._53in ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -722px;
  height: 16px;
  left: 7px;
  width: 8px
}

._55ym {
  animation: rotateSpinner 1.2s steps(20, end) infinite;
  display: inline-block;
  vertical-align: middle
}

._55yn._55yo {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yD/r/MKQzjVd1bVq.png);
  height: 12px;
  width: 12px
}

._55yn._55yp {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/lHmjAzNKBcg.png);
  height: 12px;
  width: 12px
}

._55yq {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yH/r/xgVgalBG80z.png);
  height: 24px;
  width: 24px
}

._5tqs {
  animation-play-state: paused;
  display: none
}

._5tqs.async_saving,
.async_saving ._5tqs {
  animation-play-state: running;
  display: inline-block
}

._2y32 {
  animation-play-state: paused
}

._5d9- {
  animation: none;
  background-repeat: no-repeat
}

._5d9-._55yn {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y4/r/Rw9OLEzMGY9.gif);
  background-repeat: no-repeat;
  background-size: 16px 10px;
  background-position: 0 2px;
  height: 16px;
  width: 16px
}

._5d9-._55yq {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y5/r/QoWXpuetsyB.gif);
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 0 2px;
  height: 32px;
  width: 32px
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.UFIAddComment .UFIAddCommentInput {
  display: block;
  height: 20px
}

.UFICommentTip {
  padding-top: 1px
}

.UFIMentionsInputWrap,
.UFICommentContainer,
.UFIInputContainer {
  position: relative
}

.UFICommentAttachmentButtons {
  bottom: 0;
  height: 24px;
  position: absolute;
  right: 0
}

.UFIPhotoAttachLinkWrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 6px 8px
}

.UFIAddCommentWithPhotoAttacher .innerWrap {
  padding-right: 29px
}

.UFIStickersEnabledInput .innerWrap {
  padding-right: 46px
}

.UFICommentActorName {
  font-weight: 600;
  white-space: nowrap
}

.UFICommentSocialContext,
.UFICommentSocialContext a {
  color: #90949c
}

.UFICommentActions {
  clear: both;
  padding-top: 2px
}

.UFICommentActions .UFIReactionLink {
  outline: none;
  word-wrap: normal
}

.UFICommentActions .UFIReactionLink::after {
  bottom: 0;
  content: '';
  display: block;
  left: -6px;
  position: absolute;
  right: -6px;
  top: -4px
}

.UFICommentActions .UFILinkBright {
  font-weight: bold
}

.UFICommentActions .UFICommentReactionsBling {
  display: inline-block;
  overflow: visible
}

.UFICommentActions .UFISutroCommentTimestamp,
.UFICommentActions .UFISutroLikeCount {
  color: #616770
}

.UFICommentAuthorWithPresence {
  position: relative
}

.UFICommentAuthorPresence {
  background-color: #42b72a;
  border: 2px solid #f2f3f5;
  border-radius: 9px;
  bottom: -2px;
  display: none;
  height: 9px;
  position: absolute;
  right: -2px;
  width: 9px;
  z-index: 2
}

.UFIReplyList .UFICommentAuthorPresence {
  border-radius: 7px;
  border-width: 1px;
  height: 7px;
  width: 7px
}

.UFICommentAuthorActive {
  display: block
}

.UFICommentAuthorLivingRoomHost {
  background-color: #8c72cb;
  border-color: #fff;
  bottom: -4px;
  display: block;
  right: -4px
}

.UFIContainer .highlightComment {
  background-color: #fff9d7
}

.UFITranslucentComment {
  background-color: #f5f6f7
}

.UFITranslucentComment a,
.UFITranslucentComment .UFIDeletedMessageIcon,
.UFITranslucentComment .UFICommentBody,
.UFITranslucentComment .UFIReplyLink,
.UFITranslucentComment .UFICommentNotSpamLink {
  opacity: .5
}

.UFICommentFailed {
  background: #f5f6f7
}

.UFICommentFailed::after {
  background-color: #aaa7a2;
  bottom: 0;
  content: '';
  left: -13px;
  position: absolute;
  top: 0;
  width: 2px
}

.UFICommentFailed,
.UFICommentFailed .UFICommentActorName {
  color: #7f7f7f
}

.UFIFailureMessage {
  color: #111;
  padding-left: 16px;
  position: relative
}

.UFIFailureMessageIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1146px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 1px;
  vertical-align: middle;
  width: 11px
}

.UFIDeletedMessage {
  position: relative
}

.UFIDeletedMessageIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1160px;
  height: 12px;
  left: 3px;
  position: absolute;
  top: 1px;
  vertical-align: middle;
  width: 11px
}

.UFICommentContentBlockWithVote {
  display: flex;
  justify-content: space-between
}

.UFICommentContentBlock {
  padding-right: 14px;
  position: relative
}

.UFICommentVerticalVoteFlexGrow {
  flex-grow: 1
}

.UFICommentLiveRedesignBadgedContent {
  padding-bottom: 3px
}

.UFICommentLiveRedesignPaddedBadgedContent {
  padding-bottom: 15px
}

.UFIRow .UFICommentSpamTooltip {
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.UFIRow .UFICommentCloseButton {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.UFIRow .UFICommentCloseButton,
.UFIRow .UFICommentSpamTooltip {
  display: block
}

.openToggler .UFICommentCloseButton,
.UFIRow:hover .UFICommentCloseButton,
.UFIRow .UFICommentCloseButton.openToggler,
.UFIRow .UFICommentCloseButton:focus,
.UFIRow .UFICommentCloseButton.focused,
.UFIRow .UFICommentCloseButton.focused a {
  opacity: 1;
  outline: none
}

.UFICommentCloseButton.UFICommentCloseButtonNux {
  opacity: 1
}

.UFICommentPhotoIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -80px -1700px;
  display: block;
  height: 12px;
  width: 15px
}

.UFIStickersEnabledInput .UFIPhotoAttachLinkWrapper {
  padding: 6px 3px
}

.UFICommentStickerButton {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 5px 6px 5px 4px
}

.UFICommentGifButton {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 0
}

.UFICommentStickerIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -66px -1678px;
  height: 14px;
  width: 14px
}

.UFICommentStickerButton:hover .UFICommentStickerIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -6px -1708px
}

.openDialog .UFICommentStickerIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -6px -1708px
}

.UFICommentGifIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -18px -2000px;
  height: 16px;
  margin: 8px 2px 8px 6px;
  width: 16px
}

.UFICommentGifButton:hover .UFICommentGifIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -2000px
}

.openDialog .UFICommentGifIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -36px -2000px
}

.openDialog .UFICommentGifIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -80px -1554px
}

.UFIPhotoAttachLinkWrapper:hover .UFICommentPhotoIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -80px -1712px
}

.UFICommentPhotoAttachedPreview {
  background-color: #fff;
  border-color: #bdc7d8;
  border-style: solid;
  border-top: 1px solid #eee;
  border-width: 0 1px 1px;
  min-height: 10px;
  position: relative
}

.UFICommentPhotoAttachedPreview .uiCloseButton {
  position: absolute;
  right: 10px;
  top: 10px
}

.UFICommentPhotoAttachedPreview .UFICommentPhotoAttachedPreviewLoadingIndicator {
  height: 21px;
  padding: 33px
}

.UFICommentStarsAttachedPreview {
  align-items: center;
  display: flex;
  flex-direction: row
}

.UFICommentStarsAttachedPreviewImage {
  margin-right: 4px
}

.UFICommentReactionIconContainer {
  bottom: -2px;
  padding-right: 3px;
  position: relative
}

.UFICommentLikeButton {
  margin: -5px;
  padding: 2px 5px 4px;
  white-space: nowrap
}

.UFILiveCommentRedesignLikedButton {
  color: #3578e5
}

.UFICommentReactionsBling a.UFICommentLikeButton {
  color: #365899
}

.UFICommentLikeButton:active,
.UFICommentLikeButton:focus,
.UFICommentLikeButton:hover {
  text-decoration: none
}

.UFITranslateAttribution {
  color: #90949c;
  display: block;
  margin: 0
}

.UFITranslatedText {
  border-left: 2px solid #dcdee3;
  display: block;
  margin-top: 2px;
  padding-left: 8px
}

.UFITranslationErrorText {
  color: #90949c;
  display: block;
  margin-top: 2px
}

.UFITranslateLink {
  display: block;
  margin: 0
}

.UFIRow .UFIImageBlockImage {
  margin: 0
}

.UFIRow .UFIImageBlockContent {
  margin: 0;
  padding-left: 8px
}

.UFIShareRow .UFIImageBlockContent {
  padding-left: 4px
}

.UFILikeSentence .UFIImageBlockContent {
  padding-left: 5px
}

.UFIPagerRow .UFIImageBlockContent {
  padding-left: 6px
}

.UFIContainer .UFILivePinnedComment.UFIComment {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  border-left: 12px solid white;
  border-right: 12px solid white;
  border-top: 1px solid #e5e5e5;
  margin: 0
}

.UFICommentLiveVideoAnnouncementBody {
  align-items: center;
  display: flex
}

.UFICommentLiveVideoAnnouncementMessage {
  flex-grow: 1;
  margin-right: 2px
}

.UFIPrivateCommentLabel {
  align-items: center;
  display: flex;
  margin-left: 40px;
  padding-bottom: 2px
}

.UFIPrivateCommentLabel.UFIPrivateCommentActorOnly {
  margin-bottom: -9px
}

.UFIComment .UFICommentContent .UFIPrivateCommentLabel {
  align-items: baseline;
  display: inline-flex;
  margin-left: 0;
  padding-bottom: 0
}

.UFIComment .UFICommentContent .UFIPrivateCommentLabel.UFIPrivateCommentReplyLabel {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle
}

.UFIPrivateCommentLabelIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1034px;
  align-self: center;
  display: inline-block;
  height: 12px;
  width: 12px
}

.UFIPrivateCommentLabelText {
  color: #616770;
  margin-left: 6px
}

.UFICommentContent .UFIPrivateCommentLabelText {
  margin-left: 4px
}

.UFILivePinnedCommentLabel {
  color: #3578e5;
  display: block;
  margin-bottom: 4px;
  margin-top: 2px
}

.UFICommentShareOptionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px
}

.UFICommentShareOptionsDescription {
  color: #616770
}

.UFICommentShareOptions {
  display: flex
}

.UFICommentShareOptionsItem {
  margin-right: 8px
}

.UFILivePinnedCommentGlyph {
  margin-bottom: -1px;
  margin-right: 4px
}

.UFIBusinessActorIconText {
  color: #27b4e8;
  left: 50%;
  position: absolute;
  top: 54%;
  transform: translate(-50%, -50%);
  -webkit-user-select: none
}

.UFICommentEmojiIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -2000px;
  height: 16px;
  width: 16px
}

.UFICommentEmojiIcon:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -2018px
}

.UFICommentEmojiIcon:active {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -2000px;
  opacity: .5
}

.UFICommentEmojiIcon.UFICommentEmojiIconActive {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -72px -2000px
}

.UFICommentFeaturedProductsIcon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -78px -1962px;
  height: 16px;
  width: 16px
}

.UFICommentFeaturedProductsIcon.UFICommentFeaturedProductsIconActive {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -78px -1962px
}

.UFICommentActions {
  min-height: 15px
}

.UFIAuthorPinnedCommentIcon {
  margin-right: 4px;
  position: relative;
  top: 2px
}

._5e-r ._53ij {
  border: none;
  border-radius: 2px;
  overflow: hidden
}

._5e-r ._53ik ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -54px -2090px;
  bottom: -3px;
  height: 8px;
  position: absolute;
  right: 13px;
  width: 16px
}

._5e-r ._53il ._53io {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -72px -2090px;
  height: 8px;
  position: absolute;
  right: 13px;
  top: -1px;
  width: 16px
}

._2ad7 {
  margin-left: 2px;
  position: relative;
  top: 1px
}

._88lp {
  margin-left: 4px;
  position: relative;
  top: 1px
}

._2ad7._5dzy,
._4rwy,
._6p8n {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1118px;
  border-radius: 50%;
  height: 12px;
  width: 12px
}

._2ad7._5dzy {
  top: -1px
}

._4rwy,
._7cf0,
._6p8n {
  background-color: #fff
}

._7cf0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -2000px;
  border-radius: 50%;
  height: 16px;
  width: 16px
}

._6p8n {
  margin-right: -3px
}

._7cf0,
._6--1 ._4rwy {
  bottom: -1px;
  position: absolute;
  right: -1px
}

.x10l6tqk {
  position: absolute
}

.x179tack {
  -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0)
}

.xzpqnlu {
  clip: rect(0, 0, 0, 0)
}

a.weakReference {
  color: #7f7f7f;
  white-space: nowrap
}

a.weakReference img:first-child,
a.weakReference i:first-child {
  margin-right: 3px
}

._aeqv {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center
}

._aeqw {
  text-shadow: 0 0 4px rgba(0, 0, 0, .5)
}

._aeqx {
  box-sizing: border-box;
  height: 100%;
  padding: 12px;
  width: 100%
}

._5yk1 {
  background: #fff;
  border: 1px solid #bdc7d8;
  box-sizing: border-box;
  cursor: text;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px
}

._am3g {
  padding: 3px
}

._am3f {
  padding: 9px 28px 9px 9px
}

._5yk1 ._1p1u {
  color: #bec3c9
}

._7v3u {
  border-radius: 13px;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px
}

._4cgy {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle
}

._1lid {
  animation: SUISpinnerRotation 550ms linear infinite;
  -webkit-backface-visibility: hidden;
  display: block;
  will-change: transform
}

@keyframes SUISpinnerRotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

._8036>._4cgy~._4cgy {
  display: none
}

._5v47 {
  display: inline;
  white-space: nowrap
}

.UFICommentContent ._5v47 {
  font-size: 12px
}

._5uzb {
  padding-right: 3px
}

._1ipe {
  font-size: 0;
  visibility: hidden
}

._1x3s {
  display: none
}

._42ef {
  overflow: hidden
}

._8o,
._8o .img {
  display: block
}

._8r {
  margin-right: 5px
}

._8s {
  margin-right: 8px
}

._8t {
  margin-right: 10px
}

._6a {
  display: inline-block
}

._6d {
  vertical-align: bottom
}

._6b {
  vertical-align: middle
}

._6e {
  vertical-align: top
}

._5u5j {
  width: 100%
}

._ohe {
  float: left
}

._ohf {
  float: right
}

._ohf>.horizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center
}

@keyframes CSSFade_show {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes CSSFade_hide {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

a._58cn:hover {
  text-decoration: none
}

a:hover ._58cm {
  text-decoration: underline
}

._58cn ._58cl {
  color: #365899
}

.UFIContainer ._58cn ._58cl {
  color: #90949c
}

._42ft {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap
}

._42ft:hover {
  text-decoration: none
}

._42ft+._42ft {
  margin-left: 4px
}

._42fr,
._42fs {
  cursor: default
}

._afhc {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

._50zz {
  height: 10px;
  width: 10px
}

._50zz._50z- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -86px -1670px
}

._50zz._50z-:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -84px -1920px
}

._50zz._50z-:active,
._50zz._42fs {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -86px -1682px
}

._50zz._50z_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -2118px
}

._50zz._50z_:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -12px -2118px
}

._50-0 {
  height: 12px;
  width: 12px
}

._50-0._50z- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1174px
}

._50-0._50z-:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1202px
}

._50-0._50z-:active,
._50-0._42fs {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1188px
}

._50-0._50z_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1216px
}

._50-0._50z_:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -82px -1230px
}

._50-1 {
  height: 20px;
  width: 20px
}

._50-1._50z- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: 0 -1832px
}

._50-1._50z-:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -44px -1832px
}

._50-1._50z-:active,
._50-1._42fs {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -44px -1832px
}

._50-1._50z_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -22px -1832px
}

._50-1._50z_:hover {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-repeat: no-repeat;
  background-size: 98px 2130px;
  background-position: -66px -1832px
}

._8k_v._50zy._50-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yv/r/XcPIROjSoOw.png);
  background-position: unset;
  background-size: 24px 24px;
  height: 24px;
  width: 24px
}

._9l19 ._50zy {
  display: none
}

._5upp {
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  font-size: 0 !important;
  overflow: hidden;
  padding: 0;
  vertical-align: middle
}

.highContrast ._5upp {
  font-size: 11px !important;
  height: auto;
  width: auto
}

._50f3 {
  font-size: 12px;
  line-height: 16px
}

._50f4 {
  font-size: 14px;
  line-height: 18px
}

._50f5 {
  font-size: 16px;
  line-height: 20px
}

._50f6 {
  font-size: 18px;
  line-height: 22px
}

._2iei {
  font-size: 40px;
  line-height: 48px
}

._2iej {
  font-size: 24px;
  line-height: 28px
}

._2iek {
  font-size: 20px;
  line-height: 24px
}

._2iel {
  font-size: 16px;
  line-height: 20px
}

._2iem {
  font-size: 14px;
  line-height: 18px
}

._2ien {
  font-size: 14px;
  line-height: 18px
}

._2ieo {
  font-size: 13px;
  line-height: 17px
}

._2iep {
  font-size: 12px;
  line-height: 16px
}

._2ieq {
  font-size: 12px;
  line-height: 16px
}

._50f7 {
  font-weight: 600
}

._5kx5 {
  font-weight: normal
}

._50f8 {
  color: #90949c
}

._c24 {
  color: #4b4f56
}

._50f9 {
  color: #1d2129
}

._2iev {
  color: #1c1e21
}

._2iex {
  color: #606770
}

._2iey {
  color: #bec3c9
}

._rzx {
  color: #385898
}

._rzy {
  color: #8d949e
}

._2ier {
  color: #fff
}

._1hk0 {
  color: #1877f2
}

._2iet {
  color: #00a400
}

._2ieu {
  color: #fa383e
}

._2iez {
  color: #ccc
}

._2ie- {
  color: #4a4a4a
}

._2ie_ {
  color: #373737
}

#facebook ._5s6c._5s6c,
._5s6c {
  font-family: Georgia, serif;
  letter-spacing: normal
}

#facebook ._6mv-._6mv-,
._6mv- {
  font-family: 'Open Dyslexic';
  letter-spacing: normal
}

.CometSettingsPage ._2iep,
.CometSettingsPage ._2ieq,
.CometSettingsPage ._50f4 {
  font-size: 15px;
  line-height: 20px
}

.CometSettingsPage ._50f4 {
  font-weight: 500
}

._3gl1 {
  background-position: center;
  background-repeat: no-repeat;
  caret-color: var(--primary-text);
  display: inline-block;
  margin: 0 1px;
  text-align: center;
  vertical-align: middle
}

._1mjk ._3gl1 {
  caret-color: #fff
}

._ncl {
  color: transparent;
  display: inline-block;
  transform: translateY(-3px);
  z-index: -1
}

._5zk7 {
  background-color: rgba(88, 144, 255, .15);
  border-bottom: 1px solid rgba(88, 144, 255, .3)
}

._247o {
  background-color: #dce6f8
}

._whq {
  background-color: #eee;
  color: #666
}

._5rp7,
._5rpb,
._5rpu {
  height: inherit;
  text-align: initial
}

._5rpu[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only
}

._5rp7 {
  position: relative
}

._5rpb {
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 1
}

.safari ._5rpb {
  border-left: .1px solid transparent
}

._45m_ {
  position: relative
}

._5rp8 ._1mf {
  text-align: left
}

._5rp8 ._1p1t {
  left: 0;
  text-align: left
}

._5rpa ._1mf {
  text-align: center
}

._5rpa ._1p1t {
  margin: 0 auto;
  text-align: center;
  width: 100%
}

._5rp9 ._1mf {
  text-align: right
}

._5rp9 ._1p1t {
  right: 0;
  text-align: right
}

._1p1t {
  color: #90949c;
  position: absolute;
  width: 100%;
  z-index: 1
}

._1p1u {
  color: #bec2c9
}

._32of {
  display: none
}

._1mf {
  position: relative;
  white-space: pre-wrap
}

._1mj {
  direction: ltr;
  text-align: left
}

._1mk {
  direction: rtl;
  text-align: right
}

._3kq6 {
  direction: ltr
}

._3kq7 {
  direction: rtl
}

._1bv0,
._1bv1 {
  margin: 16px 0;
  padding: 0
}

._3kq1._3kq6 {
  margin-left: 1.5em
}

._3kq1._3kq7 {
  margin-right: 1.5em
}

._3kq2._3kq6 {
  margin-left: 3em
}

._3kq2._3kq7 {
  margin-right: 3em
}

._3kq3._3kq6 {
  margin-left: 4.5em
}

._3kq3._3kq7 {
  margin-right: 4.5em
}

._3kq4._3kq6 {
  margin-left: 6em
}

._3kq4._3kq7 {
  margin-right: 6em
}

._3kq5._3kq6 {
  margin-left: 7.5em
}

._3kq5._3kq7 {
  margin-right: 7.5em
}

._3kpz {
  list-style-type: square;
  position: relative
}

._3kpz._3kq1 {
  list-style-type: disc
}

._3kpz._3kq2 {
  list-style-type: circle
}

._3kp_ {
  list-style-type: none;
  position: relative
}

._3kp_._3kq6:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px
}

._3kp_._3kq7:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px
}

._3kp_:before {
  content: counter(ol0) '. ';
  counter-increment: ol0
}

._3kp_._3kq2:before {
  content: counter(ol1, lower-alpha) '. ';
  counter-increment: ol1
}

._3kp_._3kq3:before {
  content: counter(ol2, lower-roman) '. ';
  counter-increment: ol2
}

._3kp_._3kq4:before {
  content: counter(ol3) '. ';
  counter-increment: ol3
}

._3kp_._3kq5:before {
  content: counter(ol4, lower-alpha) '. ';
  counter-increment: ol4
}

._3kq1._3kq0 {
  counter-reset: ol0
}

._3kq2._3kq0 {
  counter-reset: ol1
}

._3kq3._3kq0 {
  counter-reset: ol2
}

._3kq4._3kq0 {
  counter-reset: ol3
}

._3kq5._3kq0 {
  counter-reset: ol4
}

._47e3,
._4ay8 {
  line-height: 0;
  vertical-align: middle
}

._4ay8 {
  font-style: normal !important;
  font-weight: normal !important
}

._4ay8._3kkw {
  font-size: 16px
}

._4ay8._366d {
  font-size: 18px
}

._4ay8._366e {
  font-size: 20px
}

._4ay8._48cb {
  font-size: 24px
}

._4ay8._5-0n {
  font-size: 28px
}

._4ay8._5-0o {
  font-size: 30px
}

._4ay8._5-0p {
  font-size: 32px
}

._4ay8._2oah {
  font-size: 36px
}

._4ay8._4352 {
  font-size: 56px
}

._4ay8._435o {
  font-size: 112px
}

span._47e3 .img {
  vertical-align: -3px
}

i._47e3 {
  display: inline-block;
  vertical-align: top
}

i._47e3._3kkw {
  background-size: 16px 16px;
  height: 16px;
  width: 16px
}

i._47e3._366d {
  background-size: 18px 18px;
  height: 18px;
  width: 18px
}

i._47e3._366e {
  background-size: 20px 20px;
  height: 20px;
  width: 20px
}

i._47e3._48cb {
  background-size: 24px 24px;
  height: 24px;
  width: 24px
}

._1gwo:before,
span._47e3:before {
  clip: rect(1px, 1px, 1px, 1px);
  content: attr(title), '';
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

._skr,
._7oe {
  display: inline;
  font-size: 0;
  width: 0
}

._5mfr {
  margin: 0 1px
}

._6qdm {
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: inline-block;
  text-decoration: none;
  text-shadow: none;
  vertical-align: text-bottom
}

._6qdm::selection {
  background-color: highlight;
  color: transparent
}

._6qdm._6qdm .pun {
  color: transparent
}

._7c4h {
  display: inline-block;
  position: relative
}

._7c4l {
  display: block;
  left: 0;
  position: absolute;
  top: 0
}

.emoticon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top
}

.emoticon_angel {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 0
}

.emoticon_colonthree {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -18px
}

.emoticon_confused {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -36px
}

.emoticon_confused_rev {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -54px
}

.emoticon_cry {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -72px
}

.emoticon_devil {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -90px
}

.emoticon_frown {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -108px
}

.emoticon_gasp {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -126px
}

.emoticon_glasses {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -144px
}

.emoticon_grin {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -162px
}

.emoticon_grumpy {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -180px
}

.emoticon_heart {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -198px
}

.emoticon_kiki {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -216px
}

.emoticon_kiss {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -234px
}

.emoticon_like {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -252px
}

.emoticon_pacman {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -270px
}

.emoticon_penguin {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -288px
}

.emoticon_poop {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -306px
}

.emoticon_putnam {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -324px
}

.emoticon_robot {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -342px
}

.emoticon_shark {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -360px
}

.emoticon_smile {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -378px
}

.emoticon_squint {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -396px
}

.emoticon_sunglasses {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -414px
}

.emoticon_tongue {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -432px
}

.emoticon_unsure {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -450px
}

.emoticon_upset {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -468px
}

.emoticon_wink {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-repeat: no-repeat;
  background-size: 18px 504px;
  background-position: 0 -486px
}

._4qba {
  font-style: inherit
}

._4qbb,
._4qbc,
._4qbd {
  background: none;
  font-style: inherit;
  padding: 0;
  width: auto
}

._4qbd {
  border-bottom: 1px solid #f99
}

._4qbb,
._4qbc {
  border-bottom: 1px solid #999
}

._4qbb:hover,
._4qbc:hover,
._4qbd:hover {
  background-color: #fcc;
  border-top: 1px solid #ccc;
  cursor: help
}

._28hn {
  display: inline-block;
  width: 100%
}

._4ez8 {
  outline: 1px solid #fa3e3e
}

._28ho {
  outline: 1px solid #42b72a
}

._4jnw {
  margin: 0
}

._3-8h {
  margin: 4px
}

._3-8i {
  margin: 8px
}

._3-8j {
  margin: 12px
}

._3-8k {
  margin: 16px
}

._3-8l {
  margin: 20px
}

._2-5b {
  margin: 24px
}

._1kbd {
  margin-bottom: 0;
  margin-top: 0
}

._3-8m {
  margin-bottom: 4px;
  margin-top: 4px
}

._3-8n {
  margin-bottom: 8px;
  margin-top: 8px
}

._3-8o {
  margin-bottom: 12px;
  margin-top: 12px
}

._3-8p {
  margin-bottom: 16px;
  margin-top: 16px
}

._3-8q {
  margin-bottom: 20px;
  margin-top: 20px
}

._2-ox {
  margin-bottom: 24px;
  margin-top: 24px
}

._1a4i {
  margin-left: 0;
  margin-right: 0
}

._3-8r {
  margin-left: 4px;
  margin-right: 4px
}

._3-8s {
  margin-left: 8px;
  margin-right: 8px
}

._3-8t {
  margin-left: 12px;
  margin-right: 12px
}

._3-8u {
  margin-left: 16px;
  margin-right: 16px
}

._3-8v {
  margin-left: 20px;
  margin-right: 20px
}

._6bu9 {
  margin-left: 24px;
  margin-right: 24px
}

._5soe {
  margin-top: 0
}

._3-8w {
  margin-top: 4px
}

._3-8x {
  margin-top: 8px
}

._3-8y {
  margin-top: 12px
}

._3-8z {
  margin-top: 16px
}

._3-8- {
  margin-top: 20px
}

._4aws {
  margin-top: 24px
}

._2-jz {
  margin-right: 0
}

._3-8_ {
  margin-right: 4px
}

._3-90 {
  margin-right: 8px
}

._3-91 {
  margin-right: 12px
}

._3-92 {
  margin-right: 16px
}

._3-93 {
  margin-right: 20px
}

._y8t {
  margin-right: 24px
}

._5emk {
  margin-bottom: 0
}

._3-94 {
  margin-bottom: 4px
}

._3-95 {
  margin-bottom: 8px
}

._3-96 {
  margin-bottom: 12px
}

._3-97 {
  margin-bottom: 16px
}

._3-98 {
  margin-bottom: 20px
}

._20nr {
  margin-bottom: 24px
}

._av_ {
  margin-left: 0
}

._3-99 {
  margin-left: 4px
}

._3-9a {
  margin-left: 8px
}

._3-9b {
  margin-left: 12px
}

._3-9c {
  margin-left: 16px
}

._3-9d {
  margin-left: 20px
}

._4m0t {
  margin-left: 24px
}

._a82f {
  margin-left: 28px
}

._8tm {
  padding: 0
}

._2phz {
  padding: 4px
}

._2ph- {
  padding: 8px
}

._2ph_ {
  padding: 12px
}

._2pi0 {
  padding: 16px
}

._2pi1 {
  padding: 20px
}

._40c7 {
  padding: 24px
}

._2o1j {
  padding: 36px
}

._6buq {
  padding-bottom: 0;
  padding-top: 0
}

._2pi2 {
  padding-bottom: 4px;
  padding-top: 4px
}

._2pi3 {
  padding-bottom: 8px;
  padding-top: 8px
}

._2pi4 {
  padding-bottom: 12px;
  padding-top: 12px
}

._2pi5 {
  padding-bottom: 16px;
  padding-top: 16px
}

._2pi6 {
  padding-bottom: 20px;
  padding-top: 20px
}

._2o1k {
  padding-bottom: 24px;
  padding-top: 24px
}

._2o1l {
  padding-bottom: 36px;
  padding-top: 36px
}

._6bua {
  padding-left: 0;
  padding-right: 0
}

._2pi7 {
  padding-left: 4px;
  padding-right: 4px
}

._2pi8 {
  padding-left: 8px;
  padding-right: 8px
}

._2pi9 {
  padding-left: 12px;
  padding-right: 12px
}

._2pia {
  padding-left: 16px;
  padding-right: 16px
}

._2pib {
  padding-left: 20px;
  padding-right: 20px
}

._2o1m {
  padding-left: 24px;
  padding-right: 24px
}

._2o1n {
  padding-left: 36px;
  padding-right: 36px
}

._iky {
  padding-top: 0
}

._2pic {
  padding-top: 4px
}

._2pid {
  padding-top: 8px
}

._2pie {
  padding-top: 12px
}

._2pif {
  padding-top: 16px
}

._2pig {
  padding-top: 20px
}

._2owm {
  padding-top: 24px
}

._div {
  padding-right: 0
}

._2pih {
  padding-right: 4px
}

._2pii {
  padding-right: 8px
}

._2pij {
  padding-right: 12px
}

._2pik {
  padding-right: 16px
}

._2pil {
  padding-right: 20px
}

._31wk {
  padding-right: 24px
}

._2phb {
  padding-right: 32px
}

._au- {
  padding-bottom: 0
}

._2pim {
  padding-bottom: 4px
}

._2pin {
  padding-bottom: 8px
}

._2pio {
  padding-bottom: 12px
}

._2pip {
  padding-bottom: 16px
}

._2piq {
  padding-bottom: 20px
}

._2o1p {
  padding-bottom: 24px
}

._4gao {
  padding-bottom: 32px
}

._1cvx {
  padding-left: 0
}

._2pir {
  padding-left: 4px
}

._2pis {
  padding-left: 8px
}

._2pit {
  padding-left: 12px
}

._2piu {
  padding-left: 16px
}

._2piv {
  padding-left: 20px
}

._2o1q {
  padding-left: 24px
}

._2o1r {
  padding-left: 36px
}

._4438 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 100%
}

._6a-v {
  opacity: .4
}

._3f0q {
  margin-top: 20px
}

._442_ {
  padding-bottom: 5px
}

._4430._5e4h {
  border-color: #ccd0d5;
  border-radius: 18px;
  box-sizing: border-box;
  width: 100%
}

._4430 ._5e4k {
  border-radius: 18px
}

._2yme {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%
}

._2ymf {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.sp_0d2oqxMYE7L_1_5x {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/O29QYRkEEy2.png);
  background-size: 98px 2130px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px
}

.sp_0d2oqxMYE7L_1_5x.sx_0f5c7b {
  width: 12px;
  height: 12px;
  background-position: -82px -950px
}

.sp_0d2oqxMYE7L_1_5x.sx_484e56 {
  width: 12px;
  height: 12px;
  background-position: -82px -964px
}

.sp_0d2oqxMYE7L_1_5x.sx_0349b9 {
  background-position: -80px -1554px
}

.sp_0d2oqxMYE7L_1_5x.sx_c18ba8 {
  width: 12px;
  height: 12px;
  background-position: -82px -978px
}

.sp_0d2oqxMYE7L_1_5x.sx_ef75bc {
  width: 12px;
  height: 12px;
  background-position: -82px -992px
}

.sp_0d2oqxMYE7L_1_5x.sx_def7dd {
  background-position: -80px -1942px
}

.sp_0d2oqxMYE7L_1_5x.sx_17aa0d {
  width: 20px;
  height: 20px;
  background-position: -76px -1584px
}

.sp_0d2oqxMYE7L_1_5x.sx_d37116 {
  width: 24px;
  height: 24px;
  background-position: -50px -1584px
}

.sp_0d2oqxMYE7L_1_5x.sx_1aa9a9 {
  width: 20px;
  height: 20px;
  background-position: -48px -1808px
}

.sp_0d2oqxMYE7L_1_5x.sx_409a39 {
  width: 12px;
  height: 12px;
  background-position: -82px -1006px
}

.sp_0d2oqxMYE7L_1_5x.sx_a3e5ee {
  width: 12px;
  height: 12px;
  background-position: -82px -1020px
}

.sp_0d2oqxMYE7L_1_5x.sx_d98be0 {
  width: 20px;
  height: 20px;
  background-position: -70px -1808px
}

.sp_0d2oqxMYE7L_1_5x.sx_37abd2 {
  width: 20px;
  height: 20px;
  background-position: 0 -1832px
}

.sp_0d2oqxMYE7L_1_5x.sx_97b5e1 {
  width: 20px;
  height: 20px;
  background-position: -22px -1832px
}

.sp_0d2oqxMYE7L_1_5x.sx_ad5c12 {
  width: 20px;
  height: 20px;
  background-position: -44px -1832px
}

.sp_0d2oqxMYE7L_1_5x.sx_0a6227 {
  width: 20px;
  height: 20px;
  background-position: -66px -1832px
}

.sp_0d2oqxMYE7L_1_5x.sx_d8c2b6 {
  width: 20px;
  height: 20px;
  background-position: 0 -1854px
}

.sp_0d2oqxMYE7L_1_5x.sx_6fe4f4 {
  width: 12px;
  height: 12px;
  background-position: -82px -1034px
}

.sp_0d2oqxMYE7L_1_5x.sx_e4d53a {
  width: 12px;
  height: 12px;
  background-position: -82px -1048px
}

.sp_0d2oqxMYE7L_1_5x.sx_36a512 {
  width: 12px;
  height: 12px;
  background-position: -82px -1062px
}

.sp_0d2oqxMYE7L_1_5x.sx_1294e4 {
  width: 20px;
  height: 20px;
  background-position: -22px -1854px
}

.sp_0d2oqxMYE7L_1_5x.sx_d1ee1d {
  background-position: -60px -1962px
}

.sp_0d2oqxMYE7L_1_5x.sx_301d36 {
  background-position: -78px -1962px
}

.sp_0d2oqxMYE7L_1_5x.sx_9237a9 {
  background-position: 0 -1982px
}

.sp_0d2oqxMYE7L_1_5x.sx_c6d2b2 {
  background-position: -18px -1982px
}

.sp_0d2oqxMYE7L_1_5x.sx_a9d0e9 {
  width: 24px;
  height: 24px;
  background-position: -54px -1700px
}

.sp_0d2oqxMYE7L_1_5x.sx_d8c1f2 {
  background-position: -36px -1982px
}

.sp_0d2oqxMYE7L_1_5x.sx_5426f3 {
  background-position: -54px -1982px
}

.sp_0d2oqxMYE7L_1_5x.sx_798940 {
  width: 24px;
  height: 24px;
  background-position: 0 -1730px
}

.sp_0d2oqxMYE7L_1_5x.sx_e1698f {
  width: 12px;
  height: 12px;
  background-position: -82px -1076px
}

.sp_0d2oqxMYE7L_1_5x.sx_5b4566 {
  background-position: -72px -1982px
}

.sp_0d2oqxMYE7L_1_5x.sx_9410f7 {
  width: 20px;
  height: 20px;
  background-position: -44px -1854px
}

.sp_0d2oqxMYE7L_1_5x.sx_ed4e41 {
  width: 24px;
  height: 24px;
  background-position: -26px -1728px
}

.sp_0d2oqxMYE7L_1_5x.sx_92408f {
  width: 12px;
  height: 12px;
  background-position: -82px -1090px
}

.sp_0d2oqxMYE7L_1_5x.sx_946b49 {
  width: 20px;
  height: 20px;
  background-position: -66px -1854px
}

.sp_0d2oqxMYE7L_1_5x.sx_ea14ee {
  width: 12px;
  height: 12px;
  background-position: -82px -1104px
}

.sp_0d2oqxMYE7L_1_5x.sx_593cce {
  width: 12px;
  height: 12px;
  background-position: -82px -1118px
}

.sp_0d2oqxMYE7L_1_5x.sx_ba973a {
  background-position: 0 -2000px
}

.sp_0d2oqxMYE7L_1_5x.sx_6a0c8f {
  width: 12px;
  height: 12px;
  background-position: -82px -1132px
}

.sp_0d2oqxMYE7L_1_5x.sx_125022 {
  width: 14px;
  height: 14px;
  background-position: -66px -1678px
}

.sp_0d2oqxMYE7L_1_5x.sx_115a8d {
  width: 14px;
  height: 14px;
  background-position: -6px -1708px
}

.sp_0d2oqxMYE7L_1_5x.sx_c80012 {
  background-position: -18px -2000px
}

.sp_0d2oqxMYE7L_1_5x.sx_9c1e5d {
  background-position: -36px -2000px
}

.sp_0d2oqxMYE7L_1_5x.sx_0f7ec0 {
  height: 24px;
  background-position: -80px -1700px
}

.sp_0d2oqxMYE7L_1_5x.sx_850b21 {
  height: 10px;
  background-position: -80px -1572px
}

.sp_0d2oqxMYE7L_1_5x.sx_4f1a85 {
  height: 10px;
  background-position: -18px -2090px
}

.sp_0d2oqxMYE7L_1_5x.sx_14a71c {
  width: 10px;
  background-position: -82px -686px
}

.sp_0d2oqxMYE7L_1_5x.sx_1b7af0 {
  width: 10px;
  background-position: -82px -704px
}

.sp_0d2oqxMYE7L_1_5x.sx_033544 {
  height: 10px;
  background-position: -36px -2090px
}

.sp_0d2oqxMYE7L_1_5x.sx_6923ee {
  background-position: -54px -2000px
}

.sp_0d2oqxMYE7L_1_5x.sx_d790fa {
  background-position: -72px -2000px
}

.sp_0d2oqxMYE7L_1_5x.sx_80841b {
  background-position: 0 -2018px
}

.sp_0d2oqxMYE7L_1_5x.sx_98fca3 {
  height: 8px;
  background-position: -54px -2090px
}

.sp_0d2oqxMYE7L_1_5x.sx_6bb9d1 {
  height: 8px;
  background-position: -72px -2090px
}

.sp_0d2oqxMYE7L_1_5x.sx_a29a5c {
  height: 8px;
  background-position: 0 -2108px
}

.sp_0d2oqxMYE7L_1_5x.sx_f6a4c8 {
  width: 8px;
  background-position: -82px -722px
}

.sp_0d2oqxMYE7L_1_5x.sx_831ff5 {
  width: 8px;
  background-position: -82px -740px
}

.sp_0d2oqxMYE7L_1_5x.sx_b3198c {
  height: 8px;
  background-position: -18px -2102px
}

.sp_0d2oqxMYE7L_1_5x.sx_5cea16 {
  width: 14px;
  height: 14px;
  background-position: -82px -758px
}

.sp_0d2oqxMYE7L_1_5x.sx_9fafc9 {
  width: 12px;
  height: 12px;
  background-position: -82px -1160px
}

.sp_0d2oqxMYE7L_1_5x.sx_8ac391 {
  width: 12px;
  height: 12px;
  background-position: -82px -1146px
}

.sp_0d2oqxMYE7L_1_5x.sx_630c53 {
  background-position: -18px -2018px
}

.sp_0d2oqxMYE7L_1_5x.sx_9adabe {
  width: 14px;
  height: 14px;
  background-position: -82px -790px
}

.sp_0d2oqxMYE7L_1_5x.sx_46a6e9 {
  width: 14px;
  height: 14px;
  background-position: -82px -774px
}

.sp_0d2oqxMYE7L_1_5x.sx_2f7387 {
  width: 14px;
  height: 14px;
  background-position: -82px -806px
}

.sp_0d2oqxMYE7L_1_5x.sx_89ef1d {
  width: 20px;
  height: 20px;
  background-position: 0 -1876px
}

.sp_0d2oqxMYE7L_1_5x.sx_817658 {
  width: 18px;
  height: 18px;
  background-position: -78px -1728px
}

.sp_0d2oqxMYE7L_1_5x.sx_edbcc4 {
  width: 26px;
  height: 26px;
  background-position: -26px -1700px
}

.sp_0d2oqxMYE7L_1_5x.sx_fa6801 {
  width: 14px;
  height: 14px;
  background-position: -56px -2020px
}

.sp_0d2oqxMYE7L_1_5x.sx_395158 {
  width: 14px;
  height: 14px;
  background-position: -38px -2020px
}

.sp_0d2oqxMYE7L_1_5x.sx_0dc35c {
  width: 18px;
  height: 18px;
  background-position: -78px -1748px
}

.sp_0d2oqxMYE7L_1_5x.sx_3616fe {
  background-position: -72px -2018px
}

.sp_0d2oqxMYE7L_1_5x.sx_5f1fa4 {
  background-position: -80px -1770px
}

.sp_0d2oqxMYE7L_1_5x.sx_b89fc4 {
  width: 14px;
  height: 14px;
  background-position: -2px -2038px
}

.sp_0d2oqxMYE7L_1_5x.sx_da913c {
  width: 14px;
  height: 14px;
  background-position: -20px -2038px
}

.sp_0d2oqxMYE7L_1_5x.sx_a756c3 {
  width: 14px;
  height: 14px;
  background-position: -38px -2038px
}

.sp_0d2oqxMYE7L_1_5x.sx_023dc8 {
  width: 20px;
  height: 20px;
  background-position: -2px -1810px
}

.sp_0d2oqxMYE7L_1_5x.sx_64ff4b {
  width: 18px;
  height: 18px;
  background-position: -24px -1878px
}

.sp_0d2oqxMYE7L_1_5x.sx_2bc47c {
  width: 26px;
  height: 26px;
  background-position: -52px -1556px
}

.sp_0d2oqxMYE7L_1_5x.sx_3efee0 {
  background-position: -54px -2036px
}

.sp_0d2oqxMYE7L_1_5x.sx_2db99f {
  width: 18px;
  height: 18px;
  background-position: -44px -1920px
}

.sp_0d2oqxMYE7L_1_5x.sx_1e9596 {
  background-position: -66px -1922px
}

.sp_0d2oqxMYE7L_1_5x.sx_db343d {
  width: 14px;
  height: 14px;
  background-position: -74px -2038px
}

.sp_0d2oqxMYE7L_1_5x.sx_261e2b {
  width: 20px;
  height: 20px;
  background-position: -26px -1810px
}

.sp_0d2oqxMYE7L_1_5x.sx_737db0 {
  width: 18px;
  height: 18px;
  background-position: -46px -1878px
}

.sp_0d2oqxMYE7L_1_5x.sx_e64443 {
  width: 26px;
  height: 26px;
  background-position: -2px -1612px
}

.sp_0d2oqxMYE7L_1_5x.sx_4f5aee {
  width: 14px;
  height: 14px;
  background-position: -20px -2056px
}

.sp_0d2oqxMYE7L_1_5x.sx_f7a689 {
  width: 14px;
  height: 14px;
  background-position: -2px -2056px
}

.sp_0d2oqxMYE7L_1_5x.sx_a01999 {
  width: 14px;
  height: 14px;
  background-position: -82px -822px
}

.sp_0d2oqxMYE7L_1_5x.sx_318b84 {
  background-position: -36px -2054px
}

.sp_0d2oqxMYE7L_1_5x.sx_2b7dbb {
  background-position: -54px -2054px
}

.sp_0d2oqxMYE7L_1_5x.sx_b642b3 {
  width: 14px;
  height: 14px;
  background-position: -82px -838px
}

.sp_0d2oqxMYE7L_1_5x.sx_53717a {
  width: 14px;
  height: 14px;
  background-position: -82px -854px
}

.sp_0d2oqxMYE7L_1_5x.sx_63b8bf {
  width: 14px;
  height: 14px;
  background-position: -82px -870px
}

.sp_0d2oqxMYE7L_1_5x.sx_15e379 {
  width: 14px;
  height: 14px;
  background-position: -82px -886px
}

.sp_0d2oqxMYE7L_1_5x.sx_053f7c {
  width: 14px;
  height: 14px;
  background-position: -82px -902px
}

.sp_0d2oqxMYE7L_1_5x.sx_e0967d {
  width: 14px;
  height: 14px;
  background-position: -82px -918px
}

.sp_0d2oqxMYE7L_1_5x.sx_b7d5e5 {
  width: 14px;
  height: 14px;
  background-position: -82px -934px
}

.sp_0d2oqxMYE7L_1_5x.sx_627856 {
  background-position: -72px -2054px
}

.sp_0d2oqxMYE7L_1_5x.sx_9e75f5 {
  background-position: 0 -2072px
}

.sp_0d2oqxMYE7L_1_5x.sx_b07450 {
  background-position: -18px -2072px
}

.sp_0d2oqxMYE7L_1_5x.sx_f49dc4 {
  background-position: -36px -2072px
}

.sp_0d2oqxMYE7L_1_5x.sx_232121 {
  background-position: -54px -2072px
}

.sp_0d2oqxMYE7L_1_5x.sx_d57a85 {
  background-position: -72px -2072px
}

.sp_0d2oqxMYE7L_1_5x.sx_de83fc {
  background-position: 0 -2090px
}

.sp_0d2oqxMYE7L_1_5x.sx_825052 {
  width: 18px;
  height: 18px;
  background-position: 0 -1942px
}

.sp_0d2oqxMYE7L_1_5x.sx_0697f8 {
  width: 18px;
  height: 18px;
  background-position: -20px -1942px
}

.sp_0d2oqxMYE7L_1_5x.sx_6ed2a9 {
  width: 18px;
  height: 18px;
  background-position: -40px -1942px
}

.sp_0d2oqxMYE7L_1_5x.sx_239e9d {
  width: 18px;
  height: 18px;
  background-position: -60px -1942px
}

.sp_0d2oqxMYE7L_1_5x.sx_71f128 {
  width: 18px;
  height: 18px;
  background-position: 0 -1962px
}

.sp_0d2oqxMYE7L_1_5x.sx_49dae3 {
  width: 18px;
  height: 18px;
  background-position: -20px -1962px
}

.sp_0d2oqxMYE7L_1_5x.sx_2f23ae {
  width: 18px;
  height: 18px;
  background-position: -40px -1962px
}

.sp_0d2oqxMYE7L_1_5x.sx_9a2ccb {
  width: 20px;
  height: 20px;
  background-position: -66px -1876px
}

.sp_0d2oqxMYE7L_1_5x.sx_20ecf5 {
  width: 20px;
  height: 20px;
  background-position: 0 -1898px
}

.sp_0d2oqxMYE7L_1_5x.sx_e06249 {
  width: 20px;
  height: 20px;
  background-position: -22px -1898px
}

.sp_0d2oqxMYE7L_1_5x.sx_aa31e7 {
  width: 20px;
  height: 20px;
  background-position: -44px -1898px
}

.sp_0d2oqxMYE7L_1_5x.sx_8164cd {
  width: 20px;
  height: 20px;
  background-position: -66px -1898px
}

.sp_0d2oqxMYE7L_1_5x.sx_d84834 {
  width: 20px;
  height: 20px;
  background-position: 0 -1920px
}

.sp_0d2oqxMYE7L_1_5x.sx_d4ad4b {
  width: 20px;
  height: 20px;
  background-position: -22px -1920px
}

.sp_0d2oqxMYE7L_1_5x.sx_4f2342 {
  width: 24px;
  height: 24px;
  background-position: -52px -1728px
}

.sp_0d2oqxMYE7L_1_5x.sx_433cff {
  width: 24px;
  height: 24px;
  background-position: 0 -1756px
}

.sp_0d2oqxMYE7L_1_5x.sx_b7bb9f {
  width: 24px;
  height: 24px;
  background-position: -26px -1754px
}

.sp_0d2oqxMYE7L_1_5x.sx_5f4029 {
  width: 24px;
  height: 24px;
  background-position: -52px -1754px
}

.sp_0d2oqxMYE7L_1_5x.sx_ded10d {
  width: 24px;
  height: 24px;
  background-position: 0 -1782px
}

.sp_0d2oqxMYE7L_1_5x.sx_b19177 {
  width: 24px;
  height: 24px;
  background-position: -26px -1780px
}

.sp_0d2oqxMYE7L_1_5x.sx_193768 {
  width: 24px;
  height: 24px;
  background-position: -52px -1780px
}

.sp_0d2oqxMYE7L_1_5x.sx_58f301 {
  width: 28px;
  height: 28px;
  background-position: -30px -1610px
}

.sp_0d2oqxMYE7L_1_5x.sx_f0feec {
  width: 28px;
  height: 28px;
  background-position: -60px -1610px
}

.sp_0d2oqxMYE7L_1_5x.sx_cb3e3a {
  width: 28px;
  height: 28px;
  background-position: 0 -1640px
}

.sp_0d2oqxMYE7L_1_5x.sx_f9b1b7 {
  width: 28px;
  height: 28px;
  background-position: -30px -1640px
}

.sp_0d2oqxMYE7L_1_5x.sx_ccc2ff {
  width: 28px;
  height: 28px;
  background-position: -60px -1640px
}

.sp_0d2oqxMYE7L_1_5x.sx_2e83eb {
  width: 28px;
  height: 28px;
  background-position: 0 -1670px
}

.sp_0d2oqxMYE7L_1_5x.sx_91aae2 {
  width: 28px;
  height: 28px;
  background-position: -30px -1670px
}

.sp_0d2oqxMYE7L_1_5x.sx_1516c1 {
  width: 40px;
  height: 40px;
  background-position: -50px -1260px
}

.sp_0d2oqxMYE7L_1_5x.sx_61670c {
  width: 40px;
  height: 40px;
  background-position: -50px -1302px
}

.sp_0d2oqxMYE7L_1_5x.sx_eb4bcb {
  width: 40px;
  height: 40px;
  background-position: -50px -1344px
}

.sp_0d2oqxMYE7L_1_5x.sx_f05e30 {
  width: 40px;
  height: 40px;
  background-position: -50px -1386px
}

.sp_0d2oqxMYE7L_1_5x.sx_412f8b {
  width: 40px;
  height: 40px;
  background-position: -50px -1428px
}

.sp_0d2oqxMYE7L_1_5x.sx_056eee {
  width: 40px;
  height: 40px;
  background-position: -50px -1470px
}

.sp_0d2oqxMYE7L_1_5x.sx_af0371 {
  width: 40px;
  height: 40px;
  background-position: -50px -1512px
}

.sp_0d2oqxMYE7L_1_5x.sx_789324 {
  width: 48px;
  height: 48px;
  background-position: 0 -1260px
}

.sp_0d2oqxMYE7L_1_5x.sx_fe7e8e {
  width: 48px;
  height: 48px;
  background-position: 0 -1310px
}

.sp_0d2oqxMYE7L_1_5x.sx_1ca7b4 {
  width: 48px;
  height: 48px;
  background-position: 0 -1360px
}

.sp_0d2oqxMYE7L_1_5x.sx_1fec7d {
  width: 48px;
  height: 48px;
  background-position: 0 -1410px
}

.sp_0d2oqxMYE7L_1_5x.sx_b95a67 {
  width: 48px;
  height: 48px;
  background-position: 0 -1460px
}

.sp_0d2oqxMYE7L_1_5x.sx_151345 {
  width: 48px;
  height: 48px;
  background-position: 0 -1510px
}

.sp_0d2oqxMYE7L_1_5x.sx_ddf615 {
  width: 48px;
  height: 48px;
  background-position: 0 -1560px
}

.sp_0d2oqxMYE7L_1_5x.sx_0bdcab {
  width: 80px;
  height: 80px;
  background-position: 0 -686px
}

.sp_0d2oqxMYE7L_1_5x.sx_8bc8cb {
  width: 80px;
  height: 80px;
  background-position: 0 -768px
}

.sp_0d2oqxMYE7L_1_5x.sx_5f0f5b {
  width: 80px;
  height: 80px;
  background-position: 0 -850px
}

.sp_0d2oqxMYE7L_1_5x.sx_702595 {
  width: 80px;
  height: 80px;
  background-position: 0 -932px
}

.sp_0d2oqxMYE7L_1_5x.sx_d14568 {
  width: 80px;
  height: 80px;
  background-position: 0 -1014px
}

.sp_0d2oqxMYE7L_1_5x.sx_a8469d {
  width: 80px;
  height: 80px;
  background-position: 0 -1096px
}

.sp_0d2oqxMYE7L_1_5x.sx_e1d7e2 {
  width: 80px;
  height: 80px;
  background-position: 0 -1178px
}

.sp_0d2oqxMYE7L_1_5x.sx_d420e1 {
  width: 96px;
  height: 96px;
  background-position: 0 0
}

.sp_0d2oqxMYE7L_1_5x.sx_7ec17f {
  width: 96px;
  height: 96px;
  background-position: 0 -98px
}

.sp_0d2oqxMYE7L_1_5x.sx_da0c27 {
  width: 96px;
  height: 96px;
  background-position: 0 -196px
}

.sp_0d2oqxMYE7L_1_5x.sx_970d17 {
  width: 96px;
  height: 96px;
  background-position: 0 -294px
}

.sp_0d2oqxMYE7L_1_5x.sx_5c1d0b {
  width: 96px;
  height: 96px;
  background-position: 0 -392px
}

.sp_0d2oqxMYE7L_1_5x.sx_15dd66 {
  width: 96px;
  height: 96px;
  background-position: 0 -490px
}

.sp_0d2oqxMYE7L_1_5x.sx_85254a {
  width: 96px;
  height: 96px;
  background-position: 0 -588px
}

.sp_0d2oqxMYE7L_1_5x.sx_28e022 {
  width: 12px;
  height: 12px;
  background-position: -82px -1174px
}

.sp_0d2oqxMYE7L_1_5x.sx_33577d {
  width: 12px;
  height: 12px;
  background-position: -82px -1188px
}

.sp_0d2oqxMYE7L_1_5x.sx_2e7df2 {
  width: 12px;
  height: 12px;
  background-position: -82px -1202px
}

.sp_0d2oqxMYE7L_1_5x.sx_b51699 {
  width: 12px;
  height: 12px;
  background-position: -82px -1216px
}

.sp_0d2oqxMYE7L_1_5x.sx_a6c067 {
  width: 12px;
  height: 12px;
  background-position: -82px -1230px
}

.sp_0d2oqxMYE7L_1_5x.sx_1f7207 {
  width: 10px;
  height: 10px;
  background-position: -86px -1670px
}

.sp_0d2oqxMYE7L_1_5x.sx_456438 {
  width: 10px;
  height: 10px;
  background-position: -86px -1682px
}

.sp_0d2oqxMYE7L_1_5x.sx_d75c09 {
  width: 10px;
  height: 10px;
  background-position: -84px -1920px
}

.sp_0d2oqxMYE7L_1_5x.sx_4a0649 {
  width: 10px;
  height: 10px;
  background-position: 0 -2118px
}

.sp_0d2oqxMYE7L_1_5x.sx_4da58d {
  width: 10px;
  height: 10px;
  background-position: -12px -2118px
}

.sp_0d2oqxMYE7L_1_5x.sx_3e543a {
  width: 10px;
  height: 8px;
  background-position: -88px -1788px
}

.selected .sp_0d2oqxMYE7L_1_5x.sx_3e543a {
  background-position: -78px -1788px
}

.sp_0d2oqxMYE7L_1_5x.sx_6ca8ad {
  width: 10px;
  height: 8px;
  background-position: -72px -2100px
}

.sp_0d2oqxMYE7L_1_5x.sx_d5a04a {
  height: 8px;
  background-position: -36px -2102px
}

.sp_0d2oqxMYE7L_1_5x.sx_05ffd2 {
  height: 8px;
  background-position: -54px -2100px
}

.sp_0d2oqxMYE7L_1_5x.sx_61a379 {
  width: 6px;
  height: 12px;
  background-position: -82px -1244px
}

.sp_fM-mz8spZ1b_1_5x {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/OLzjZiUznve.png);
  background-size: 18px 504px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px
}

.sp_fM-mz8spZ1b_1_5x.sx_b16ba6 {
  background-position: 0 0
}

.sp_fM-mz8spZ1b_1_5x.sx_d995ef {
  background-position: 0 -18px
}

.sp_fM-mz8spZ1b_1_5x.sx_09b77a {
  background-position: 0 -36px
}

.sp_fM-mz8spZ1b_1_5x.sx_3ee89d {
  background-position: 0 -54px
}

.sp_fM-mz8spZ1b_1_5x.sx_cd7376 {
  background-position: 0 -72px
}

.sp_fM-mz8spZ1b_1_5x.sx_63b587 {
  background-position: 0 -90px
}

.sp_fM-mz8spZ1b_1_5x.sx_1de67c {
  background-position: 0 -108px
}

.sp_fM-mz8spZ1b_1_5x.sx_d3e7fb {
  background-position: 0 -126px
}

.sp_fM-mz8spZ1b_1_5x.sx_d34d89 {
  background-position: 0 -144px
}

.sp_fM-mz8spZ1b_1_5x.sx_791f7e {
  background-position: 0 -162px
}

.sp_fM-mz8spZ1b_1_5x.sx_3ed465 {
  background-position: 0 -180px
}

.sp_fM-mz8spZ1b_1_5x.sx_2b4647 {
  background-position: 0 -198px
}

.sp_fM-mz8spZ1b_1_5x.sx_eff7ce {
  background-position: 0 -216px
}

.sp_fM-mz8spZ1b_1_5x.sx_a20e06 {
  background-position: 0 -234px
}

.sp_fM-mz8spZ1b_1_5x.sx_0ee42b {
  background-position: 0 -252px
}

.sp_fM-mz8spZ1b_1_5x.sx_f3658e {
  background-position: 0 -270px
}

.sp_fM-mz8spZ1b_1_5x.sx_90a1fb {
  background-position: 0 -288px
}

.sp_fM-mz8spZ1b_1_5x.sx_1cd5a9 {
  background-position: 0 -306px
}

.sp_fM-mz8spZ1b_1_5x.sx_9024a0 {
  background-position: 0 -324px
}

.sp_fM-mz8spZ1b_1_5x.sx_c1d617 {
  background-position: 0 -342px
}

.sp_fM-mz8spZ1b_1_5x.sx_b713ec {
  background-position: 0 -360px
}

.sp_fM-mz8spZ1b_1_5x.sx_4f9dab {
  background-position: 0 -378px
}

.sp_fM-mz8spZ1b_1_5x.sx_bd6afe {
  background-position: 0 -396px
}

.sp_fM-mz8spZ1b_1_5x.sx_c9f24a {
  background-position: 0 -414px
}

.sp_fM-mz8spZ1b_1_5x.sx_0b25c2 {
  background-position: 0 -432px
}

.sp_fM-mz8spZ1b_1_5x.sx_4c96af {
  background-position: 0 -450px
}

.sp_fM-mz8spZ1b_1_5x.sx_cf91f2 {
  background-position: 0 -468px
}

.sp_fM-mz8spZ1b_1_5x.sx_d20e06 {
  background-position: 0 -486px
}

/*FB_PKG_DELIM*/

#bootloader_Qx0brYa {
  height: 42px;
}

.bootloader_Qx0brYa {
  display: block !important;
}