._4-eo {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .41);
  display: block
}

._3-a6 ._4-eo {
  box-shadow: none
}

._5aet ._4-eo {
  box-shadow: none
}

._5vsj ._4-eo {
  display: inline-block
}

._5v3q ._4-eo {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  position: relative
}

._5v3q ._3-a6 ._4-eo._2rn3 {
  box-shadow: none
}

._5aet ._5v3q ._4-eo {
  box-shadow: none
}

._5v3q ._31-z ._4-eo::after {
  border-bottom-width: 1px;
  border-width: 0
}

._5v3q ._4-eo::after {
  border: 1px solid rgba(0, 0, 0, .1);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

._1ktf ._4-eo::after {
  border-left: none;
  border-right: none
}

._5v3q ._3-a6 ._4-eo::after {
  border: none
}

._5tmf ._2t9n img,
._5tmf ._2t9n._4-eo,
._5tmf ._2t9n._4-eo::after {
  border-radius: 5px 5px 0 0
}

._1ktf {
  margin-left: -12px
}

._1ktf._4-eo:hover::after {
  background-color: rgba(29, 33, 41, .04)
}

._1ktf._4-eo:active::after {
  background-color: rgba(29, 33, 41, .08)
}

._9_q ._4-eo {
  width: initial !important
}

._5v3q ._4-eo._9_q,
._9_q {
  background: #000;
  box-shadow: none
}

._9_q ._4-ep {
  margin: 0 auto
}

._3-a6 ._2rn3 ._4-ep .img {
  border-radius: 18px
}

._2rn3 {
  display: inline-block
}

._3-a6 ._2rn3 {
  border: 1px solid #ebedf0;
  border-radius: 18px;
  margin-top: 2px;
  overflow: hidden
}

._4iv9 {
  margin: 5px 0
}

._5-g_ ._6to2,
._5-g_ ._8jnd {
  display: none
}

._6to2._89a- {
  top: 38px
}

._6to2,
._3-8b {
  align-items: center;
  display: flex;
  padding: 8px;
  position: absolute;
  top: 8px
}

._6to2 {
  cursor: pointer;
  left: 8px
}

._8jnd {
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 16px;
  bottom: 8px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .3), 0 2px 4px 0 rgba(0, 0, 0, .1);
  cursor: pointer;
  display: flex;
  height: 32px;
  left: 8px;
  padding: 0 12px;
  pointer-events: none;
  position: absolute;
  transition: bottom .3s ease 0s;
  z-index: 2
}

._8o0h {
  bottom: 58px
}

._8jne {
  padding-right: 7px
}

._360g ._6to2,
._360g ._8jnd {
  display: none
}

._6wbn {
  border-radius: 50%;
  box-shadow: 0 0 14px 10px rgba(0, 0, 0, .3);
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 2px
}

._6war {
  z-index: 2
}

._-iv {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 14px 0 2px 0;
  max-height: 44px;
  overflow: hidden;
  word-wrap: break-word
}

._-iw {
  color: #90949c
}

._-ix {
  margin-top: 14px
}

._d5z {
  max-width: 70%
}

._6m2 {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 0
}

.fbInternVideoPreview ._6m2 {
  z-index: 12
}

._2lhm ._6m2._6m2 {
  background-color: transparent
}

._3906._6m2 {
  overflow: visible
}

._3906._6m2 ._3907 {
  overflow: hidden;
  position: relative
}

._6m3 {
  font-size: 12px;
  height: 132px;
  margin: 10px 12px;
  position: relative
}

._59ap ._6m3._--6._k-h {
  font-size: 12px;
  height: 100px;
  margin: 10px 12px;
  position: relative
}

._59ap ._6m3._--6._7eb5 {
  font-size: 12px;
  height: 77px;
  margin: 10px 12px;
  position: relative
}

._6m3._5oi5 {
  height: 100%;
  margin: 0 12px 0 12px
}

._5oi5 ._522u {
  z-index: 1
}

._5oi5 ._6m6,
._5oi5 ._6m7 {
  text-overflow: ellipsis
}

._22p7 ._6m3 {
  height: 86px;
  margin: 5px 12px
}

._51cc ._6m3,
._3eqz._5cwb._51cc ._6m3 {
  height: 215px
}

._5ej3 ._6m3 {
  height: 88px
}

._4y_8 ._6m3 {
  height: 142px;
  margin: 6px 12px
}

._59ap ._6m3 {
  height: auto;
  margin: 10px 12px;
  max-height: 100px
}

._59ap ._6m3._649o,
._59ap._41u- ._6m3._649o {
  max-height: none
}

._649o {
  display: flex;
  flex-flow: column
}

._649o ._59tj {
  order: 0
}

._649o ._2dbt {
  margin-bottom: 0;
  order: 1
}

._649o ._6m7 {
  order: 2
}

._59ap ._649o {
  margin-top: 0
}

._59ap._6m4 ._649o ._59tj,
._5cwb._6m4 ._649o ._59tj {
  margin-top: 0
}

._649o ._2dbs {
  padding-top: 3px
}

._pb2 ._6m3 {
  height: 76px;
  margin: 6px 12px
}

._59ap._41u- ._6m3 {
  max-height: 110px
}

._3eqz._59ap._41u- ._6m3 {
  max-height: 190px
}

._6m2.exploded .lfloat {
  float: none
}

._6m2.exploded ._6m3 {
  float: none;
  height: auto;
  margin-bottom: 12px
}

._5cwb._5cwb._5cwb,
._6m4._6m4._6m4 {
  max-width: none
}

._37iz._37iz._37iz {
  max-width: -webkit-max-content;
  max-width: max-content
}

._6m4 ._6m3 {
  height: auto;
  margin-bottom: 9px
}

._6ks {
  line-height: 0;
  position: relative;
  z-index: 1
}

._6ks a:focus {
  outline: none
}

._63yw {
  position: relative
}

._5uyo {
  position: static;
  z-index: auto
}

._6ks .music_button_img_wrap {
  z-index: 1
}

._6m5 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  height: 158px;
  width: 158px
}

._51cc ._6m5 {
  height: 237px;
  width: 158px
}

._5ej3 ._6m5 {
  height: 40px;
  width: 40px
}

._51cc .__fq {
  top: 206px
}

._6m2:hover ._6m5 {
  color: rgba(0, 0, 0, .15)
}

._6m6,
._6m6._6m6._6m6._6m6 {
  font-family: Georgia, Lucida Grande, Tahoma, Verdana, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
  max-height: 110px;
  word-wrap: break-word
}

._5cwb._359m:not(._3eqz) ._6m6 {
  padding-right: 10px
}

._22p7 ._6m6,
._22p7 ._6m6._6m6._6m6._6m6 {
  max-height: 66px
}

._pb2 ._6m6,
._pb2 ._6m6._6m6._6m6._6m6 {
  font-size: 16px;
  line-height: 20px
}

._6m6,
._6m6 a {
  text-decoration: none;
  transition: color .1s ease-in-out
}

._6m6 a {
  color: #1d2129
}

._6m2:hover ._275z {
  color: #1d2129
}

._6m2 ._6jx ._6m6 a {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px
}

._6m2 ._6jx ._6m6._6m6 {
  margin-bottom: 0
}

._6m4._6m8 ._6m6 {
  margin-bottom: 0
}

._6m8 ._6m6 {
  max-height: 88px
}

._6m6 ._w_i {
  margin-right: 5px;
  position: relative;
  top: 3px
}

._6m7._6m7._6m7._6m7 {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 16px;
  max-height: 80px;
  overflow: hidden
}

._6m7 a,
._20l4 a {
  color: inherit;
  position: relative;
  z-index: 1
}

._6m7 a:hover {
  cursor: pointer;
  text-decoration: none
}

._4rtc ._6m7 {
  text-overflow: ellipsis;
  white-space: nowrap
}

._59ap ._59tj {
  padding-top: 9px;
  position: relative
}

._22p7 ._1oa0 {
  float: left;
  line-height: 14px;
  position: relative;
  top: -1px
}

._59ap .__fq {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

._59ap .__fq .__fr {
  left: 20px
}

._59ap._6m8 ._59tj,
._41u- ._6m3 ._59tj,
._170x ._59ap ._59tj {
  padding-top: 5px
}

._j_2 ._41u- ._6m3 ._59tj {
  padding-top: 9px
}

._170x ._59ap._6m8 ._59tj {
  padding-top: 21px
}

._59ap._6m8 ._6jx ._59tj {
  padding-top: 3px
}

._5cwb ._59tj {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0
}

._5cwb ._4eyp ._522u {
  bottom: 0;
  position: absolute;
  right: 0
}

._5cwb.exploded ._59tj {
  margin-top: 9px;
  position: static
}

._5cwb._6m4 ._59tj {
  margin-top: 10px;
  position: relative
}

._59ap ._59tj a:hover {
  text-decoration: none
}

._5cwb._6m4._6m8 ._59tj {
  margin-top: 5px
}

._6m3 ._6mb,
._6m3 ._d5q,
._6m3 ._59tj ._6mb a {
  color: #90949c
}

._6m3 ._6mb {
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase
}

._6m3 ._1t62 {
  color: #606770;
  font-size: 12px
}

._6m3 ._d5q {
  font-size: 12px;
  line-height: 12px
}

._6m3 ._5x4b {
  margin: -1px 1px -4px 0
}

._22p7 ._6m3 ._6mb {
  font-size: 12px;
  line-height: 12px;
  text-transform: none
}

._22p7 ._6m3 ._6mb .share_action_link {
  color: #3b5998
}

._41u- ._6m3 ._6mb,
._170x ._41u- ._6m3 ._6mb,
._170x ._6m3 ._6mb {
  padding-top: 13px
}

._j_2 ._41u- ._6m3 ._6mb {
  padding-top: 0
}

._41u-._61bj._6m4 ._6m3 ._6mb {
  padding-top: 0
}

._41u- ._6m3 ._6mb.ellipsis {
  padding-right: 8px
}

.Locale_zh_HK ._6m3 ._6mb,
.Locale_zh_TW ._6m3 ._6mb,
.Locale_zh_CN ._6m3 ._6mb {
  line-height: 12px
}

._59tj ._522u {
  padding-left: 10px
}

._522u {
  position: relative
}

._41u- ._522u {
  bottom: -4px;
  z-index: 2
}

._pb2._41u- ._522u {
  bottom: -2px
}

._59ap._41u- ._522u {
  bottom: 0
}

._59ap._41u- ._522u,
._6m4._41u- ._522u {
  position: relative
}

._4eyp ._522u {
  margin-top: -8px
}

._59ap ._2r3x>._ohe {
  width: 100%
}

._170x ._6m2 {
  margin: 0 -12px
}

._170x ._6m3 {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 0;
  padding-bottom: 10px
}

._hye ._6m7,
._170x ._6m7 {
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap
}

._hye ._6m6,
._170x ._6m6 {
  height: 22px;
  text-overflow: ellipsis
}

._5tmf ._6m8 ._2r3x>._ohe {
  width: 100%
}

._2r3x ._4g6m {
  background-color: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 50%;
  margin-left: -40px;
  margin-top: -15px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 70%;
  width: 30px;
  z-index: 2
}

._2uwc {
  border-radius: 50%;
  height: 56px;
  margin-right: 12px;
  overflow: hidden;
  width: 56px
}

._2uwd {
  display: flex;
  flex-direction: column;
  justify-content: center
}

._2r3x:hover ._4g6m {
  opacity: 1
}

._2r3x:hover ._4g6m:hover {
  transform: scale(1.1)
}

._2r3x ._1nu {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -75px -1295px;
  height: 18px;
  margin: 6px 6px;
  width: 18px
}

._2r3x ._4g6n {
  background-color: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 50%;
  margin-left: 10px;
  margin-top: -15px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 70%;
  width: 30px;
  z-index: 2
}

._2r3x:hover ._4g6n {
  opacity: 1
}

._2r3x:hover ._4g6n:hover {
  transform: scale(1.1)
}

._2r3x ._1nw {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1758px;
  height: 18px;
  margin: 6px 6px;
  width: 18px
}

._6m3 ._59tj ._6mb ._2iv2 {
  color: #3b5998;
  position: relative;
  text-decoration: none;
  z-index: 5
}

._6m3 ._59tj ._6mb ._2iv2:hover {
  text-decoration: underline
}

._6m3 ._59tj ._5tc6 {
  color: #90949c;
  margin-top: 5px
}

._6m3 ._59tj ._1cen {
  padding-left: 10px;
  padding-top: 5px
}

._6m3 ._59tj ._3vkw {
  float: left;
  margin-right: 5px
}

._41u- ._6m3 ._1cen ._6mb {
  padding-top: 0
}

._1cep {
  margin-bottom: 5px
}

._7mgo {
  display: inline-block;
  margin-bottom: 3px;
  padding-left: 10px;
  vertical-align: bottom
}

._41u- ._6m3 ._59tj._2616 {
  padding-top: 0
}

._2iv3 {
  border-top: 1px solid #e5e5e5;
  height: auto;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 6px;
  position: relative;
  width: auto;
  z-index: 5
}

._2iv4 {
  float: left;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 4px;
  margin-top: 4px;
  max-width: 75%;
  width: auto
}

._2iv5 {
  float: right;
  margin-bottom: 7px;
  width: auto
}

._5qqr ._6m6,
._5qqr ._6m7 {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%
}

._2ikp {
  border-top: solid 1px #dddfe2;
  margin: 10px 12px;
  padding-top: 11px
}

._2ikq {
  color: #90949c;
  font-size: 12px;
  padding: 14px 14px 11px 12px
}

._2iku {
  color: #4b4f56;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-top: 16px;
  position: relative;
  text-transform: uppercase;
  z-index: 10
}

._2iku a {
  color: #4b4f56;
  z-index: 10
}

._3-a6 ._pb2 ._2r3x {
  border: 1px solid #ebedf0;
  border-radius: 18px;
  margin-top: 2px;
  overflow: hidden
}

._3-a6._1blz ._pb2 ._2r3x {
  border: none;
  margin-top: 0
}

._3-a6._1blz ._pb2 ._2r3x ._3ekx {
  background-color: #f5f6f7;
  border: 1px solid #dddfe2;
  border-left: none;
  border-radius: 0 18px 18px 0
}

._3-a6._65_9 ._pb2 ._2r3x ._3ekx {
  background-color: #fff
}

._3-a6._4zau ._pb2 ._2r3x ._3ekx {
  background-color: #282828
}

._3-a6._1blz ._pb2._6m4 ._2r3x ._3ekx {
  border-left: 1px solid #dddfe2;
  border-radius: 18px
}

._3-a6 ._5pcr ._pb2 ._2r3x {
  border: none;
  border-radius: 0;
  margin-top: 0;
  overflow: hidden
}

._3eqz {
  border-radius: 5px
}

._3eqw {
  border-radius: 0;
  margin-left: -12px;
  margin-right: -12px
}

._3b6i ._3eqw {
  margin-left: -11px;
  margin-right: -11px
}

._5p9g ._3eqw,
._3-a6 ._3eqw {
  margin-left: 0;
  margin-right: 0
}

._3b6i ._3eqw {
  margin-bottom: -11px
}

._6m2._3eqz._3eqw {
  max-width: none
}

._3eqz {
  background-color: #f2f3f5
}

._3eqz._267a {
  background-color: #fff
}

._3eqz._5cwb ._6m3 {
  display: flex;
  flex-direction: column;
  height: 136px;
  justify-content: center
}

._3eqz._6m4 ._6m3,
._3eqz._5ej3._6m4 ._6m3 {
  height: auto;
  max-height: 136px
}

.UFICommentContent ._3eqz._5cwb ._6m3 {
  height: 76px
}

.UFICommentContent ._3eqz._6m4._5cwb ._6m3 {
  height: auto
}

._3ekx {
  position: relative
}

._3eqz ._3ekx {
  border-bottom: 1px solid #dddfe2;
  border-right: 1px solid #dddfe2;
  margin: 0;
  padding: 10px 12px;
  position: relative
}

._3eqz._59ap ._3ekx {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #dddfe2
}

._3eqz._5cwb ._3ekx {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px
}

._3eqz._3eqw._5cwb ._3ekx,
._3eqz._3eqw ._3ekx {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left: none;
  border-right: none;
  border-top-right-radius: 0
}

._3eqz._5cwb ._3ekx {
  border-top: 1px solid #dddfe2
}

._3b6i ._3eqz._3eqw ._3ekx {
  border-bottom: none
}

._3b6i ._5cwb._3eqz ._3ekx {
  padding-bottom: 10px
}

._3b6i ._5cwb._3eqz._3eqw ._3ekx {
  padding-bottom: 11px
}

._3-a6 ._3eqz ._3ekx {
  border: none
}

._3eqz ._3ekx:empty {
  padding: 0
}

._3eqz ._44ae {
  display: flex;
  flex-direction: row;
  justify-content: flex-end
}

._3eqz ._6m3 {
  margin: 0
}

._3eqz._59ap ._6m5,
._3eqz._59ap ._6m5 .img {
  height: 100%;
  width: 100%
}

._3eqz._5cwb._51cc._6m4 ._6m3,
._3eqz._6m4 ._6m3 {
  height: auto
}

._3eqz._5ej3 ._6m3 {
  height: 117px
}

._3eqz ._44ae ._6m3 {
  flex-grow: 1;
  overflow-x: auto
}

.ie11 ._3eqz ._44ae ._6m3 {
  flex: 1;
  max-width: 95%;
  width: 45%
}

@supports (-ms-ime-align: auto) {
  ._3eqz ._44ae ._6m3 {
    flex: 1;
    max-width: 95%;
    width: 45%
  }
}

._3eqz._5cwb ._6m3 ._59tj {
  position: static
}

._3eqz ._6m3 ._59tj {
  color: #606770;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap
}

._3eqz._41u- ._6m3 ._6mb,
._3eqz._41u- ._6m3 ._59tj ._1t62 {
  padding: 0
}

._3eqz._5cwb._6m4._6m8 ._59tj,
._3eqz._5cwb._6m4 ._59tj {
  margin-top: 2px
}

._3eqz ._6m3 ._59tj ._5tc6 {
  color: #606770;
  margin-top: 3px
}

#facebook ._3eqz ._6m3 ._6m6 {
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 3px 0 0;
  padding-top: 2px
}

#facebook ._3eqz._2rk1 ._6m3 ._6m6 {
  font-weight: 600;
  overflow: hidden
}

._3eqz ._6m3 ._6m7._6m7,
._3eqz ._6m3 ._6m7._6m7 .fsm,
._3eqz ._6m3 ._6m7._6m7 .fwn,
._3eqz ._6m3 ._6m7._6m7 .fcg,
._3eqz ._6m3 ._20l4._20l4,
._3eqz ._6m3 ._20l4._20l4 .fsm,
._3eqz ._6m3 ._20l4._20l4 .fwn,
._3eqz ._6m3 ._20l4._20l4 .fcg {
  color: #606770;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word
}

._3eqz ._6m3 ._6m7._6m7 a:hover,
._3eqz ._6m3 ._20l4._20l4 a:hover {
  text-decoration: underline
}

._3eqz ._6m3 ._6m7._6m7,
._3eqz ._6m3 ._20l4._20l4 {
  margin-top: 3px
}

._3eqz ._44af {
  align-self: center;
  padding-left: 18px
}

._3n1j ._6m6,
._3n1j ._6m7 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal
}

._3n1j ._6m6 {
  -webkit-line-clamp: 2
}

._3n1j ._6m7 {
  -webkit-line-clamp: 1
}

._3n1j ._6m6:empty~._6m7 {
  -webkit-line-clamp: 2
}

._3n1j ._3n1k {
  max-height: 46px;
  overflow: hidden
}

.fixed_elem,
.fixed_always {
  position: fixed !important
}

.tinyHeight .fixed_elem {
  position: static !important
}

.chrome .fixed_elem,
.chrome .fixed_always {
  transform: translateZ(0)
}

.tinyHeight .chrome .fixed_elem {
  transform: none
}

._64nf {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1940px;
  height: 16px;
  margin-left: 2px;
  width: 16px
}

._64nf._5dzz {
  width: 16px
}

.profileLink ._64nf,
._2u0z ._64nf,
._5vra ._64nf,
._64-f ._64nf,
._52eh ._64nf {
  position: relative;
  top: 3px
}

._5_jv .profileLink ._64nf,
._64-f ._64nf {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -25px -1637px;
  height: 24px;
  margin-left: 4px;
  position: relative;
  top: 5px;
  width: 24px
}

.highContrast ._59c6._5dzz:before {
  /* content: url(./Y_2rPZLlNeZ.png); */
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 1px;
  position: absolute
}

.highContrast ._59c6._5dzz {
  overflow: hidden;
  position: relative
}

._59c6._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 1px -2058px
}

.highContrast ._59c6._5d-3._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 1px;
  position: absolute
}

.highContrast ._59c6._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._59c6._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 1px -2058px
}

.highContrast ._59c6._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 1px;
  position: absolute
}

.highContrast ._59c6._5dz- {
  overflow: hidden;
  position: relative
}

._59c6._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 1px -2058px
}

.highContrast ._59c6._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 1px;
  position: absolute
}

.highContrast ._59c6._5dz_ {
  overflow: hidden;
  position: relative
}

._59c6._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 1px -2058px
}

._3qcr._5dzy {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1940px
}

.uiTypeaheadView .basic ._5dzy {
  float: none
}

.uiTypeaheadView .compact ._5dzy {
  display: inline-block;
  position: static
}

.UFIReplySocialSentenceLinkText ._5dzy {
  margin-left: 1px
}

._5dzy {
  display: inline-block
}

._5dzz {
  height: 14px;
  margin-left: 3px;
  vertical-align: -2px;
  width: 14px
}

._5d-3._5dzz {
  height: 16px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 16px
}

._5dz- {
  height: 12px;
  margin-left: 3px;
  vertical-align: -1px;
  width: 12px
}

._5dz_ {
  height: 17px;
  margin-left: 3px;
  vertical-align: -4px;
  width: 17px
}

._5d-0 {
  height: 19px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 19px
}

._5d-1 {
  height: 19px;
  margin-left: 4px;
  vertical-align: -2px;
  width: 19px
}

._6g4u {
  height: 20px;
  margin-left: 2px;
  vertical-align: -3px;
  width: 20px
}

._5d-3._5d-1 {
  height: 26px;
  margin-left: 4px;
  vertical-align: -5px;
  width: 26px
}

._7xv0 {
  margin-left: 2px;
  position: relative;
  top: 1px
}

._7xv0._5dzy {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1157px;
  border-radius: 50%;
  height: 12px;
  top: -1px;
  width: 12px
}

.highContrast ._4fvy._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1393px 0 0 -77px;
  position: absolute
}

.highContrast ._4fvy._5dzz {
  overflow: hidden;
  position: relative
}

._4fvy._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -77px -1393px
}

.highContrast ._4fvy._5d-3._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1393px 0 0 -77px;
  position: absolute
}

.highContrast ._4fvy._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._4fvy._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -77px -1393px
}

.highContrast ._4fvy._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1393px 0 0 -77px;
  position: absolute
}

.highContrast ._4fvy._5dz- {
  overflow: hidden;
  position: relative
}

._4fvy._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -77px -1393px
}

.highContrast ._4fvy._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1393px 0 0 -77px;
  position: absolute
}

.highContrast ._4fvy._5dz_ {
  overflow: hidden;
  position: relative
}

._4fvy._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -77px -1393px
}

._59t2._5dzz {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yX/r/XTBCJQ4brFv.png)
}

._59t2._5dz- {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yt/r/9P4tFVoazCQ.png)
}

._59t2._5dz_ {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yl/r/seydRWcRiTg.png)
}

._59t2._5d-0 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/ye/r/ui6i-KsMj_C.png)
}

._59t2._5d-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y_/r/pAT_aWF8wR_.png)
}

.highContrast ._1gop._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 -68px;
  position: absolute
}

.highContrast ._1gop._5dzz {
  overflow: hidden;
  position: relative
}

._1gop._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -2058px;
  height: 16px;
  width: 16px
}

.highContrast .uiTypeaheadView .selected ._1gop._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2058px 0 0 -51px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._1gop._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._1gop._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -2058px;
  -webkit-filter: brightness(200%);
  height: 16px;
  width: 16px
}

.highContrast ._56_f._5dzz:before,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1052px 0 0 -80px;
  position: absolute
}

.highContrast ._56_f._5dzz,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz {
  overflow: hidden;
  position: relative
}

._56_f._5dzz,
.uiTypeaheadView .search.updatedSuggestionRows .selected ._56_f._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1052px
}

.highContrast ._56_f._5d-3._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1413px 0 0 -78px;
  position: absolute
}

.highContrast ._56_f._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._56_f._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -78px -1413px
}

._56_f._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1014px
}

.highContrast ._56_f._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2059px 0 0 -17px;
  position: absolute
}

.highContrast ._56_f._5dz_ {
  overflow: hidden;
  position: relative
}

._56_f._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -2059px
}

.highContrast ._56_f._5d-0:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1712px 0 0 -48px;
  position: absolute
}

.highContrast ._56_f._5d-0 {
  overflow: hidden;
  position: relative
}

._56_f._5d-0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -48px -1712px
}

.highContrast ._56_f._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1295px 0 0 -55px;
  position: absolute
}

.highContrast ._56_f._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1295px
}

.highContrast ._56_f._5d-3._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1609px 0 0 0;
  position: absolute
}

.highContrast ._56_f._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._5d-3._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1609px
}

.highContrast ._56_f._3vjg._5dzz:before,
.highContrast ._56_f._5d-3._3vjg ._5dzz:before,
.highContrast ._56_f._3vjg._5dz-:before,
.highContrast ._56_f._3vjg._5dz_:before,
.highContrast ._56_f._3vjg._5d-0:before,
.highContrast ._56_f._3vjg._5d-1:before,
.highContrast ._56_f._5d-3._3vjg ._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -999px 0 0 -80px;
  position: absolute
}

.highContrast ._56_f._3vjg._5dzz,
.highContrast ._56_f._5d-3._3vjg ._5dzz,
.highContrast ._56_f._3vjg._5dz-,
.highContrast ._56_f._3vjg._5dz_,
.highContrast ._56_f._3vjg._5d-0,
.highContrast ._56_f._3vjg._5d-1,
.highContrast ._56_f._5d-3._3vjg ._5d-1 {
  overflow: hidden;
  position: relative
}

._56_f._3vjg._5dzz,
._56_f._5d-3._3vjg ._5dzz,
._56_f._3vjg._5dz-,
._56_f._3vjg._5dz_,
._56_f._3vjg._5d-0,
._56_f._3vjg._5d-1,
._56_f._5d-3._3vjg ._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -999px
}

.highContrast .uiTypeaheadView .selected ._56_f._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -804px 0 0 -81px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._56_f._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._56_f._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -804px
}

.highContrast .selected ._56_f._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2059px 0 0 -34px;
  position: absolute
}

.highContrast .selected ._56_f._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._56_f._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2059px
}

.UFICommentContent ._56_f {
  margin-left: 1px
}

.tooltipContent ._4ag8 {
  overflow: hidden;
  white-space: normal;
  width: 164px
}

.highContrast ._5n3t._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -957px 0 0 -80px;
  position: absolute
}

.highContrast ._5n3t._5dzz {
  overflow: hidden;
  position: relative
}

._5n3t._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -957px
}

.highContrast ._5n3t._5d-3._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2041px 0 0 1px;
  position: absolute
}

.highContrast ._5n3t._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._5n3t._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 1px -2041px
}

.highContrast ._5n3t._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -985px 0 0 -80px;
  position: absolute
}

.highContrast ._5n3t._5dz- {
  overflow: hidden;
  position: relative
}

._5n3t._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -985px
}

.highContrast ._5n3t._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2042px 0 0 -17px;
  position: absolute
}

.highContrast ._5n3t._5dz_ {
  overflow: hidden;
  position: relative
}

._5n3t._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -2042px
}

.highContrast ._5n3t._5d-0:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1757px 0 0 -20px;
  position: absolute
}

.highContrast ._5n3t._5d-0 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -20px -1757px
}

.highContrast ._5n3t._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1577px 0 0 -76px;
  position: absolute
}

.highContrast ._5n3t._5d-1 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -76px -1577px
}

.highContrast ._5n3t._5d-3._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1608px 0 0 -27px;
  position: absolute
}

.highContrast ._5n3t._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._5n3t._5d-3._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -27px -1608px
}

.highContrast .uiTypeaheadView .selected ._5n3t._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -971px 0 0 -80px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._5n3t._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._5n3t._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -971px
}

.highContrast .selected ._5n3t._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -2042px 0 0 -34px;
  position: absolute
}

.highContrast .selected ._5n3t._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._5n3t._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2042px
}

.UFICommentContent ._5n3t {
  margin-left: 1px
}

.highContrast ._6w81._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1065px 0 0 -80px;
  position: absolute
}

.highContrast ._6w81._5dzz {
  overflow: hidden;
  position: relative
}

._6w81._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1065px
}

.highContrast ._6w81._5d-3._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1065px 0 0 -80px;
  position: absolute
}

.highContrast ._6w81._5d-3._5dzz {
  overflow: hidden;
  position: relative
}

._6w81._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1065px
}

.highContrast ._6w81._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1065px 0 0 -80px;
  position: absolute
}

.highContrast ._6w81._5dz- {
  overflow: hidden;
  position: relative
}

._6w81._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1065px;
  vertical-align: -2px
}

.highContrast ._6w81._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1939px 0 0 -67px;
  position: absolute
}

.highContrast ._6w81._5dz_ {
  overflow: hidden;
  position: relative
}

._6w81._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -67px -1939px
}

.highContrast ._6w81._5d-0:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1736px 0 0 -62px;
  position: absolute
}

.highContrast ._6w81._5d-0 {
  overflow: hidden;
  position: relative
}

._6w81._5d-0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -62px -1736px
}

.highContrast ._6w81._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1736px 0 0 -62px;
  position: absolute
}

.highContrast ._6w81._5d-1 {
  overflow: hidden;
  position: relative
}

._6w81._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -62px -1736px
}

.highContrast ._6w81._5d-3._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1636px 0 0 -49px;
  position: absolute
}

.highContrast ._6w81._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._6w81._5d-3._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -49px -1636px
}

.highContrast .uiTypeaheadView .selected ._6w81._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1195px 0 0 -80px;
  position: absolute
}

.highContrast .uiTypeaheadView .selected ._6w81._5dzz {
  overflow: hidden;
  position: relative
}

.uiTypeaheadView .selected ._6w81._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1195px
}

.highContrast .selected ._6w81._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1195px 0 0 -80px;
  position: absolute
}

.highContrast .selected ._6w81._5dz_ {
  overflow: hidden;
  position: relative
}

.selected ._6w81._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1195px
}

.UFICommentContent ._6w81 {
  margin-left: 1px
}

.tooltipContent ._6w82 {
  overflow: hidden;
  white-space: normal;
  width: 164px
}

._7cto {
  margin: 4px 8px
}

._7ctr {
  border-bottom: 1px solid #dddfe2;
  padding: 4px 0;
  text-align: justify
}

._7cts {
  margin: 8px 0 0 auto
}

._5d62._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -772px
}

._5d62._5d-3._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -78px -1375px
}

._5d62._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -788px
}

._5d62._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -2041px
}

._5d62._5d-0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -25px -1712px
}

._5d62._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -35px -1295px
}

._5d62._5d-3._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -49px -1578px
}

.highContrast ._8b0y._5d-3._5dzz:before,
.highContrast ._8b0y._5dzz:before,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz:before,
.highContrast .uiTypeaheadView .selected ._8b0y._5dzz:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1039px 0 0 -80px;
  position: absolute
}

.highContrast ._8b0y._5d-3._5dzz,
.highContrast ._8b0y._5dzz,
.highContrast .uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz,
.highContrast .uiTypeaheadView .selected ._8b0y._5dzz {
  overflow: hidden;
  position: relative
}

._8b0y._5d-3._5dzz,
._8b0y._5dzz,
.uiTypeaheadView .search.updatedSuggestionRows .selected ._8b0y._5dzz,
.uiTypeaheadView .selected ._8b0y._5dzz {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1039px
}

.highContrast ._8b0y._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1040px 0 0 -81px;
  position: absolute
}

.highContrast ._8b0y._5dz- {
  overflow: hidden;
  position: relative
}

._8b0y._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1040px;
  height: 12px;
  margin-bottom: 1px;
  width: 12px
}

.highContrast ._8b0y._5dz_:before,
.highContrast .selected ._8b0y._5dz_:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1800px 0 0 -72px;
  position: absolute
}

.highContrast ._8b0y._5dz_,
.highContrast .selected ._8b0y._5dz_ {
  overflow: hidden;
  position: relative
}

._8b0y._5dz_,
.selected ._8b0y._5dz_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -72px -1800px;
  height: 16px;
  margin-bottom: 1px;
  width: 16px
}

.highContrast ._8b0y._5d-0:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1712px 0 0 -48px;
  position: absolute
}

.highContrast ._8b0y._5d-0 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -48px -1712px
}

.highContrast ._8b0y._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1314px 0 0 -74px;
  position: absolute
}

.highContrast ._8b0y._5d-1 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -74px -1314px
}

.highContrast ._8b0y._5d-3._5d-1:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1608px 0 0 -53px;
  position: absolute
}

.highContrast ._8b0y._5d-3._5d-1 {
  overflow: hidden;
  position: relative
}

._8b0y._5d-3._5d-1 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -53px -1608px
}

.UFICommentContent ._8b0y {
  margin-left: 1px
}

.tooltipContent ._8b11 {
  overflow: hidden;
  white-space: normal;
  width: 164px
}

.highContrast ._8b-m._5dz-:before {
  content: url(./Y_2rPZLlNeZ.png);
  margin: -1026px 0 0 -80px;
  position: absolute
}

.highContrast ._8b-m._5dz- {
  overflow: hidden;
  position: relative
}

._8b-m._5dz- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -80px -1026px;
  height: 14px;
  margin-top: 2px
}

a._58cn:hover {
  text-decoration: none
}

a:hover ._58cm {
  text-decoration: underline
}

._58cn ._58cl {
  color: #365899
}

.UFIContainer ._58cn ._58cl {
  color: #90949c
}

._53ij {
  background: #fff;
  background: var(--card-background, #FFFFFF);
  position: relative
}

._53io {
  overflow: hidden;
  position: absolute
}

._53ih ._53io {
  display: none
}

._54ni {
  overflow: hidden;
  white-space: nowrap
}

._54nc,
._54nc:hover,
._54nc:active,
._54nc:focus {
  display: block;
  outline: none;
  text-decoration: none
}

._54nh {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._54nu .img,
._54nu ._54nh {
  display: inline-block;
  vertical-align: middle
}

._2agf {
  word-wrap: normal
}

._2agf._4o_4 {
  display: inline-flex
}

._55pe {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap
}

._5f0v {
  outline: none
}

._3oxt {
  outline: 1px dotted #3b5998;
  outline-color: invert
}

.webkit ._3oxt {
  outline: 5px auto #5b9dd9
}

.win.webkit ._3oxt {
  outline-color: #e59700
}

i.img {
  -ms-high-contrast-adjust: none
}

i.img u {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  left: auto;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

.lfloat {
  float: left
}

.rfloat {
  float: right
}

.uiCloseButton {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  zoom: 1
}

label.uiCloseButton {
  overflow: hidden
}

.uiCloseButton input {
  cursor: pointer;
  opacity: 0;
  outline: none;
  padding: 18px
}

.uiCloseButton {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/4WSewcWboV8.png);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px
}

.uiCloseButtonSmall {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yx/r/ogYrclupeJV.png);
  height: 11px;
  margin-top: 1px;
  width: 11px
}

.uiCloseButtonHuge {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/ubIGOTIgUYc.png)
}

.uiCloseButtonDark {
  background-position: left -16px
}

.uiCloseButtonInverted {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yo/r/33RpiRygxAr.png);
  background-position: left -16px
}

.uiCloseButtonGray {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yY/r/51lL0S8tLwD.png)
}

.uiCloseButton:hover,
.uiCloseButton:focus {
  background-position: left -32px
}

.uiCloseButton:active {
  background-position: left -48px
}

.uiCloseButtonSmallDark {
  background-position: left -12px
}

.uiCloseButtonSmallGray {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/Z6iX1mRLEPH.png)
}

.uiCloseButtonSmallInverted {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/Vi-KTnbdMKr.png);
  background-position: left -12px
}

.uiCloseButtonSmall:hover,
.uiCloseButtonSmall:focus {
  background-position: left -24px
}

.uiCloseButtonSmall:active {
  background-position: left -36px
}

.uiCloseButtonHighContrast {
  display: none
}

.highContrast .uiCloseButtonHighContrast {
  display: inline-block
}

.uiPopover .uiCloseButton {
  background-clip: padding-box;
  border: 1px solid transparent;
  margin: 0
}

.uiPopover.openToggler .uiCloseButton {
  background-position: left -32px
}

.uiPopover.openToggler .uiCloseButtonSmall {
  background-position: left -24px
}

.uiPopover.openToggler .uiCloseButton,
.uiPopover.openToggler .uiCloseButton:active,
.uiPopover.openToggler .uiCloseButton:focus,
.uiPopover.openToggler .uiCloseButton:hover {
  background-color: #6d84b4;
  border: 1px solid #365899;
  border-bottom-color: #4267b2;
  box-shadow: none
}

.uiChevronSelectorButton .uiCloseButton {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yQ/r/P7iQUKoCRnx.png);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px
}

.uiChevronSelectorButtonSmall .uiCloseButtonSmall {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y_/r/DFLEwJwc0A7.png);
  background-repeat: no-repeat;
  height: 11px;
  width: 11px
}

.uiInputLabel {
  position: relative
}

.uiInputLabel+.uiInputLabel {
  margin-top: 3px
}

.uiInputLabel+.inlineBlock.uiInputLabel {
  margin-left: 10px;
  margin-top: 0
}

.uiInputLabelInput {
  margin: 0;
  padding: 0;
  position: absolute
}

.uiInputLabelRadio {
  margin-top: 1px
}

.uiInputLabel .uiInputLabelLabelFlipped {
  display: inline-block;
  margin-right: 17px;
  vertical-align: baseline
}

.uiInputLabel .uiInputLabelLabel {
  display: inline-block;
  margin-left: 17px;
  vertical-align: baseline
}

.uiInputLabelLegacy label {
  color: #333;
  font-weight: normal
}

.webkit.mac .uiInputLabel .uiInputLabelLabel {
  margin-left: 16px
}

.webkit.mac .uiInputLabelRadio,
.webkit.mac .uiInputLabelCheckbox {
  margin-top: 2px
}

div._3qw {
  height: auto;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 400
}

._31e {
  position: fixed !important;
  width: 100%
}

.webkit ._42w {
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 1px
}

._3ixn {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0
}

._3qw ._3ixn {
  background-color: rgba(255, 255, 255, .8)
}

._3qx ._3ixn {
  background-color: rgba(0, 0, 0, .9)
}

._4-hy ._3ixn {
  background-color: rgba(0, 0, 0, .4)
}

._99rc ._3ixn {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, .5)
}

.uiLayer {
  outline: none
}

._57-x {
  padding: 36px 0;
  text-align: center
}

._53ip ._53iv {
  padding: 15px
}

._53ip ._53ij {
  border: 1px solid #8c8c8c;
  border: 1px solid rgba(0, 0, 0, .45);
  border-bottom: 1px solid #666;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3)
}

._53ip ._572u {
  padding: 6px 7px 6px 8px
}

._53ip ._5v-0 {
  padding-bottom: 10px
}

._53ip ._53il {
  padding-top: 10px
}

._53ip ._53im {
  padding-right: 10px
}

._53ip ._53ik {
  padding-bottom: 10px
}

._53ip ._53in {
  padding-left: 10px
}

._53ip ._53il ._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -75px -1700px;
  height: 9px;
  top: 2px;
  width: 16px
}

._53ip ._53im ._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -721px;
  height: 16px;
  right: 2px;
  width: 9px
}

._53ip ._53ik ._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -78px -1565px;
  bottom: 2px;
  height: 9px;
  width: 16px
}

._53ip ._53ik._kc ._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -77px -1596px
}

._53ip ._53in ._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -704px;
  height: 16px;
  left: 2px;
  width: 9px
}

.uiContextualDialogFooterLink {
  background: #f5f6f7;
  border-top: 1px solid #e0e0e0;
  display: block;
  font-weight: bold;
  padding: 7px;
  text-align: center
}

.uiContextualDialogFooterLink:hover {
  background: #6d84b4;
  border-color: #365899;
  color: #fff;
  text-decoration: none
}

.uiScrollableArea {
  direction: ltr;
  height: 100%;
  overflow: hidden;
  position: relative
}

.uiScrollableAreaWrap {
  height: 100%;
  outline: none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative
}

.uiScrollableAreaWrapHorizontal {
  overflow-x: auto
}

.uiScrollableArea .uiScrollableAreaWrap {
  overflow-y: scroll
}

.uiScrollableArea.nofade .uiScrollableAreaWrap,
.uiScrollableArea.fade .uiScrollableAreaWrap {
  margin-right: -30px;
  padding-right: 30px
}

.uiScrollableArea.nofade .uiScrollableAreaBody {
  padding-right: 10px
}

.native .uiScrollableAreaBody,
.no_js .uiScrollableAreaBody {
  width: auto !important
}

.uiScrollableAreaBottomAligned .uiScrollableAreaShadow {
  display: block
}

.uiScrollableAreaBottomAligned .uiScrollableAreaBody {
  height: 100%
}

.uiScrollableAreaBottomAligned .uiScrollableAreaContent {
  bottom: 0;
  position: absolute;
  width: 100%
}

.uiScrollableAreaBody {
  direction: ltr;
  position: relative
}

.uiScrollableAreaTrack {
  bottom: 2px;
  display: block;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 2px;
  -webkit-user-select: none;
  width: 7px;
  z-index: 5
}

.contentAfter .uiScrollableAreaTrack:hover,
.contentBefore .uiScrollableAreaTrack:hover,
.contentAfter.uiScrollableAreaTrackOver .uiScrollableAreaTrack,
.contentBefore.uiScrollableAreaTrackOver .uiScrollableAreaTrack,
.uiScrollableAreaDragging .uiScrollableAreaTrack {
  background-color: rgba(0, 0, 0, .15);
  border-radius: 10px;
  width: 10px
}

.contentBefore.uiScrollableAreaTrackOver,
.contentAfter.uiScrollableAreaTrackOver,
.uiScrollableAreaDragging {
  cursor: default
}

.uiScrollableArea .uiContextualLayerPositioner {
  z-index: 4
}

.uiScrollableAreaShadow,
.native .uiScrollableAreaTrack,
.no_js .uiScrollableAreaTrack {
  display: none
}

.uiScrollableAreaGripper {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, .4);
  border: 1px solid rgba(85, 85, 85, .6);
  border-radius: 7px;
  position: absolute;
  right: 0;
  transition: width 250ms;
  width: 5px
}

.uiScrollableAreaDragging .uiScrollableAreaGripper,
.uiScrollableAreaTrackOver .uiScrollableAreaGripper,
.uiScrollableAreaTrack:hover .uiScrollableAreaGripper {
  width: 8px
}

.uiScrollableArea.contentBefore:before,
.uiScrollableArea.contentAfter:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  width: 100%;
  z-index: 99
}

.uiScrollableArea.contentBefore:before {
  top: 0
}

.uiScrollableArea.contentAfter:after {
  bottom: 0
}

.uiScrollableAreaWithTopShadow.contentBefore:before,
.uiScrollableAreaWithShadow.contentAfter:after {
  background-color: rgba(0, 0, 0, .07)
}

.uiContextualLayerPositioner {
  height: 0;
  position: absolute;
  z-index: 202
}

.uiContextualLayer {
  position: absolute
}

div.uiContextualLayerPositionerFixed {
  position: fixed
}

.uiContextualLayerParent {
  position: relative
}

.uiTooltipX {
  max-width: 334px;
  word-wrap: break-word;
  position: relative;
  zoom: 1
}

.uiTooltipX .tooltipContent {
  background-color: #282828;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 8px;
  text-align: left
}

.uiTooltipX .tooltipText span {
  zoom: 1
}

.uiTooltipX i.arrow {
  border: 4px solid transparent;
  font-size: 0;
  height: 0;
  line-height: 0;
  position: absolute;
  width: 0
}

.uiContextualLayerAboveLeft>.uiTooltipX,
.uiContextualLayerAboveRight>.uiTooltipX,
.uiContextualLayerAboveCenter>.uiTooltipX {
  margin-bottom: 1px;
  padding-bottom: 4px
}

.uiContextualLayerAboveLeft>.uiTooltipX i.arrow,
.uiContextualLayerAboveRight>.uiTooltipX i.arrow,
.uiContextualLayerAboveCenter>.uiTooltipX i.arrow {
  border-bottom: 0;
  border-top-color: #282828;
  bottom: 0
}

.uiContextualLayerBelowLeft>.uiTooltipX,
.uiContextualLayerBelowRight>.uiTooltipX,
.uiContextualLayerBelowCenter>.uiTooltipX {
  margin-top: 1px;
  padding-top: 4px
}

.uiContextualLayerBelowLeft>.uiTooltipX i.arrow,
.uiContextualLayerBelowRight>.uiTooltipX i.arrow,
.uiContextualLayerBelowCenter>.uiTooltipX i.arrow {
  border-bottom-color: #282828;
  border-top: 0;
  top: 0
}

.uiContextualLayerAboveLeft>.uiTooltipX i.arrow,
.uiContextualLayerBelowLeft>.uiTooltipX i.arrow {
  left: 12px
}

.uiContextualLayerAboveRight>.uiTooltipX i.arrow,
.uiContextualLayerBelowRight>.uiTooltipX i.arrow {
  right: 12px
}

.uiContextualLayerAboveCenter>.uiTooltipX i.arrow,
.uiContextualLayerBelowCenter>.uiTooltipX i.arrow {
  left: 50%;
  margin-left: -4px
}

.uiContextualLayerLeft>.uiTooltipX {
  margin-right: 1px;
  padding-right: 4px
}

.uiContextualLayerLeft>.uiTooltipX i.arrow {
  border-left-color: #282828;
  border-right: 0;
  right: 0;
  top: 10px
}

.uiContextualLayerRight>.uiTooltipX {
  margin-left: 1px;
  padding-left: 4px
}

.uiContextualLayerRight>.uiTooltipX i.arrow {
  border-left: 0;
  border-right-color: #282828;
  left: 0;
  top: 10px
}

._rv {
  height: 100px;
  width: 100px
}

._rw {
  height: 50px;
  width: 50px
}

._s0:only-child {
  display: block
}

._3tm9 {
  height: 14px;
  width: 14px
}

._54rv {
  height: 16px;
  width: 16px
}

._3qxe {
  height: 19px;
  width: 19px
}

._1m6h {
  height: 24px;
  width: 24px
}

._3d80 {
  height: 28px;
  width: 28px
}

._54ru {
  height: 32px;
  width: 32px
}

._tzw {
  height: 40px;
  width: 40px
}

._54rt {
  height: 48px;
  width: 48px
}

._54rs {
  height: 56px;
  width: 56px
}

._1m9m {
  height: 64px;
  width: 64px
}

._ry {
  height: 24px;
  width: 24px
}

a._p {
  display: block
}

._36gl {
  position: absolute;
  right: 12px;
  top: 14px
}

._21es {
  background: #3578e5;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  padding: 10px 34px 12px 10px;
  position: relative
}

._6dh- ._21es {
  background: #373737
}

._izg {
  padding-right: 10px
}

._2x6q ._53ij {
  background-color: transparent
}

._2x6q ._5v-0,
._2x6q ._53ik {
  padding-bottom: 13px
}

._2x6q ._53il {
  padding-top: 13px
}

._2x6q ._53im {
  padding-right: 13px
}

._2x6q ._53in {
  padding-left: 13px
}

._2x6q ._53il ._53io {
  border-bottom: 9px solid #3578e5;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  top: 4px
}

._2x6q._6dh- ._53il ._53io {
  border-bottom-color: #373737
}

._2x6q ._53ik ._53io {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #3578e5;
  bottom: 4px
}

._2x6q._6dh- ._53ik ._53io {
  border-top-color: #373737
}

._2x6q ._53in ._53io {
  border-bottom: 9px solid transparent;
  border-right: 9px solid #3578e5;
  border-top: 9px solid transparent;
  left: 4px
}

._2x6q._6dh- ._53in ._53io {
  border-right-color: #373737
}

._2x6q ._53im ._53io {
  border-bottom: 9px solid transparent;
  border-left: 9px solid #3578e5;
  border-top: 9px solid transparent;
  right: 4px
}

._2x6q._6dh- ._53im ._53io {
  border-left-color: #373737
}

.__xn,
.__xn a {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-shadow: 0 0 2px rgba(0, 0, 0, .3)
}

._8wpp {
  background-color: var(--fds-white-text);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--fds-highlight);
  display: inline-block;
  margin: 0 2px;
  min-width: 28px;
  padding: 0 5px;
  text-align: center;
  text-shadow: none
}

.__xn a {
  text-decoration: underline
}

._4jy0 {
  border: 1px solid;
  border-radius: 2px;
  box-sizing: content-box;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  justify-content: center;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-shadow: none;
  vertical-align: middle
}

.segoe ._4jy0 {
  font-weight: 600
}

._4jy0:before {
  content: '';
  display: inline-block;
  height: 20px;
  vertical-align: middle
}

html ._4jy0:focus {
  box-shadow: 0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15);
  outline: none
}

._19_u ._4jy0:focus,
._4jy0._5f0v:focus {
  box-shadow: none
}

._4jy0 {
  transition: 200ms cubic-bezier(.08, .52, .52, 1) background-color, 200ms cubic-bezier(.08, .52, .52, 1) box-shadow, 200ms cubic-bezier(.08, .52, .52, 1) transform
}

._4jy0:active {
  transition: none
}

.mac ._4jy0:not(._42fr):active {
  box-shadow: none;
  transform: scale(.98)
}

._4jy0 .img {
  bottom: 1px;
  position: relative;
  vertical-align: middle
}

form.async_saving ._4jy0 .img,
a.async_saving._4jy0 .img,
._4jy0._42fr .img {
  opacity: .5
}

._517h,
._59pe:focus,
._59pe:hover {
  background-color: #f5f6f7;
  border-color: #ccd0d5;
  color: #4b4f56
}

._64lx ._517h,
._64lx ._59pe:focus,
._64lx ._59pe:hover {
  background-color: #eff1f3;
  border-color: #bec3c9
}

._517h:hover {
  background-color: #ebedf0
}

._517h:active,
._517h._42fs {
  background-color: #dddfe2;
  border-color: #bec3c9
}

form.async_saving ._517h,
a.async_saving._517h,
._517h._42fr {
  background-color: #f5f6f7;
  border-color: #dddfe2;
  color: #bec3c9
}

._517h._42fs {
  color: #4080ff
}

._4jy1,
._4jy2 {
  color: #fff
}

._4jy1 {
  background-color: #4267b2;
  border-color: #4267b2
}

._4jy1:hover {
  background-color: #365899;
  border-color: #365899
}

._4jy1:active,
._4jy1._42fs {
  background-color: #29487d;
  border-color: #29487d
}

form.async_saving ._4jy1,
a.async_saving._4jy1,
._4jy1._42fr {
  background-color: #9cb4d8;
  border-color: #9cb4d8
}

._4jy2 {
  background-color: #42b72a;
  border-color: #42b72a
}

._4jy2:hover {
  background-color: #36a420;
  border-color: #36a420
}

._4jy2:active,
._4jy2._42fs {
  background-color: #2b9217;
  border-color: #2b9217
}

form.async_saving ._4jy2,
a.async_saving._4jy2,
._4jy2._42fr {
  background-color: #ace0a2;
  border-color: #ace0a2
}

._59pe,
form.async_saving ._59pe,
a.async_saving._59pe,
._59pe._42fr {
  background: none;
  border-color: transparent
}

._517i,
._517i._42fr:active,
._517i._42fr._42fs {
  height: 18px;
  line-height: 18px
}

._4jy3,
._4jy3._42fr:active,
._4jy3._42fr._42fs {
  line-height: 22px
}

._4jy4,
._4jy4._42fr:active,
._4jy4._42fr._42fs {
  line-height: 26px;
  padding: 0 10px
}

._4jy5,
._4jy5._42fr:active,
._4jy5._42fr._42fs {
  line-height: 34px;
  padding: 0 16px
}

._4jy6,
._4jy6._42fr:active,
._4jy6._42fr._42fs {
  line-height: 42px;
  padding: 0 24px
}

._51xa ._4jy0 {
  border-radius: 0;
  display: inline-block;
  margin: 0 !important;
  margin-left: -1px !important;
  position: relative;
  z-index: 1
}

._51xa>._4jy0:first-child,
._51xa>:first-child ._4jy0 {
  border-radius: 2px 0 0 2px;
  margin-left: 0 !important
}

._51xa>._4jy0:last-child,
._51xa>:last-child ._4jy0 {
  border-radius: 0 2px 2px 0
}

._51xa>._4jy0:only-child,
._51xa>:only-child ._4jy0 {
  border-radius: 2px
}

._51xa ._4jy0._42fr {
  z-index: 0
}

._51xa ._4jy0._4jy1,
._51xa ._4jy0._4jy2 {
  z-index: 2
}

._51xa ._4jy0:focus {
  z-index: 3
}

._51xa ._4jy1+.uiPopover>._4jy1:not(:focus):after {
  background-color: #f5f6f7;
  bottom: -1px;
  content: '';
  display: block;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1px
}

._4jy0._52nf {
  cursor: default
}

._9c6._9c6 {
  background-clip: padding-box;
  border-color: rgba(0, 0, 0, .4)
}

._3y89 ._4jy0 {
  border-bottom-width: 0;
  border-top-width: 0
}

._3y89>._4jy0:first-child,
._3y89>:first-child ._4jy0 {
  border-left-width: 0;
  border-radius: 1px 0 0 1px
}

._3y89>._4jy0:last-child,
._3y89>:last-child ._4jy0 {
  border-radius: 0 1px 1px 0;
  border-right-width: 0
}

._6n1z._4jy6,
._6n1z._4jy6._42fr:active,
._6n1z._4jy6._42fr._42fs {
  padding: 0 0
}

._6n1z._517h,
._6n1z._59pe:focus,
._6n1z._59pe:hover {
  background-color: #fff;
  border-color: transparent
}

._55sg {
  vertical-align: baseline
}

.uiInputLabel ._55sg+.uiInputLabelLabel {
  margin-left: 23px
}

.webkit.mac .uiInputLabel ._55sg+.uiInputLabelLabel {
  margin-left: 22px
}

.ie10 ._55sg>input+span:before,
.webkit ._55sg>input+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1246px;
  content: '';
  display: inline-block;
  height: 16px;
  margin: 0 1px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 16px
}

.webkit ._55sg>input+span:active:before,
.ie10 ._55sg>input:active+span:before,
.webkit ._55sg>input:active+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1263px
}

.ie10 ._55sg>input:focus+span:before,
.webkit ._55sg>input:focus+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1280px
}

.ie10 ._55sg>input:disabled+span:before,
.webkit ._55sg>input:disabled+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1297px
}

.ie10 ._55sg>input:checked+span:before,
.webkit ._55sg>input:checked+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1246px
}

.webkit ._55sg>input:checked+span:active:before,
.ie10 ._55sg>input:active:checked+span:before,
.webkit ._55sg>input:active:checked+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1263px
}

.ie10 ._55sg>input:focus:checked+span:before,
.webkit ._55sg>input:focus:checked+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1280px
}

.ie10 ._55sg>input:disabled:checked+span:before,
.webkit ._55sg>input:disabled:checked+span:before {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1297px
}

.uiContextualLayerAboveLeft ._558b,
.uiContextualLayerAboveCenter ._558b,
.uiContextualLayerAboveRight ._558b {
  margin: 0 0 -1px
}

.uiContextualLayerBelowLeft ._558b,
.uiContextualLayerBelowCenter ._558b,
.uiContextualLayerBelowRight ._558b {
  margin: -1px 0 30px
}

._558b ._54ng {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 3px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3)
}

.uiContextualLayerAboveLeft ._2n_z ._54ng,
.uiContextualLayerAboveRight ._575s._2n_z ._54ng {
  border-bottom-left-radius: 0
}

.uiContextualLayerAboveRight ._2n_z ._54ng,
.uiContextualLayerAboveLeft ._575s._2n_z ._54ng {
  border-bottom-right-radius: 0
}

.uiContextualLayerBelowLeft ._2n_z ._54ng,
.uiContextualLayerBelowRight ._575s._2n_z ._54ng {
  border-top-left-radius: 0
}

.uiContextualLayerBelowRight ._2n_z ._54ng,
.uiContextualLayerBelowLeft ._575s._2n_z ._54ng {
  border-top-right-radius: 0
}

.uiContextualLayer._5v-0 ._558b ._54ng {
  border-radius: 3px
}

._558b ._54nf {
  padding: 5px 0
}

._558b ._54ak {
  border-bottom: 1px solid #e9ebee;
  margin: 5px 7px 6px;
  padding-top: 1px
}

._558b ._54nc {
  border: solid #fff;
  border-width: 1px 0;
  color: #1d2129;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 22px;
  padding: 0 12px
}

._558b ._5arm ._54nc {
  cursor: default;
  opacity: .55
}

._558b ._54ne ._54nc {
  background-color: #4267b2;
  border-color: #29487d;
  color: #fff
}

._558b ._54nd ._54nc {
  background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/kXT5i7nB3hl.png) left 7px no-repeat;
  font-weight: bold
}

._558b ._54nd._54ne ._54nc {
  background-color: #4267b2;
  background-position: left -53px
}

._558b._57di ._54nc {
  padding-left: 22px
}

._558b ._54ah {
  color: #4b4f56
}

._558b ._54nh {
  max-width: 300px
}

._53il ._558b {
  padding-top: 10px
}

._53ik ._558b {
  padding-bottom: 10px
}

._53il ._558b+._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -829px;
  height: 8px;
  top: 2px;
  width: 16px
}

._53ik ._558b+._53io {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -820px;
  bottom: 0;
  height: 10px;
  width: 16px
}

.openToggler>._5vto._5vto {
  background-color: #4267b2;
  border-color: #4267b2;
  box-shadow: none;
  color: #fff
}

.openToggler>._5vto:hover {
  background-color: #365899;
  border-color: #365899
}

.openToggler>._5vto:active {
  background-color: #29487d;
  border-color: #29487d
}

.openToggler>._55pi._nk,
.openToggler>._55pi._nl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.openToggler>._55pi._nn,
.openToggler>._55pi._no {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

._599r {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 10px rgba(0, 0, 0, .35);
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  max-width: 640px;
  min-width: 240px;
  padding: 8px 0
}

._8xdi ._599r {
  font-family: inherit
}

._599s {
  display: none
}

._599m {
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 12px
}

._605a ._1u3_ .img {
  border-radius: 50%
}

._605a ._1u3_ ._39jx .img {
  border-radius: 0
}

._5mne ._599p {
  margin-top: 8px
}

._599p {
  color: #1d2129;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._599q {
  color: #4b4f56;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._4tw1 {
  opacity: .5
}

._599n {
  background-color: #365899;
  border-color: #20375f
}

._1c5r {
  direction: rtl
}

._599n ._599p,
._599n ._599q {
  color: #fff
}

._62w5 {
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px
}

._62w5 ._599m {
  width: 100%
}

._55ym {
  animation: rotateSpinner 1.2s steps(20, end) infinite;
  display: inline-block;
  vertical-align: middle
}

._55yn._55yo {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/n-uOOobFC9i.png);
  height: 12px;
  width: 12px
}

._55yn._55yp {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yn/r/O1LrqXHR9oZ.png);
  height: 12px;
  width: 12px
}

._55yq {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y2/r/onuUJj0tCqE.png);
  height: 24px;
  width: 24px
}

._5tqs {
  animation-play-state: paused;
  display: none
}

._5tqs.async_saving,
.async_saving ._5tqs {
  animation-play-state: running;
  display: inline-block
}

._2y32 {
  animation-play-state: paused
}

._5d9- {
  animation: none;
  background-repeat: no-repeat
}

._5d9-._55yn {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y0/r/tb-rMa-mQQ-.gif);
  background-repeat: no-repeat;
  background-size: 16px 11px;
  background-position: 0 2px;
  height: 16px;
  width: 16px
}

._5d9-._55yq {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yJ/r/kGTCTAmlAi-.gif);
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 0 2px;
  height: 32px;
  width: 32px
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

._2ad7 {
  margin-left: 2px;
  position: relative;
  top: 1px
}

._88lp {
  margin-left: 4px;
  position: relative;
  top: 1px
}

._2ad7._5dzy,
._4rwy,
._6p8n {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1246px;
  border-radius: 50%;
  height: 12px;
  width: 12px
}

._2ad7._5dzy {
  top: -1px
}

._4rwy,
._2zb5,
._7cf0,
._6p8n {
  background-color: #fff
}

._2zb5,
._7cf0 {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1957px;
  border-radius: 50%;
  height: 16px;
  width: 16px
}

._6p8n {
  margin-right: -3px
}

._7cf0,
._6--1 ._4rwy {
  bottom: -1px;
  position: absolute;
  right: -1px
}

._42ef {
  overflow: hidden
}

._51mz {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0
}

._5f0n {
  table-layout: fixed;
  width: 100%
}

.uiGrid .vTop {
  vertical-align: top
}

.uiGrid .vMid {
  vertical-align: middle
}

.uiGrid .vBot {
  vertical-align: bottom
}

.uiGrid .hLeft {
  text-align: left
}

.uiGrid .hCent {
  text-align: center
}

.uiGrid .hRght {
  text-align: right
}

._51mx:first-child>._51m- {
  padding-top: 0
}

._51mx:last-child>._51m- {
  padding-bottom: 0
}

._51mz ._51mw {
  padding-right: 0
}

._51mz ._51m-:first-child {
  padding-left: 0
}

._51mz._4r9u {
  border-radius: 50%;
  overflow: hidden
}

._8o,
._8o .img {
  display: block
}

._8r {
  margin-right: 5px
}

._8s {
  margin-right: 8px
}

._8t {
  margin-right: 10px
}

._6a {
  display: inline-block
}

._6d {
  vertical-align: bottom
}

._6b {
  vertical-align: middle
}

._6e {
  vertical-align: top
}

._5u5j {
  width: 100%
}

._ohe {
  float: left
}

._ohf {
  float: right
}

@keyframes CSSFade_show {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes CSSFade_hide {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

._42ft {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap
}

._42ft:hover {
  text-decoration: none
}

._42ft+._42ft {
  margin-left: 4px
}

._42fr,
._42fs {
  cursor: default
}

._50zz {
  height: 10px;
  width: 10px
}

._50zz._50z- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1235px
}

._50zz._50z-:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -85px -1847px
}

._50zz._50z-:active,
._50zz._42fs {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1789px
}

._50zz._50z_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -85px -1858px
}

._50zz._50z_:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -85px -1869px
}

._50-0 {
  height: 12px;
  width: 12px
}

._50-0._50z- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1272px
}

._50-0._50z-:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1750px
}

._50-0._50z-:active,
._50-0._42fs {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1737px
}

._50-0._50z_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1763px
}

._50-0._50z_:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -84px -1776px
}

._50-1 {
  height: 20px;
  width: 20px
}

._50-1._50z- {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -75px -1658px
}

._50-1._50z-:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -71px -1712px
}

._50-1._50z-:active,
._50-1._42fs {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -71px -1712px
}

._50-1._50z_ {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -75px -1679px
}

._50-1._50z_:hover {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1737px
}

._8k_v._50zy._50-1 {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yh/r/ekiMN2iZ1gH.png);
  background-position: unset;
  background-size: 24px 24px;
  height: 24px;
  width: 24px
}

._50f3 {
  font-size: 12px;
  line-height: 16px
}

._50f4 {
  font-size: 14px;
  line-height: 18px
}

._50f5 {
  font-size: 16px;
  line-height: 20px
}

._50f6 {
  font-size: 18px;
  line-height: 22px
}

._2iei {
  font-size: 40px;
  line-height: 48px
}

._2iej {
  font-size: 24px;
  line-height: 28px
}

._2iek {
  font-size: 20px;
  line-height: 24px
}

._2iel {
  font-size: 16px;
  line-height: 20px
}

._2iem {
  font-size: 14px;
  line-height: 18px
}

._2ien {
  font-size: 14px;
  line-height: 18px
}

._2ieo {
  font-size: 13px;
  line-height: 17px
}

._2iep {
  font-size: 12px;
  line-height: 16px
}

._2ieq {
  font-size: 12px;
  line-height: 16px
}

._50f7 {
  font-weight: 600
}

._5kx5 {
  font-weight: normal
}

._50f8 {
  color: #90949c
}

._c24 {
  color: #4b4f56
}

._50f9 {
  color: #1d2129
}

._2iev {
  color: #1c1e21
}

._2iex {
  color: #606770
}

._2iey {
  color: #bec3c9
}

._rzx {
  color: #385898
}

._rzy {
  color: #8d949e
}

._2ier {
  color: #fff
}

._1hk0 {
  color: #1877f2
}

._2iet {
  color: #00a400
}

._2ieu {
  color: #fa383e
}

._2iez {
  color: #ccc
}

._2ie- {
  color: #4a4a4a
}

._2ie_ {
  color: #373737
}

#facebook ._5s6c._5s6c,
._5s6c {
  font-family: Georgia, serif;
  letter-spacing: normal
}

#facebook ._6mv-._6mv-,
._6mv- {
  font-family: 'Open Dyslexic';
  letter-spacing: normal
}

.CometSettingsPage ._2iep,
.CometSettingsPage ._2ieq,
.CometSettingsPage ._50f4 {
  font-size: 15px;
  line-height: 20px
}

.CometSettingsPage ._50f4 {
  font-weight: 500
}

._47e3,
._4ay8 {
  line-height: 0;
  vertical-align: middle
}

._4ay8 {
  font-style: normal !important;
  font-weight: normal !important
}

._4ay8._3kkw {
  font-size: 16px
}

._4ay8._366d {
  font-size: 18px
}

._4ay8._366e {
  font-size: 20px
}

._4ay8._48cb {
  font-size: 24px
}

._4ay8._5-0n {
  font-size: 28px
}

._4ay8._5-0o {
  font-size: 30px
}

._4ay8._5-0p {
  font-size: 32px
}

._4ay8._2oah {
  font-size: 36px
}

._4ay8._4352 {
  font-size: 56px
}

._4ay8._435o {
  font-size: 112px
}

span._47e3 .img {
  vertical-align: -3px
}

i._47e3 {
  display: inline-block;
  vertical-align: top
}

i._47e3._3kkw {
  background-size: 16px 16px;
  height: 16px;
  width: 16px
}

i._47e3._366d {
  background-size: 18px 18px;
  height: 18px;
  width: 18px
}

i._47e3._366e {
  background-size: 20px 20px;
  height: 20px;
  width: 20px
}

i._47e3._48cb {
  background-size: 24px 24px;
  height: 24px;
  width: 24px
}

._1gwo:before,
span._47e3:before {
  clip: rect(1px, 1px, 1px, 1px);
  content: attr(title), "";
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

._skr,
._7oe {
  display: inline;
  font-size: 0;
  width: 0
}

._5mfr {
  margin: 0 1px
}

._6qdm {
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: inline-block;
  text-decoration: none;
  text-shadow: none;
  vertical-align: text-bottom
}

._6qdm::selection {
  background-color: highlight;
  color: transparent
}

._6qdm._6qdm .pun {
  color: transparent
}

._7c4h {
  display: inline-block;
  position: relative
}

._7c4l {
  display: block;
  left: 0;
  position: absolute;
  top: 0
}

.emoticon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top
}

.emoticon_angel {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -17px
}

.emoticon_colonthree {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -34px
}

.emoticon_confused {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -51px
}

.emoticon_confused_rev {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -68px
}

.emoticon_cry {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -85px
}

.emoticon_devil {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -102px
}

.emoticon_frown {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -119px
}

.emoticon_gasp {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -136px
}

.emoticon_glasses {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -153px
}

.emoticon_grin {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -170px
}

.emoticon_grumpy {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -187px
}

.emoticon_heart {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -204px
}

.emoticon_kiki {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -221px
}

.emoticon_kiss {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -238px
}

.emoticon_like {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 0
}

.emoticon_pacman {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -255px
}

.emoticon_penguin {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -272px
}

.emoticon_poop {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -289px
}

.emoticon_putnam {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -306px
}

.emoticon_robot {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -323px
}

.emoticon_shark {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -340px
}

.emoticon_smile {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -357px
}

.emoticon_squint {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -374px
}

.emoticon_sunglasses {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -391px
}

.emoticon_tongue {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -408px
}

.emoticon_unsure {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -425px
}

.emoticon_upset {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -442px
}

.emoticon_wink {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-repeat: no-repeat;
  background-size: 18px 476px;
  background-position: 0 -459px
}

._4jnw {
  margin: 0
}

._3-8h {
  margin: 4px
}

._3-8i {
  margin: 8px
}

._3-8j {
  margin: 12px
}

._3-8k {
  margin: 16px
}

._3-8l {
  margin: 20px
}

._2-5b {
  margin: 24px
}

._1kbd {
  margin-bottom: 0;
  margin-top: 0
}

._3-8m {
  margin-bottom: 4px;
  margin-top: 4px
}

._3-8n {
  margin-bottom: 8px;
  margin-top: 8px
}

._3-8o {
  margin-bottom: 12px;
  margin-top: 12px
}

._3-8p {
  margin-bottom: 16px;
  margin-top: 16px
}

._3-8q {
  margin-bottom: 20px;
  margin-top: 20px
}

._2-ox {
  margin-bottom: 24px;
  margin-top: 24px
}

._1a4i {
  margin-left: 0;
  margin-right: 0
}

._3-8r {
  margin-left: 4px;
  margin-right: 4px
}

._3-8s {
  margin-left: 8px;
  margin-right: 8px
}

._3-8t {
  margin-left: 12px;
  margin-right: 12px
}

._3-8u {
  margin-left: 16px;
  margin-right: 16px
}

._3-8v {
  margin-left: 20px;
  margin-right: 20px
}

._6bu9 {
  margin-left: 24px;
  margin-right: 24px
}

._5soe {
  margin-top: 0
}

._3-8w {
  margin-top: 4px
}

._3-8x {
  margin-top: 8px
}

._3-8y {
  margin-top: 12px
}

._3-8z {
  margin-top: 16px
}

._3-8- {
  margin-top: 20px
}

._4aws {
  margin-top: 24px
}

._2-jz {
  margin-right: 0
}

._3-8_ {
  margin-right: 4px
}

._3-90 {
  margin-right: 8px
}

._3-91 {
  margin-right: 12px
}

._3-92 {
  margin-right: 16px
}

._3-93 {
  margin-right: 20px
}

._y8t {
  margin-right: 24px
}

._5emk {
  margin-bottom: 0
}

._3-94 {
  margin-bottom: 4px
}

._3-95 {
  margin-bottom: 8px
}

._3-96 {
  margin-bottom: 12px
}

._3-97 {
  margin-bottom: 16px
}

._3-98 {
  margin-bottom: 20px
}

._20nr {
  margin-bottom: 24px
}

._av_ {
  margin-left: 0
}

._3-99 {
  margin-left: 4px
}

._3-9a {
  margin-left: 8px
}

._3-9b {
  margin-left: 12px
}

._3-9c {
  margin-left: 16px
}

._3-9d {
  margin-left: 20px
}

._4m0t {
  margin-left: 24px
}

._8tm {
  padding: 0
}

._2phz {
  padding: 4px
}

._2ph- {
  padding: 8px
}

._2ph_ {
  padding: 12px
}

._2pi0 {
  padding: 16px
}

._2pi1 {
  padding: 20px
}

._40c7 {
  padding: 24px
}

._2o1j {
  padding: 36px
}

._6buq {
  padding-bottom: 0;
  padding-top: 0
}

._2pi2 {
  padding-bottom: 4px;
  padding-top: 4px
}

._2pi3 {
  padding-bottom: 8px;
  padding-top: 8px
}

._2pi4 {
  padding-bottom: 12px;
  padding-top: 12px
}

._2pi5 {
  padding-bottom: 16px;
  padding-top: 16px
}

._2pi6 {
  padding-bottom: 20px;
  padding-top: 20px
}

._2o1k {
  padding-bottom: 24px;
  padding-top: 24px
}

._2o1l {
  padding-bottom: 36px;
  padding-top: 36px
}

._6bua {
  padding-left: 0;
  padding-right: 0
}

._2pi7 {
  padding-left: 4px;
  padding-right: 4px
}

._2pi8 {
  padding-left: 8px;
  padding-right: 8px
}

._2pi9 {
  padding-left: 12px;
  padding-right: 12px
}

._2pia {
  padding-left: 16px;
  padding-right: 16px
}

._2pib {
  padding-left: 20px;
  padding-right: 20px
}

._2o1m {
  padding-left: 24px;
  padding-right: 24px
}

._2o1n {
  padding-left: 36px;
  padding-right: 36px
}

._iky {
  padding-top: 0
}

._2pic {
  padding-top: 4px
}

._2pid {
  padding-top: 8px
}

._2pie {
  padding-top: 12px
}

._2pif {
  padding-top: 16px
}

._2pig {
  padding-top: 20px
}

._2owm {
  padding-top: 24px
}

._div {
  padding-right: 0
}

._2pih {
  padding-right: 4px
}

._2pii {
  padding-right: 8px
}

._2pij {
  padding-right: 12px
}

._2pik {
  padding-right: 16px
}

._2pil {
  padding-right: 20px
}

._31wk {
  padding-right: 24px
}

._2phb {
  padding-right: 32px
}

._au- {
  padding-bottom: 0
}

._2pim {
  padding-bottom: 4px
}

._2pin {
  padding-bottom: 8px
}

._2pio {
  padding-bottom: 12px
}

._2pip {
  padding-bottom: 16px
}

._2piq {
  padding-bottom: 20px
}

._2o1p {
  padding-bottom: 24px
}

._4gao {
  padding-bottom: 32px
}

._1cvx {
  padding-left: 0
}

._2pir {
  padding-left: 4px
}

._2pis {
  padding-left: 8px
}

._2pit {
  padding-left: 12px
}

._2piu {
  padding-left: 16px
}

._2piv {
  padding-left: 20px
}

._2o1q {
  padding-left: 24px
}

._2o1r {
  padding-left: 36px
}

.sp_Xi-fxomIVGq_2x {
  /* background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/Y_2rPZLlNeZ.png); */
  background-image: url(./Y_2rPZLlNeZ.png);
  background-size: 97px 2110px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px
}

.sp_Xi-fxomIVGq_2x.sx_88d740 {
  width: 12px;
  height: 12px;
  background-position: -81px -1014px
}

.sp_Xi-fxomIVGq_2x.sx_9509d3 {
  background-position: -79px -1609px
}

.sp_Xi-fxomIVGq_2x.sx_e39a66 {
  background-position: -21px -1800px
}

.sp_Xi-fxomIVGq_2x.sx_5b2cb7 {
  background-position: -38px -1800px
}

.sp_Xi-fxomIVGq_2x.sx_806992 {
  background-position: -55px -1800px
}

.sp_Xi-fxomIVGq_2x.sx_854acf {
  width: 12px;
  height: 12px;
  background-position: -81px -1027px
}

.sp_Xi-fxomIVGq_2x.sx_e8ce5a {
  width: 12px;
  height: 12px;
  background-position: -81px -1040px
}

.sp_Xi-fxomIVGq_2x.sx_e6a555 {
  background-position: -72px -1800px
}

.sp_Xi-fxomIVGq_2x.sx_e2a4e6 {
  width: 20px;
  height: 20px;
  background-position: -75px -1315px
}

.sp_Xi-fxomIVGq_2x.sx_3888a1 {
  width: 24px;
  height: 24px;
  background-position: -54px -1609px
}

.sp_Xi-fxomIVGq_2x.sx_2c0140 {
  background-position: 0 -1821px
}

.sp_Xi-fxomIVGq_2x.sx_0c0ad0 {
  background-position: -17px -1821px
}

.sp_Xi-fxomIVGq_2x.sx_e0b6dd {
  background-position: -34px -1821px
}

.sp_Xi-fxomIVGq_2x.sx_7b8744 {
  background-position: -51px -1821px
}

.sp_Xi-fxomIVGq_2x.sx_ecaf15 {
  width: 20px;
  height: 20px;
  background-position: -75px -1336px
}

.sp_Xi-fxomIVGq_2x.sx_402820 {
  width: 12px;
  height: 12px;
  background-position: -81px -1053px
}

.sp_Xi-fxomIVGq_2x.sx_0d8130 {
  width: 12px;
  height: 12px;
  background-position: -81px -1066px
}

.sp_Xi-fxomIVGq_2x.sx_f52405 {
  width: 20px;
  height: 20px;
  background-position: -75px -1637px
}

.sp_Xi-fxomIVGq_2x.sx_7d2f42 {
  background-position: -68px -1821px
}

.sp_Xi-fxomIVGq_2x.sx_fa718f {
  background-position: 0 -1838px
}

.sp_Xi-fxomIVGq_2x.sx_755a18 {
  background-position: -17px -1838px
}

.sp_Xi-fxomIVGq_2x.sx_fb7717 {
  background-position: -34px -1838px
}

.sp_Xi-fxomIVGq_2x.sx_55770b {
  background-position: -51px -1838px
}

.sp_Xi-fxomIVGq_2x.sx_12829c {
  background-position: -68px -1838px
}

.sp_Xi-fxomIVGq_2x.sx_642619 {
  background-position: 0 -1855px
}

.sp_Xi-fxomIVGq_2x.sx_bd4bc1 {
  background-position: -17px -1855px
}

.sp_Xi-fxomIVGq_2x.sx_0c12be {
  background-position: -34px -1855px
}

.sp_Xi-fxomIVGq_2x.sx_915ccd {
  width: 20px;
  height: 20px;
  background-position: -75px -1658px
}

.sp_Xi-fxomIVGq_2x.sx_95256c {
  width: 20px;
  height: 20px;
  background-position: -75px -1679px
}

.sp_Xi-fxomIVGq_2x.sx_1836f5 {
  width: 20px;
  height: 20px;
  background-position: -71px -1712px
}

.sp_Xi-fxomIVGq_2x.sx_d91e1d {
  width: 20px;
  height: 20px;
  background-position: 0 -1737px
}

.sp_Xi-fxomIVGq_2x.sx_27d78d {
  background-position: -51px -1855px
}

.sp_Xi-fxomIVGq_2x.sx_48388f {
  background-position: -68px -1855px
}

.sp_Xi-fxomIVGq_2x.sx_5a1c07 {
  background-position: 0 -1872px
}

.sp_Xi-fxomIVGq_2x.sx_795697 {
  background-position: -17px -1872px
}

.sp_Xi-fxomIVGq_2x.sx_534291 {
  background-position: -34px -1872px
}

.sp_Xi-fxomIVGq_2x.sx_450114 {
  background-position: -51px -1872px
}

.sp_Xi-fxomIVGq_2x.sx_c25d84 {
  width: 12px;
  height: 12px;
  background-position: -81px -1079px
}

.sp_Xi-fxomIVGq_2x.sx_c0503b {
  width: 12px;
  height: 12px;
  background-position: -81px -1092px
}

.sp_Xi-fxomIVGq_2x.sx_251eb0 {
  width: 12px;
  height: 12px;
  background-position: -81px -1105px
}

.sp_Xi-fxomIVGq_2x.sx_453ab1 {
  background-position: -68px -1872px
}

.sp_Xi-fxomIVGq_2x.sx_bf7cd0 {
  width: 20px;
  height: 20px;
  background-position: -21px -1737px
}

.sp_Xi-fxomIVGq_2x.sx_b81baf {
  background-position: 0 -1889px
}

.sp_Xi-fxomIVGq_2x.sx_3c6cdc {
  background-position: -17px -1889px
}

.sp_Xi-fxomIVGq_2x.sx_7e8167 {
  background-position: -34px -1889px
}

.sp_Xi-fxomIVGq_2x.sx_be32d3 {
  background-position: -51px -1889px
}

.sp_Xi-fxomIVGq_2x.sx_211a19 {
  background-position: -68px -1889px
}

.sp_Xi-fxomIVGq_2x.sx_b64bff {
  width: 12px;
  height: 12px;
  background-position: -81px -1118px
}

.sp_Xi-fxomIVGq_2x.sx_b739d6 {
  width: 12px;
  height: 12px;
  background-position: -81px -1131px
}

.sp_Xi-fxomIVGq_2x.sx_35d630 {
  background-position: 0 -1906px
}

.sp_Xi-fxomIVGq_2x.sx_5d5075 {
  background-position: -17px -1906px
}

.sp_Xi-fxomIVGq_2x.sx_ed262d {
  background-position: -34px -1906px
}

.sp_Xi-fxomIVGq_2x.sx_8c7015 {
  width: 12px;
  height: 12px;
  background-position: -81px -1144px
}

.sp_Xi-fxomIVGq_2x.sx_d81629 {
  width: 12px;
  height: 12px;
  background-position: -81px -1157px
}

.sp_Xi-fxomIVGq_2x.sx_2b456e {
  width: 12px;
  height: 12px;
  background-position: -81px -1170px
}

.sp_Xi-fxomIVGq_2x.sx_670237 {
  width: 12px;
  height: 12px;
  background-position: -81px -1183px
}

.sp_Xi-fxomIVGq_2x.sx_9b6196 {
  width: 20px;
  height: 20px;
  background-position: -42px -1737px
}

.sp_Xi-fxomIVGq_2x.sx_f2acab {
  background-position: -51px -1906px
}

.sp_Xi-fxomIVGq_2x.sx_4823c3 {
  background-position: -68px -1906px
}

.sp_Xi-fxomIVGq_2x.sx_f75582 {
  background-position: 0 -1923px
}

.sp_Xi-fxomIVGq_2x.sx_a0369c {
  background-position: -17px -1923px
}

.sp_Xi-fxomIVGq_2x.sx_288d73 {
  background-position: -34px -1923px
}

.sp_Xi-fxomIVGq_2x.sx_b51603 {
  background-position: -51px -1923px
}

.sp_Xi-fxomIVGq_2x.sx_ea9e62 {
  background-position: -68px -1923px
}

.sp_Xi-fxomIVGq_2x.sx_d4c025 {
  background-position: 0 -1940px
}

.sp_Xi-fxomIVGq_2x.sx_4154c8 {
  background-position: -17px -1940px
}

.sp_Xi-fxomIVGq_2x.sx_4a534b {
  width: 24px;
  height: 24px;
  background-position: 0 -1637px
}

.sp_Xi-fxomIVGq_2x.sx_de2558 {
  background-position: -34px -1940px
}

.sp_Xi-fxomIVGq_2x.sx_c9af47 {
  background-position: -51px -1940px
}

.sp_Xi-fxomIVGq_2x.sx_f0b80e {
  width: 24px;
  height: 24px;
  background-position: -25px -1637px
}

.sp_Xi-fxomIVGq_2x.sx_461240 {
  width: 12px;
  height: 12px;
  background-position: -81px -1196px
}

.sp_Xi-fxomIVGq_2x.sx_4bc865 {
  background-position: -68px -1940px
}

.sp_Xi-fxomIVGq_2x.sx_cab7c2 {
  width: 20px;
  height: 20px;
  background-position: -63px -1737px
}

.sp_Xi-fxomIVGq_2x.sx_0d3b6e {
  width: 24px;
  height: 24px;
  background-position: -50px -1637px
}

.sp_Xi-fxomIVGq_2x.sx_13dccb {
  width: 12px;
  height: 12px;
  background-position: -81px -1209px
}

.sp_Xi-fxomIVGq_2x.sx_18b6c4 {
  width: 20px;
  height: 20px;
  background-position: 0 -1758px
}

.sp_Xi-fxomIVGq_2x.sx_51488f {
  width: 12px;
  height: 12px;
  background-position: -81px -1222px
}

.sp_Xi-fxomIVGq_2x.sx_ff4a7b {
  width: 12px;
  height: 12px;
  background-position: -84px -1246px
}

.sp_Xi-fxomIVGq_2x.sx_fa73c1 {
  background-position: 0 -1957px
}

.sp_Xi-fxomIVGq_2x.sx_91ed0c {
  width: 12px;
  height: 12px;
  background-position: -84px -1259px
}

.sp_Xi-fxomIVGq_2x.sx_e87700 {
  width: 14px;
  height: 14px;
  background-position: -55px -1323px
}

.sp_Xi-fxomIVGq_2x.sx_edcb9e {
  width: 14px;
  height: 14px;
  background-position: -55px -1353px
}

.sp_Xi-fxomIVGq_2x.sx_5e7edd {
  background-position: -17px -1957px
}

.sp_Xi-fxomIVGq_2x.sx_adce6a {
  background-position: -34px -1957px
}

.sp_Xi-fxomIVGq_2x.sx_fa8836 {
  width: 15px;
  height: 24px;
  background-position: -81px -679px
}

.sp_Xi-fxomIVGq_2x.sx_6c9ab7 {
  height: 9px;
  background-position: -78px -1565px
}

.sp_Xi-fxomIVGq_2x.sx_bb32d1 {
  height: 9px;
  background-position: -77px -1596px
}

.sp_Xi-fxomIVGq_2x.sx_36dcc5 {
  width: 9px;
  background-position: -81px -704px
}

.sp_Xi-fxomIVGq_2x.sx_0af01e {
  width: 9px;
  background-position: -81px -721px
}

.sp_Xi-fxomIVGq_2x.sx_08962f {
  height: 9px;
  background-position: -75px -1700px
}

.sp_Xi-fxomIVGq_2x.sx_8bcd95 {
  background-position: -51px -1957px
}

.sp_Xi-fxomIVGq_2x.sx_7aa18d {
  background-position: -68px -1957px
}

.sp_Xi-fxomIVGq_2x.sx_65cfc5 {
  background-position: 0 -1974px
}

.sp_Xi-fxomIVGq_2x.sx_dc3504 {
  background-position: -17px -1974px
}

.sp_Xi-fxomIVGq_2x.sx_403ca0 {
  background-position: -34px -1974px
}

.sp_Xi-fxomIVGq_2x.sx_9a1c26 {
  background-position: -51px -1974px
}

.sp_Xi-fxomIVGq_2x.sx_d6aae6 {
  background-position: -68px -1974px
}

.sp_Xi-fxomIVGq_2x.sx_7a5695 {
  background-position: 0 -1991px
}

.sp_Xi-fxomIVGq_2x.sx_940f9e {
  background-position: -17px -1991px
}

.sp_Xi-fxomIVGq_2x.sx_d2b8a5 {
  background-position: -34px -1991px
}

.sp_Xi-fxomIVGq_2x.sx_f1207d {
  background-position: -51px -1991px
}

.sp_Xi-fxomIVGq_2x.sx_6088ee {
  background-position: -68px -1991px
}

.sp_Xi-fxomIVGq_2x.sx_45bf04 {
  background-position: 0 -2008px
}

.sp_Xi-fxomIVGq_2x.sx_e86f92 {
  background-position: -17px -2008px
}

.sp_Xi-fxomIVGq_2x.sx_d6257e {
  background-position: -34px -2008px
}

.sp_Xi-fxomIVGq_2x.sx_4eb470 {
  background-position: -51px -2008px
}

.sp_Xi-fxomIVGq_2x.sx_a2e1b4 {
  background-position: -68px -2008px
}

.sp_Xi-fxomIVGq_2x.sx_3c1db9 {
  background-position: 0 -2025px
}

.sp_Xi-fxomIVGq_2x.sx_efe374 {
  background-position: -17px -2025px
}

.sp_Xi-fxomIVGq_2x.sx_0e66f7 {
  background-position: -34px -2025px
}

.sp_Xi-fxomIVGq_2x.sx_35424b {
  background-position: -51px -2025px
}

.sp_Xi-fxomIVGq_2x.sx_1e882b {
  background-position: -68px -2025px
}

.sp_Xi-fxomIVGq_2x.sx_63918b {
  height: 8px;
  background-position: -75px -1366px
}

.sp_Xi-fxomIVGq_2x.sx_62d12a {
  height: 8px;
  background-position: -34px -2093px
}

.sp_Xi-fxomIVGq_2x.sx_cfd0fb {
  height: 8px;
  background-position: -51px -2093px
}

.sp_Xi-fxomIVGq_2x.sx_46f466 {
  width: 8px;
  background-position: -81px -738px
}

.sp_Xi-fxomIVGq_2x.sx_d25b01 {
  width: 8px;
  background-position: -81px -755px
}

.sp_Xi-fxomIVGq_2x.sx_2479fe {
  height: 8px;
  background-position: -68px -2093px
}

.sp_Xi-fxomIVGq_2x.sx_ea312e {
  width: 14px;
  height: 14px;
  background-position: -81px -838px
}

.sp_Xi-fxomIVGq_2x.sx_a59f6e {
  width: 11px;
  height: 12px;
  background-position: -85px -1834px
}

.sp_Xi-fxomIVGq_2x.sx_a23bbc {
  width: 11px;
  height: 12px;
  background-position: -85px -1821px
}

.sp_Xi-fxomIVGq_2x.sx_86cb6e {
  background-position: 0 -2042px
}

.sp_Xi-fxomIVGq_2x.sx_3c7f44 {
  width: 13px;
  height: 13px;
  background-position: -81px -972px
}

.sp_Xi-fxomIVGq_2x.sx_3358ec {
  width: 13px;
  height: 13px;
  background-position: -81px -958px
}

.sp_Xi-fxomIVGq_2x.sx_fd798f {
  width: 13px;
  height: 13px;
  background-position: -81px -986px
}

.sp_Xi-fxomIVGq_2x.sx_99c1ea {
  width: 20px;
  height: 20px;
  background-position: -21px -1758px
}

.sp_Xi-fxomIVGq_2x.sx_4f0204 {
  width: 17px;
  height: 17px;
  background-position: -77px -1578px
}

.sp_Xi-fxomIVGq_2x.sx_64ff46 {
  width: 25px;
  height: 25px;
  background-position: -28px -1609px
}

.sp_Xi-fxomIVGq_2x.sx_81dc26 {
  width: 14px;
  height: 14px;
  background-position: -35px -2043px
}

.sp_Xi-fxomIVGq_2x.sx_8ff4c3 {
  width: 14px;
  height: 14px;
  background-position: -18px -2043px
}

.sp_Xi-fxomIVGq_2x.sx_8f3011 {
  width: 18px;
  height: 18px;
  background-position: -75px -1295px
}

.sp_Xi-fxomIVGq_2x.sx_f14e17 {
  background-position: -51px -2042px
}

.sp_Xi-fxomIVGq_2x.sx_cdfbd3 {
  background-position: -79px -1376px
}

.sp_Xi-fxomIVGq_2x.sx_b61d46 {
  width: 13px;
  height: 13px;
  background-position: -82px -773px
}

.sp_Xi-fxomIVGq_2x.sx_300f7c {
  width: 13px;
  height: 13px;
  background-position: -82px -789px
}

.sp_Xi-fxomIVGq_2x.sx_ffe368 {
  width: 14px;
  height: 14px;
  background-position: -69px -2043px
}

.sp_Xi-fxomIVGq_2x.sx_665e30 {
  width: 20px;
  height: 20px;
  background-position: -26px -1713px
}

.sp_Xi-fxomIVGq_2x.sx_ae6eff {
  width: 17px;
  height: 17px;
  background-position: -36px -1296px
}

.sp_Xi-fxomIVGq_2x.sx_4df04c {
  width: 25px;
  height: 25px;
  background-position: -50px -1579px
}

.sp_Xi-fxomIVGq_2x.sx_fb88c4 {
  background-position: 0 -2059px
}

.sp_Xi-fxomIVGq_2x.sx_2cd1ed {
  width: 18px;
  height: 18px;
  background-position: -78px -1394px
}

.sp_Xi-fxomIVGq_2x.sx_450e01 {
  background-position: -79px -1414px
}

.sp_Xi-fxomIVGq_2x.sx_818da6 {
  width: 13px;
  height: 13px;
  background-position: -82px -805px
}

.sp_Xi-fxomIVGq_2x.sx_54c5e5 {
  width: 20px;
  height: 20px;
  background-position: -49px -1713px
}

.sp_Xi-fxomIVGq_2x.sx_4a975a {
  width: 17px;
  height: 17px;
  background-position: -56px -1296px
}

.sp_Xi-fxomIVGq_2x.sx_080c59 {
  width: 25px;
  height: 25px;
  background-position: -1px -1610px
}

.sp_Xi-fxomIVGq_2x.sx_0119c4 {
  width: 14px;
  height: 14px;
  background-position: -35px -2060px
}

.sp_Xi-fxomIVGq_2x.sx_607758 {
  width: 14px;
  height: 14px;
  background-position: -18px -2060px
}

.sp_Xi-fxomIVGq_2x.sx_290567 {
  width: 13px;
  height: 13px;
  background-position: -81px -1000px
}

.sp_Xi-fxomIVGq_2x.sx_9a01c9 {
  background-position: -51px -2059px
}

.sp_Xi-fxomIVGq_2x.sx_2e868d {
  background-position: -68px -2059px
}

.sp_Xi-fxomIVGq_2x.sx_c97c50 {
  width: 14px;
  height: 14px;
  background-position: -81px -853px
}

.sp_Xi-fxomIVGq_2x.sx_5f252f {
  width: 14px;
  height: 14px;
  background-position: -81px -868px
}

.sp_Xi-fxomIVGq_2x.sx_abe80a {
  width: 14px;
  height: 14px;
  background-position: -81px -883px
}

.sp_Xi-fxomIVGq_2x.sx_9519ba {
  width: 14px;
  height: 14px;
  background-position: -81px -898px
}

.sp_Xi-fxomIVGq_2x.sx_57ce50 {
  width: 14px;
  height: 14px;
  background-position: -81px -913px
}

.sp_Xi-fxomIVGq_2x.sx_5c663d {
  width: 14px;
  height: 14px;
  background-position: -81px -928px
}

.sp_Xi-fxomIVGq_2x.sx_5c57eb {
  width: 14px;
  height: 14px;
  background-position: -81px -943px
}

.sp_Xi-fxomIVGq_2x.sx_cb1b2e {
  background-position: 0 -2076px
}

.sp_Xi-fxomIVGq_2x.sx_b9b056 {
  background-position: -17px -2076px
}

.sp_Xi-fxomIVGq_2x.sx_db80d6 {
  background-position: -34px -2076px
}

.sp_Xi-fxomIVGq_2x.sx_e3e9b8 {
  background-position: -51px -2076px
}

.sp_Xi-fxomIVGq_2x.sx_52b3e6 {
  background-position: -68px -2076px
}

.sp_Xi-fxomIVGq_2x.sx_8c3550 {
  background-position: 0 -2093px
}

.sp_Xi-fxomIVGq_2x.sx_fd63b7 {
  background-position: -17px -2093px
}

.sp_Xi-fxomIVGq_2x.sx_cb6bbc {
  width: 18px;
  height: 18px;
  background-position: -78px -1432px
}

.sp_Xi-fxomIVGq_2x.sx_f98735 {
  width: 18px;
  height: 18px;
  background-position: -78px -1451px
}

.sp_Xi-fxomIVGq_2x.sx_ec14d2 {
  width: 18px;
  height: 18px;
  background-position: -78px -1470px
}

.sp_Xi-fxomIVGq_2x.sx_3d66c6 {
  width: 18px;
  height: 18px;
  background-position: -78px -1489px
}

.sp_Xi-fxomIVGq_2x.sx_411381 {
  width: 18px;
  height: 18px;
  background-position: -78px -1508px
}

.sp_Xi-fxomIVGq_2x.sx_e37af1 {
  width: 18px;
  height: 18px;
  background-position: -78px -1527px
}

.sp_Xi-fxomIVGq_2x.sx_114ddc {
  width: 18px;
  height: 18px;
  background-position: -78px -1546px
}

.sp_Xi-fxomIVGq_2x.sx_2c6b64 {
  width: 20px;
  height: 20px;
  background-position: -42px -1758px
}

.sp_Xi-fxomIVGq_2x.sx_dd7358 {
  width: 20px;
  height: 20px;
  background-position: -63px -1758px
}

.sp_Xi-fxomIVGq_2x.sx_c07e0f {
  width: 20px;
  height: 20px;
  background-position: 0 -1779px
}

.sp_Xi-fxomIVGq_2x.sx_24c871 {
  width: 20px;
  height: 20px;
  background-position: -21px -1779px
}

.sp_Xi-fxomIVGq_2x.sx_547974 {
  width: 20px;
  height: 20px;
  background-position: -42px -1779px
}

.sp_Xi-fxomIVGq_2x.sx_d725c5 {
  width: 20px;
  height: 20px;
  background-position: -63px -1779px
}

.sp_Xi-fxomIVGq_2x.sx_e4d243 {
  width: 20px;
  height: 20px;
  background-position: 0 -1800px
}

.sp_Xi-fxomIVGq_2x.sx_5d1efb {
  width: 24px;
  height: 24px;
  background-position: 0 -1662px
}

.sp_Xi-fxomIVGq_2x.sx_d7ff77 {
  width: 24px;
  height: 24px;
  background-position: -25px -1662px
}

.sp_Xi-fxomIVGq_2x.sx_eb4a8f {
  width: 24px;
  height: 24px;
  background-position: -50px -1662px
}

.sp_Xi-fxomIVGq_2x.sx_20d31c {
  width: 24px;
  height: 24px;
  background-position: 0 -1687px
}

.sp_Xi-fxomIVGq_2x.sx_3f992a {
  width: 24px;
  height: 24px;
  background-position: -25px -1687px
}

.sp_Xi-fxomIVGq_2x.sx_d2ba57 {
  width: 24px;
  height: 24px;
  background-position: -50px -1687px
}

.sp_Xi-fxomIVGq_2x.sx_9e1435 {
  width: 24px;
  height: 24px;
  background-position: 0 -1712px
}

.sp_Xi-fxomIVGq_2x.sx_289e9a {
  width: 28px;
  height: 28px;
  background-position: -49px -1375px
}

.sp_Xi-fxomIVGq_2x.sx_4efdbf {
  width: 28px;
  height: 28px;
  background-position: -49px -1404px
}

.sp_Xi-fxomIVGq_2x.sx_f73a4b {
  width: 28px;
  height: 28px;
  background-position: -49px -1433px
}

.sp_Xi-fxomIVGq_2x.sx_a7be4e {
  width: 28px;
  height: 28px;
  background-position: -49px -1462px
}

.sp_Xi-fxomIVGq_2x.sx_9feb28 {
  width: 28px;
  height: 28px;
  background-position: -49px -1491px
}

.sp_Xi-fxomIVGq_2x.sx_70fb86 {
  width: 28px;
  height: 28px;
  background-position: -49px -1520px
}

.sp_Xi-fxomIVGq_2x.sx_13f52b {
  width: 28px;
  height: 28px;
  background-position: -49px -1549px
}

.sp_Xi-fxomIVGq_2x.sx_f28c95 {
  width: 48px;
  height: 48px;
  background-position: -35px -1246px
}

.sp_Xi-fxomIVGq_2x.sx_75b116 {
  width: 48px;
  height: 48px;
  background-position: 0 -1315px
}

.sp_Xi-fxomIVGq_2x.sx_67ae2a {
  width: 48px;
  height: 48px;
  background-position: 0 -1364px
}

.sp_Xi-fxomIVGq_2x.sx_62ca03 {
  width: 48px;
  height: 48px;
  background-position: 0 -1413px
}

.sp_Xi-fxomIVGq_2x.sx_bd29a6 {
  width: 48px;
  height: 48px;
  background-position: 0 -1462px
}

.sp_Xi-fxomIVGq_2x.sx_6ffb31 {
  width: 48px;
  height: 48px;
  background-position: 0 -1511px
}

.sp_Xi-fxomIVGq_2x.sx_42b63c {
  width: 48px;
  height: 48px;
  background-position: 0 -1560px
}

.sp_Xi-fxomIVGq_2x.sx_9f3831 {
  width: 80px;
  height: 80px;
  background-position: 0 -679px
}

.sp_Xi-fxomIVGq_2x.sx_2cafa9 {
  width: 80px;
  height: 80px;
  background-position: 0 -760px
}

.sp_Xi-fxomIVGq_2x.sx_439034 {
  width: 80px;
  height: 80px;
  background-position: 0 -841px
}

.sp_Xi-fxomIVGq_2x.sx_b2581b {
  width: 80px;
  height: 80px;
  background-position: 0 -922px
}

.sp_Xi-fxomIVGq_2x.sx_42541f {
  width: 80px;
  height: 80px;
  background-position: 0 -1003px
}

.sp_Xi-fxomIVGq_2x.sx_8fdcf1 {
  width: 80px;
  height: 80px;
  background-position: 0 -1084px
}

.sp_Xi-fxomIVGq_2x.sx_2f2d25 {
  width: 80px;
  height: 80px;
  background-position: 0 -1165px
}

.sp_Xi-fxomIVGq_2x.sx_c589a4 {
  width: 96px;
  height: 96px;
  background-position: 0 0
}

.sp_Xi-fxomIVGq_2x.sx_73d08a {
  width: 96px;
  height: 96px;
  background-position: 0 -97px
}

.sp_Xi-fxomIVGq_2x.sx_c65da2 {
  width: 96px;
  height: 96px;
  background-position: 0 -194px
}

.sp_Xi-fxomIVGq_2x.sx_a9650d {
  width: 96px;
  height: 96px;
  background-position: 0 -291px
}

.sp_Xi-fxomIVGq_2x.sx_72c9bd {
  width: 96px;
  height: 96px;
  background-position: 0 -388px
}

.sp_Xi-fxomIVGq_2x.sx_417a79 {
  width: 96px;
  height: 96px;
  background-position: 0 -485px
}

.sp_Xi-fxomIVGq_2x.sx_1c675c {
  width: 96px;
  height: 96px;
  background-position: 0 -582px
}

.sp_Xi-fxomIVGq_2x.sx_fba509 {
  width: 12px;
  height: 12px;
  background-position: -84px -1272px
}

.sp_Xi-fxomIVGq_2x.sx_f5bb6b {
  width: 12px;
  height: 12px;
  background-position: -84px -1737px
}

.sp_Xi-fxomIVGq_2x.sx_038df6 {
  width: 12px;
  height: 12px;
  background-position: -84px -1750px
}

.sp_Xi-fxomIVGq_2x.sx_4aea0e {
  width: 12px;
  height: 12px;
  background-position: -84px -1763px
}

.sp_Xi-fxomIVGq_2x.sx_b60dc9 {
  width: 12px;
  height: 12px;
  background-position: -84px -1776px
}

.sp_Xi-fxomIVGq_2x.sx_69a377 {
  width: 10px;
  height: 10px;
  background-position: -81px -1235px
}

.sp_Xi-fxomIVGq_2x.sx_a65f0d {
  width: 10px;
  height: 10px;
  background-position: -84px -1789px
}

.sp_Xi-fxomIVGq_2x.sx_1ed153 {
  width: 10px;
  height: 10px;
  background-position: -85px -1847px
}

.sp_Xi-fxomIVGq_2x.sx_9188ad {
  width: 10px;
  height: 10px;
  background-position: -85px -1858px
}

.sp_Xi-fxomIVGq_2x.sx_9cc003 {
  width: 10px;
  height: 10px;
  background-position: -85px -1869px
}

.sp_Xi-fxomIVGq_2x.sx_a3dc06 {
  width: 9px;
  height: 8px;
  background-position: -84px -1357px
}

.selected .sp_Xi-fxomIVGq_2x.sx_a3dc06 {
  background-position: -75px -1357px
}

.sp_Xi-fxomIVGq_2x.sx_4b9e3e {
  width: 9px;
  height: 8px;
  background-position: -84px -1285px
}

.sp_Xi-fxomIVGq_2x.sx_170558 {
  width: 34px;
  height: 68px;
  background-position: 0 -1246px
}

.sp_Xi-fxomIVGq_2x.sx_5e9925 {
  width: 15px;
  height: 8px;
  background-position: -81px -820px
}

.sp_Xi-fxomIVGq_2x.sx_b90d64 {
  width: 15px;
  height: 8px;
  background-position: -81px -829px
}

.sp_Xi-fxomIVGq_2x.sx_1d1c0b {
  width: 6px;
  height: 11px;
  background-position: -90px -738px
}

.sp_fM-mz8spZ1b_2x {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/2gvS2H_aq_u.png);
  background-size: 18px 476px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px
}

.sp_fM-mz8spZ1b_2x.sx_a6f37b {
  background-position: 0 -17px
}

.sp_fM-mz8spZ1b_2x.sx_206e2c {
  background-position: 0 -34px
}

.sp_fM-mz8spZ1b_2x.sx_3bfbfe {
  background-position: 0 -51px
}

.sp_fM-mz8spZ1b_2x.sx_9499bc {
  background-position: 0 -68px
}

.sp_fM-mz8spZ1b_2x.sx_892bd8 {
  background-position: 0 -85px
}

.sp_fM-mz8spZ1b_2x.sx_321af4 {
  background-position: 0 -102px
}

.sp_fM-mz8spZ1b_2x.sx_f3cdd7 {
  background-position: 0 -119px
}

.sp_fM-mz8spZ1b_2x.sx_f76499 {
  background-position: 0 -136px
}

.sp_fM-mz8spZ1b_2x.sx_8b3722 {
  background-position: 0 -153px
}

.sp_fM-mz8spZ1b_2x.sx_662822 {
  background-position: 0 -170px
}

.sp_fM-mz8spZ1b_2x.sx_853b92 {
  background-position: 0 -187px
}

.sp_fM-mz8spZ1b_2x.sx_bf4186 {
  background-position: 0 -204px
}

.sp_fM-mz8spZ1b_2x.sx_470b8d {
  background-position: 0 -221px
}

.sp_fM-mz8spZ1b_2x.sx_651e81 {
  background-position: 0 -238px
}

.sp_fM-mz8spZ1b_2x.sx_5295b7 {
  background-position: 0 0
}

.sp_fM-mz8spZ1b_2x.sx_570c8f {
  background-position: 0 -255px
}

.sp_fM-mz8spZ1b_2x.sx_55f846 {
  background-position: 0 -272px
}

.sp_fM-mz8spZ1b_2x.sx_663c4e {
  background-position: 0 -289px
}

.sp_fM-mz8spZ1b_2x.sx_5ca361 {
  background-position: 0 -306px
}

.sp_fM-mz8spZ1b_2x.sx_1ef1b4 {
  background-position: 0 -323px
}

.sp_fM-mz8spZ1b_2x.sx_665b37 {
  background-position: 0 -340px
}

.sp_fM-mz8spZ1b_2x.sx_98af2b {
  background-position: 0 -357px
}

.sp_fM-mz8spZ1b_2x.sx_88037d {
  background-position: 0 -374px
}

.sp_fM-mz8spZ1b_2x.sx_d893ea {
  background-position: 0 -391px
}

.sp_fM-mz8spZ1b_2x.sx_c5eb31 {
  background-position: 0 -408px
}

.sp_fM-mz8spZ1b_2x.sx_a534bb {
  background-position: 0 -425px
}

.sp_fM-mz8spZ1b_2x.sx_a1d8cd {
  background-position: 0 -442px
}

.sp_fM-mz8spZ1b_2x.sx_930a32 {
  background-position: 0 -459px
}

.sp_jQW-CGvXQNz_2x {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/3jK8lOh14wq.png);
  background-size: 14px 84px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 13px;
  width: 13px
}

.sp_jQW-CGvXQNz_2x.sx_362127 {
  background-position: 0 0
}

.sp_jQW-CGvXQNz_2x.sx_8b19c1 {
  background-position: 0 -14px
}

.sp_jQW-CGvXQNz_2x.sx_d1a83c {
  background-position: 0 -28px
}

.sp_jQW-CGvXQNz_2x.sx_70618f {
  background-position: 0 -42px
}

.sp_jQW-CGvXQNz_2x.sx_0702bb {
  background-position: 0 -56px
}

.sp_jQW-CGvXQNz_2x.sx_f07d99 {
  background-position: 0 -70px
}

#bootloader_1u_fm {
  height: 42px;
}

.bootloader_1u_fm {
  display: block !important;
}