._6vg {
  border-radius: 1px
}

._605a ._6vg {
  border-radius: 50%;
  overflow: hidden
}

._2wk7 {
  width: 16px
}

._ezo {
  cursor: pointer
}

._1yib {
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 401
}

._2alo {
  position: absolute
}

._6st5 ._ezo,
._6xrs ._ezo {
  background-position-y: bottom;
  background-repeat: repeat no-repeat;
  background-size: 10px 4px;
  margin-bottom: -2px;
  padding-bottom: 2px;
  white-space: pre-line
}

._2tm9 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-between;
  margin-left: 12px
}

._6jru {
  align-items: center
}

._6jrv {
  align-items: flex-end
}

._4-4u {
  height: 16px;
  width: 16px
}

._4-4u._2wdy {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1838px
}

._4-4u._2wdy:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1838px
}

._4-4u._2wd- {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1838px
}

._4-4u._2wd-:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1838px
}

._4-4u._2wdx {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1838px
}

._4-4u._2wdz {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1821px
}

._4-4u._12a5 {
  color: #1877f2
}

._4-4u._12a6 {
  color: #1877f2
}

._4-4u._12a7 {
  color: #606770
}

._4-4u._14d0 {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: unset
}

._4-4u._6cdx {
  display: inline-block;
  margin-left: -10px;
  vertical-align: top
}

._6hmt {
  margin-left: 10px
}

._1k48 {
  cursor: default
}

._1k48:hover {
  text-decoration: none
}

._6kf_ {
  border: 1px solid #ebedf0;
  margin-left: 2px;
  margin-right: -4px
}

._6n1z {
  height: 44px;
  width: 60px
}

._6ljg {
  height: 40px;
  position: relative;
  width: 40px
}

._6rig {
  background-color: rgba(0, 0, 0, .3);
  background-size: cover;
  height: 40px;
  left: 0;
  margin-left: 3px;
  margin-top: 1px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: .5s ease;
  width: 40px;
  z-index: 1
}

._6ljg:hover ._6rig {
  opacity: 1
}

._6ljh {
  background-color: rgba(0, 0, 0, .3);
  background-size: cover;
  height: 40px;
  left: 0;
  margin-left: 3px;
  margin-top: 1px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
  z-index: 1
}

._605a ._6rig,
._6ljh {
  border-radius: 50%;
  overflow: hidden
}

._6lji {
  height: 40px;
  left: 0;
  margin-top: -1px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
  z-index: 1
}

._56q9 {
  font-size: 14px;
  line-height: 1.358;
  word-break: break-word;
  word-wrap: break-word
}

._44ri {
  border-left: 1px dotted #d3d6db
}

#facebook ._1e_f {
  overflow: visible
}

._2vq9 {
  font-size: 12px;
  line-height: 1.358;
  padding-top: 2px
}

._2vq9 a {
  color: #4267b2
}

._2vq9 .livetimestamp,
._2vq9 .uiLinkSubtle {
  color: #90949c
}

._4iy4 {
  bottom: -2px;
  position: relative
}

._5mlf {
  color: #ccc;
  font-style: italic
}

._4q1v {
  color: #90949c;
  display: inline
}

._4q1v a {
  color: #90949c
}

._5mdd {
  word-wrap: break-word
}

._5mdd ._5v47 {
  font-size: 14px
}

._5mdd a {
  color: #4267b2
}

@media (max-width: 480px) {
  ._4q1v {
    display: block;
    font-size: 11px
  }
}

._2392 {
  margin-bottom: 24px
}

._3j_p {
  margin-left: 56px
}

._4uyl ._1cb {
  border: 1px solid #d3d6db;
  word-wrap: break-word
}

._1zz8 ._5yk2,
._1zz8 ._1u9t {
  min-height: 40px
}

._1zz7 ._5yk2,
._1zz6 ._5yk2 {
  min-height: 16px
}

._1zz7 ._1u9t,
._1zz6 ._1u9t {
  min-height: 48px
}

._1u9t {
  box-sizing: border-box;
  vertical-align: top;
  width: 100%
}

._4uyl ._5yk2,
._4uyl ._1u9t {
  font-size: 16px;
  padding: 12px 8px
}

._4uyl ._1p1t {
  color: #a9a9a9
}

._5tr6 {
  background: #f5f6f7;
  text-align: right
}

._5tr6 button,
._5tr6 ._42fr:active,
._5tr6 ._42fs {
  font-size: 14px;
  white-space: normal
}

._5pu_ {
  color: #999
}

._5sge {
  background-color: #fafafa;
  border-bottom: 1px solid #d3d6db;
  color: #90949c;
  font-size: 11px
}

._4uym {
  border: 1px solid #d3d6db;
  border-top: 0
}

._2ern {
  font-size: 12px
}

._1u0n {
  line-height: 24px
}

._1y5f {
  color: #90949c;
  font-size: 11px
}

._1y5g {
  max-width: 140px
}

@media (max-width: 480px) {
  ._2ern {
    font-size: 11px
  }
}

._5lm5 {
  border-top: 1px solid #e9ebee;
  font-size: 11px
}

._491z {
  border-bottom: 1px solid #e9ebee;
  line-height: 25px;
  margin-bottom: 24px;
  padding: 8px 0
}

._52bm {
  color: #e9ebee;
  padding: 0 8px
}

._pup {
  color: #4b4f56
}

._491z ._33ki {
  font-size: 16px
}

@media (max-width: 480px) {
  ._4k-6 ._5nz1:not(:first-child) {
    border-top: 1px dotted #d3d6db;
    padding-top: 12px
  }
}

._5o4h ._1gl3 {
  background: #3578e5;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
  padding: 12px;
  text-shadow: none;
  width: 100%
}

._ndl {
  margin-top: 24px;
  padding: 11px
}

._3uik {
  background: #f5f6f7;
  border-radius: 1px;
  color: #365899;
  display: block;
  font-weight: bold
}

._1ci {
  height: 48px;
  width: 48px
}

._1cj {
  height: 36px;
  width: 36px
}

@media (max-width: 480px) {

  ._1ci,
  ._1cj {
    height: 32px;
    width: 32px
  }
}

._5yct {
  background-color: #f5f6f7;
  color: #365899
}

._4cqr {
  position: relative
}

._4cqr:after {
  content: '\A';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

._35mm {
  display: inline-block;
  margin-right: 3px
}

._35mm+span:not(.whitespace) {
  margin-left: -3px
}

._35mp {
  height: 16px;
  width: 16px
}

._35mp .UFIActorImage,
.UFIReplyList ._35mp .UFIActorImage {
  border: 1px inset rgba(0, 0, 0, .1);
  height: 14px;
  vertical-align: top;
  width: 14px
}

._35mq {
  margin-left: 3px
}

._45-n,
._2pye,
._1l4x,
._2wdv,
._6nkz,
._qqq {
  height: 16px;
  outline: none;
  width: 16px
}

._45-n {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -2025px
}

._45-n._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -2025px
}

._5vsj .UFIStickersEnabledInput .UFIPhotoAttachLinkWrapper._5t1p {
  display: none !important;
  padding: 0
}

._2wdv {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1906px
}

._6nkz {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1906px
}

._r1a {
  align-items: center;
  cursor: pointer;
  display: flex !important;
  padding: 0 6px !important;
  position: relative
}

._u77 ._r1a {
  padding: 0 4px !important
}

._u77 ._r1a:last-child {
  padding-right: 6px !important
}

._r1a .UFICommentGifIcon {
  margin: 0 !important
}

._2tpi {
  color: #8d949e;
  margin-left: 50px;
  padding-bottom: 2px
}

.UFIReplyList ._2tpi {
  margin-left: 38px
}

._5op2 ._45-n {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1991px
}

._5op2 ._45-n:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -2008px
}

._5op2 ._45-n:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1991px;
  opacity: .5
}

._5op2 ._45-n._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -2008px
}

._5op2 ._qqq {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -2025px
}

._5op2 ._qqq:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2025px
}

._5op2 ._qqq:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -2025px;
  opacity: .5
}

._5op2 ._qqq._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -2025px
}

._2kwm ._45-n {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1855px
}

._2kwm ._45-n:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1855px
}

._2kwm ._45-n:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1855px;
  opacity: .5
}

._2kwm ._45-n._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1855px
}

._2kwm ._1l4x {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1855px
}

._2kwm ._1l4x:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1855px
}

._2kwm ._1l4x:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1855px;
  opacity: .5
}

._2kwm ._6nkz._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1923px
}

._2kwm ._6nkz {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1906px
}

._2kwm ._6nkz:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1923px
}

._2kwm ._6nkz:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1906px;
  opacity: .5
}

._2kwm ._2wdv._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1923px
}

._2kwm ._2wdv {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1906px
}

._2kwm ._2wdv:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1923px
}

._2kwm ._2wdv:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1906px;
  opacity: .5
}

._2kwm ._2wdv._45-o {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1889px
}

._2mdh {
  background: #54c7ec;
  border: 2px #FFFFFF solid;
  border-radius: 6px;
  height: 6px;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 6px
}

._fmi {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dddfe2;
  display: flex;
  flex-wrap: wrap;
  position: relative
}

._69ad ._fmi {
  background-color: #f2f3f5
}

._6q1a ._69ad ._fmi {
  background-color: #f7f6f5
}

._3-a6._1blz ._fmi {
  border-color: #ccd0d5
}

._6q1a ._3-a6._1blz ._fmi {
  border-color: #dfdddb
}

._613v {
  border-radius: 18px;
  padding-left: 5px
}

.UFICommentPhotoAttachedPreview._4soo {
  background-color: #fff;
  border-color: #ccd0d5;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  margin-top: 4px;
  min-height: 10px;
  padding: 10px;
  position: relative
}

.UFICommentPhotoAttachedPreview._4soo img {
  border-radius: 18px
}

.UFIReplyList ._613v {
  border-radius: 12px
}

._3-a6._4eno .UFIReplyList ._613v {
  border-radius: 16px
}

._fmi ._fmk {
  align-self: flex-end;
  display: flex;
  height: 24px;
  margin: 4px 0 4px auto;
  padding-right: 4px;
  position: static
}

._5vsj ._fmk {
  height: 32px;
  margin-bottom: 0;
  margin-top: 0
}

._5vsj ._fmk._6fry {
  height: 29px
}

._fmi ._2xwx {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word
}

._62i8 ._fmi ._2xwx {
  width: 100%
}

._fmi ._2xww {
  border: 0 !important
}

._613v ._2xww {
  background-color: transparent !important;
  line-height: 1.5
}

._fmi ._5yk2 {
  padding-right: 0 !important
}

._13gc {
  align-items: center;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0 6px
}

._u77 ._13gc {
  padding: 0 4px
}

._u77 ._13gc:last-child {
  padding-right: 6px
}

._5op2 .UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1957px;
  height: 16px;
  width: 16px
}

._5op2 .UFICommentFileIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1889px;
  height: 16px;
  width: 16px
}

._5op2 .UFIPhotoAttachLinkWrapper:hover .UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1957px
}

._5op2 .UFIPhotoAttachLinkWrapper:hover .UFICommentFileIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1889px
}

._5op2 .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2008px;
  height: 16px;
  width: 16px
}

._5op2 .UFICommentStickerButton:hover .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -2008px
}

._5op2 .UFICommentStickerButton:active .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2008px;
  opacity: .5
}

._5op2 .UFICommentStickerButton .UFICommentStickerIcon.UFICommentStickerIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -2008px
}

._5op2 .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1991px;
  height: 16px;
  width: 16px
}

._5op2 .UFICommentGifButton:hover .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1991px
}

._5op2 .UFICommentGifButton:active .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1991px;
  opacity: .5
}

._5op2 .UFICommentGifButton .UFICommentGifIcon.UFICommentGifIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1991px
}

._5op2 .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -38px -1800px;
  height: 16px;
  width: 16px
}

._5op2 .UFICommentMentionButton:hover .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1800px
}

._5op2 .UFICommentMentionButton:active .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -21px -1800px
}

._5op2 .UFICommentMentionButton .UFICommentMentionIcon.UFICommentMentionIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -79px -1609px
}

._5-lj .UFICommentEmojiIcon,
._5-lj .UFICommentEmojiIcon:active,
._5-lk .UFICommentStickerIcon,
._5-lk .UFICommentStickerButton:active .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1974px
}

._5-lj .UFICommentEmojiIcon:hover,
._5-lk .UFICommentStickerButton:hover .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1974px
}

._5-lj .UFICommentEmojiIcon.UFICommentEmojiIconActive,
._5-lk .UFICommentStickerButton .UFICommentStickerIcon.UFICommentStickerIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1974px
}

._5-lk .UFICommentEmojiIcon,
._5-lk .UFICommentEmojiIcon:active,
._5-lj .UFICommentStickerIcon,
._5-lj .UFICommentStickerButton:active .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1974px
}

._5-lk .UFICommentEmojiIcon:hover,
._5-lj .UFICommentStickerButton:hover .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1991px
}

._5-lk .UFICommentEmojiIcon.UFICommentEmojiIconActive,
._5-lj .UFICommentStickerButton .UFICommentStickerIcon.UFICommentStickerIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1974px
}

._15i7 .UFICommentBotsIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1821px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentBotsIcon:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1821px
}

._15i7 .UFICommentEmojiIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1872px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentEmojiIcon:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1872px
}

._15i7 .UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1821px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentFileIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1889px;
  height: 16px;
  width: 16px
}

._15i7 .UFIPhotoAttachLinkWrapper:hover .UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1821px
}

._15i7 .UFIPhotoAttachLinkWrapper:hover .UFICommentFileIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1889px
}

._15i7 .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1872px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentGifButton:hover .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1872px
}

._15i7 .UFICommentGifButton:active .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1872px;
  opacity: .5
}

._15i7 .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -38px -1800px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentMentionButton:hover .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1800px
}

._15i7 .UFICommentMentionButton:active .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -21px -1800px
}

._15i7 .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1923px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentStickerButton:hover .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1923px
}

._15i7 .UFICommentStickerButton:active .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1923px;
  opacity: .5
}

._15i7 .UFICommentPrivacyIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1906px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentPrivacyButton:hover .UFICommentPrivacyIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1906px
}

._15i7 .UFICommentPrivacyButton:active .UFICommentPrivacyIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1906px;
  opacity: .5
}

._15i7 .UFICommentPrivacyIcon.UFICommentPrivacyIconVariant {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1889px;
  height: 16px;
  width: 16px
}

._15i7 .UFICommentPrivacyButton:hover .UFICommentPrivacyIcon.UFICommentPrivacyIconVariant {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1889px
}

._15i7 .UFICommentPrivacyButton:active .UFICommentPrivacyIcon.UFICommentPrivacyIconVariant {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -51px -1889px;
  opacity: .5
}

._15i7 .UFICommentPrivacyButton .UFICommentPrivacyIcon.UFICommentPrivacyIconVariant.UFICommentPrivacyIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1906px
}

._15i7 .UFICommentPrivacyButton:active .UFICommentPrivacyIcon.UFICommentPrivacyIconVariant.UFICommentPrivacyIconActive {
  opacity: .5
}

.uiContextualLayerBelowLeft ._54hy ._54ng,
.uiContextualLayerBelowRight ._54hy ._54ng {
  border-top: none
}

.uiContextualLayerAboveLeft ._54hy ._54ng,
.uiContextualLayerAboveRight ._54hy ._54ng {
  border-bottom: none
}


a.uiLinkSubtle {
  color: #90949c
}

a.uiLinkDark {
  color: #1d2129
}

a.uiLinkAccent {
  color: #3578e5
}

._5xew {
  position: absolute;
  z-index: 202
}

._5xex {
  position: fixed
}

.UFIAddComment .UFIAddCommentInput {
  display: block;
  height: 20px
}

.UFICommentTip {
  padding-top: 1px
}

.UFIMentionsInputWrap,
.UFICommentContainer,
.UFIInputContainer {
  position: relative
}

.UFICommentAttachmentButtons {
  bottom: 0;
  height: 24px;
  position: absolute;
  right: 0
}

.UFIPhotoAttachLinkWrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 6px 8px
}

.UFIAddCommentWithPhotoAttacher .innerWrap {
  padding-right: 29px
}

.UFIStickersEnabledInput .innerWrap {
  padding-right: 46px
}

._1rpl {
  background-color: #edf9fd
}

._1rpl ._1rpm {
  border-bottom: 1px solid #27b4e8
}

.UFICommentActorName {
  font-weight: 600;
  white-space: nowrap
}

.UFICommentActorNameSpan {
  display: inline-flex;
  max-width: 100%
}

.UFICommentSocialContext,
.UFICommentSocialContext a {
  color: #90949c
}

.UFICommentActions {
  clear: both;
  padding-top: 2px
}

.UFICommentActions .UFIReactionLink {
  outline: none;
  word-wrap: normal
}

.UFICommentActions .UFIReactionLink::after {
  bottom: 0;
  content: '';
  display: block;
  left: -6px;
  position: absolute;
  right: -6px;
  top: -4px
}

.UFICommentActions .UFILinkBright {
  font-weight: bold
}

.UFICommentActions .UFICommentReactionsBling {
  display: inline-block;
  overflow: visible
}

.UFICommentActions .UFISutroCommentTimestamp,
.UFICommentActions .UFISutroLikeCount {
  color: #616770
}

.UFICommentAuthorWithPresence {
  position: relative
}

.UFICommentAuthorPresence {
  background-color: #42b72a;
  border: 2px solid #f2f3f5;
  border-radius: 9px;
  bottom: -2px;
  display: none;
  height: 9px;
  position: absolute;
  right: -2px;
  width: 9px;
  z-index: 2
}

.UFIReplyList .UFICommentAuthorPresence {
  border-radius: 7px;
  border-width: 1px;
  height: 7px;
  width: 7px
}

.UFICommentAuthorActive {
  display: block
}

.UFICommentAuthorLivingRoomHost {
  background-color: #8c72cb;
  border-color: #fff;
  bottom: -4px;
  display: block;
  right: -4px
}

.UFIContainer .highlightComment {
  background-color: #fff9d7
}

.UFITranslucentComment {
  background-color: #f5f6f7
}

.UFITranslucentComment a,
.UFITranslucentComment .UFIDeletedMessageIcon,
.UFITranslucentComment .UFICommentBody,
.UFITranslucentComment .UFIReplyLink,
.UFITranslucentComment .UFICommentNotSpamLink {
  opacity: .5
}

.UFICommentShowComment {
  margin-left: 3px;
  margin-right: 3px
}

.UFICommentBodyPreserveWhitespace {
  white-space: pre-wrap
}

.UFICommentFailed {
  background: #f5f6f7
}

.UFICommentFailed::after {
  background-color: #aaa7a2;
  bottom: 0;
  content: '';
  left: -13px;
  position: absolute;
  top: 0;
  width: 2px
}

.UFICommentFailed,
.UFICommentFailed .UFICommentActorName {
  color: #7f7f7f
}

.UFIFailureMessage {
  color: #111;
  padding-left: 16px;
  position: relative
}

.UFIPinnedCommentGlyph {
  margin-left: 3px
}

.UFIFailureMessageIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -85px -1821px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 1px;
  vertical-align: middle;
  width: 11px
}

.UFIDeletedMessage {
  position: relative
}

.UFIDeletedMessageIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -85px -1834px;
  height: 12px;
  left: 3px;
  position: absolute;
  top: 1px;
  vertical-align: middle;
  width: 11px
}

.UFICommentContentBlockWithVote {
  display: flex;
  justify-content: space-between
}

.UFICommentContentBlock {
  padding-right: 14px;
  position: relative
}

.UFICommentVerticalVoteFlexGrow {
  flex-grow: 1
}

.UFICommentLiveRedesignBadgedContent {
  padding-bottom: 3px
}

.UFICommentLiveRedesignPaddedBadgedContent {
  padding-bottom: 15px
}

.UFIRow .UFICommentSpamTooltip {
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.UFIRow .UFICommentCloseButton {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.UFIRow .UFICommentCloseButton,
.UFIRow .UFICommentSpamTooltip {
  display: block
}

.openToggler .UFICommentCloseButton,
.UFIRow:hover .UFICommentCloseButton,
.UFIRow .UFICommentCloseButton.openToggler,
.UFIRow .UFICommentCloseButton:focus,
.UFIRow .UFICommentCloseButton.focused,
.UFIRow .UFICommentCloseButton.focused a {
  opacity: 1;
  outline: none
}

.UFICommentCloseButton.UFICommentCloseButtonNux {
  opacity: 1
}

.UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -679px;
  display: block;
  height: 12px;
  width: 15px
}

.UFIStickersEnabledInput .UFIPhotoAttachLinkWrapper {
  padding: 6px 3px
}

.UFICommentStickerLoadingDialog {
  display: block;
  padding: 10px
}

.UFICommentStickerButton {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 5px 6px 5px 4px
}

.UFICommentGifButton {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 0
}

.UFICommentMentionButton {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 0
}

.UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1323px;
  height: 14px;
  width: 14px
}

.UFICommentStickerButton:hover .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1353px
}

.openDialog .UFICommentStickerIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1353px
}

.UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1957px;
  height: 16px;
  margin: 8px 2px 8px 6px;
  width: 16px
}

.UFICommentGifButton:hover .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1957px
}

.openDialog .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1957px
}

.UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -38px -1800px;
  height: 16px;
  margin: 8px 0 8px 0;
  width: 16px
}

.UFICommentMentionButton:hover .UFICommentMentionIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -55px -1800px
}

.openDialog .UFICommentGifIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -79px -1609px
}

.UFIPhotoAttachLinkWrapper:hover .UFICommentPhotoIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -691px
}

.UFICommentPhotoAttachedPreview {
  background-color: #fff;
  border-color: #bdc7d8;
  border-style: solid;
  border-top: 1px solid #eee;
  border-width: 0 1px 1px;
  min-height: 10px;
  position: relative
}

.UFICommentPhotoAttachedPreview .uiCloseButton {
  position: absolute;
  right: 10px;
  top: 10px
}

.UFICommentPhotoAttachedPreview .UFICommentPhotoAttachedPreviewLoadingIndicator {
  height: 21px;
  padding: 33px
}

.UFICommentStarsAttachedPreview {
  align-items: center;
  display: flex;
  flex-direction: row
}

.UFICommentStarsAttachedPreviewImage {
  margin-right: 4px
}

.UFICommentReactionIconContainer {
  bottom: -2px;
  padding-right: 3px;
  position: relative
}

.UFICommentLikeButton {
  margin: -5px;
  padding: 2px 5px 4px;
  white-space: nowrap
}

.UFILiveCommentRedesignLikedButton {
  color: #3578e5
}

.UFICommentReactionsBling a.UFICommentLikeButton {
  color: #365899
}

.UFICommentLikeButton:active,
.UFICommentLikeButton:focus,
.UFICommentLikeButton:hover {
  text-decoration: none
}

.UFIEditNuxContents {
  margin: 15px
}

.UFITranslateAttribution {
  color: #90949c;
  display: block;
  margin: 0
}

.UFITranslatedText {
  border-left: 2px solid #dcdee3;
  display: block;
  margin-top: 2px;
  padding-left: 8px
}

.UFITranslationErrorText {
  color: #90949c;
  display: block;
  margin-top: 2px
}

.UFITranslateLink {
  display: block;
  margin: 0
}

.UFIRow .UFIImageBlockImage {
  margin: 0
}

.UFIRow .UFIImageBlockContent {
  margin: 0;
  padding-left: 8px
}

.UFIShareRow .UFIImageBlockContent {
  padding-left: 4px
}

.UFILikeSentence .UFIImageBlockContent {
  padding-left: 5px
}

.UFIPagerRow .UFIImageBlockContent {
  padding-left: 6px
}

.UFIContainer .UFILivePinnedComment.UFIComment {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  border-left: 12px solid white;
  border-right: 12px solid white;
  border-top: 1px solid #e5e5e5;
  margin: 0
}

.UFICommentLiveVideoAnnouncementBody {
  align-items: center;
  display: flex
}

.UFICommentLiveVideoAnnouncementMessage {
  flex-grow: 1;
  margin-right: 2px
}

.UFIPrivateCommentLabel {
  align-items: center;
  display: flex;
  margin-left: 40px;
  padding-bottom: 2px
}

.UFIPrivateCommentLabel.UFIPrivateCommentActorOnly {
  margin-bottom: -9px
}

.UFIComment .UFICommentContent .UFIPrivateCommentLabel {
  align-items: baseline;
  display: inline-flex;
  margin-left: 0;
  padding-bottom: 0
}

.UFIComment .UFICommentContent .UFIPrivateCommentLabel.UFIPrivateCommentReplyLabel {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle
}

.UFIPrivateCommentLabelIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1131px;
  align-self: center;
  display: inline-block;
  height: 12px;
  width: 12px
}

.UFIPrivateCommentDarkTheme .UFIPrivateCommentLabelIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -81px -1118px
}

.UFIPrivateCommentLabelText {
  color: #616770;
  margin-left: 6px
}

.UFICommentContent .UFIPrivateCommentLabelText {
  margin-left: 4px
}

.UFIPrivateCommentDarkTheme .UFIPrivateCommentLabelText {
  color: #8d949e
}

.UFILivePinnedCommentLabel {
  color: #3578e5;
  display: block;
  margin-bottom: 4px;
  margin-top: 2px
}

.UFICommentShareOptionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px
}

.UFICommentShareOptionsDescription {
  color: #616770
}

.UFICommentShareOptions {
  display: flex
}

.UFICommentShareOptionsItem {
  margin-right: 8px
}

.UFILivePinnedCommentGlyph {
  margin-bottom: -1px;
  margin-right: 4px
}

.UFIBusinessActorIconText {
  color: #27b4e8;
  left: 50%;
  position: absolute;
  top: 54%;
  transform: translate(-50%, -50%);
  -webkit-user-select: none
}

.UFICommentEmojiIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1974px;
  height: 16px;
  width: 16px
}

.UFICommentEmojiIcon:hover {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -1974px
}

.UFICommentEmojiIcon:active {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: 0 -1974px;
  opacity: .5
}

.UFICommentEmojiIcon.UFICommentEmojiIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -17px -1974px
}

.UFICommentFeaturedProductsIcon {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1923px;
  height: 16px;
  width: 16px
}

.UFICommentFeaturedProductsIcon.UFICommentFeaturedProductsIconActive {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -68px -1923px
}

.UFICommentActions {
  min-height: 15px
}

.UFIAuthorPinnedCommentIcon {
  margin-right: 4px;
  position: relative;
  top: 2px
}

._5e-r ._53ij {
  border: none;
  border-radius: 2px;
  overflow: hidden
}

._5e-r ._53ik ._53io {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -75px -1366px;
  bottom: -3px;
  height: 8px;
  position: absolute;
  right: 13px;
  width: 16px
}

._5e-r ._53il ._53io {
  /* background-image: url(../../rsrc.php/v3/yC/r/Y_2rPZLlNeZ.html); */
  background-repeat: no-repeat;
  background-size: 97px 2110px;
  background-position: -34px -2093px;
  height: 8px;
  position: absolute;
  right: 13px;
  top: -1px;
  width: 16px
}

._8mq4 {
  border-top: 1px solid #dadde1
}

._8mq5 {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  box-sizing: border-box;
  cursor: pointer
}

._8ocl {
  border: none
}

._8mq6 {
  height: 120px;
  width: 99px
}

._8mpq {
  height: 180px;
  width: 154px
}

._8mpr {
  background-color: #fef3e9;
  height: 180px;
  width: 154px
}

._8mps {
  background-color: #ebedf0;
  border-radius: 12px;
  height: 24px;
  margin: 0 auto;
  padding: 0 12px
}

._8mpt {
  background-color: #f5c33b
}

._8mpu {
  width: 70%
}

._8mpv {
  width: 77%
}

._8mpw {
  width: 88%
}

._8mpx {
  margin: 0 auto
}

._8mpy {
  margin-right: 5px
}

._8n8m {
  box-shadow: 0 0 0 2px #3578e5
}

a.weakReference {
  color: #7f7f7f;
  white-space: nowrap
}

a.weakReference img:first-child,
a.weakReference i:first-child {
  margin-right: 3px
}

._5yk1 {
  background: #fff;
  border: 1px solid #bdc7d8;
  box-sizing: border-box;
  cursor: text;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  padding: 3px
}

._5yk1 ._1p1u {
  color: #bec3c9
}

._7v3u {
  border-radius: 13px;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px
}

._5v47 {
  display: inline;
  white-space: nowrap
}

.UFICommentContent ._5v47 {
  font-size: 12px
}

._5uzb {
  padding-right: 3px
}

._1ipe {
  font-size: 0;
  visibility: hidden
}

._1x3s {
  display: none
}

._3gl1 {
  background-position: center;
  background-repeat: no-repeat;
  caret-color: var(--primary-text);
  display: inline-block;
  margin: 0 1px;
  text-align: center;
  vertical-align: middle
}

._1mjk ._3gl1 {
  caret-color: #fff
}

._ncl {
  color: transparent;
  display: inline-block;
  transform: translateY(-3px);
  z-index: -1
}

._5zk7 {
  background-color: rgba(88, 144, 255, .15);
  border-bottom: 1px solid rgba(88, 144, 255, .3)
}

._247o {
  background-color: #dce6f8
}

._whq {
  background-color: #eee;
  color: #666
}

._5rp7,
._5rpb,
._5rpu {
  height: inherit;
  text-align: initial
}

._5rpu[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only
}

._5rp7 {
  position: relative
}

._5rpb {
  background-color: rgba(255, 255, 255, 0);
  border-left: .1px solid transparent;
  position: relative;
  z-index: 1
}

._45m_ {
  position: relative
}

._5rp8 ._1mf {
  text-align: left
}

._5rp8 ._1p1t {
  left: 0;
  text-align: left
}

._5rpa ._1mf {
  text-align: center
}

._5rpa ._1p1t {
  margin: 0 auto;
  text-align: center;
  width: 100%
}

._5rp9 ._1mf {
  text-align: right
}

._5rp9 ._1p1t {
  right: 0;
  text-align: right
}

._1p1t {
  color: #90949c;
  position: absolute;
  width: 100%;
  z-index: 1
}

._1p1u {
  color: #bec2c9
}

._32of {
  display: none
}

._1mf {
  position: relative;
  white-space: pre-wrap
}

._1mj {
  direction: ltr;
  text-align: left
}

._1mk {
  direction: rtl;
  text-align: right
}

._3kq6 {
  direction: ltr
}

._3kq7 {
  direction: rtl
}

._1bv0,
._1bv1 {
  margin: 16px 0;
  padding: 0
}

._3kq1._3kq6 {
  margin-left: 1.5em
}

._3kq1._3kq7 {
  margin-right: 1.5em
}

._3kq2._3kq6 {
  margin-left: 3em
}

._3kq2._3kq7 {
  margin-right: 3em
}

._3kq3._3kq6 {
  margin-left: 4.5em
}

._3kq3._3kq7 {
  margin-right: 4.5em
}

._3kq4._3kq6 {
  margin-left: 6em
}

._3kq4._3kq7 {
  margin-right: 6em
}

._3kq5._3kq6 {
  margin-left: 7.5em
}

._3kq5._3kq7 {
  margin-right: 7.5em
}

._3kpz {
  list-style-type: square;
  position: relative
}

._3kpz._3kq1 {
  list-style-type: disc
}

._3kpz._3kq2 {
  list-style-type: circle
}

._3kp_ {
  list-style-type: none;
  position: relative
}

._3kp_._3kq6:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px
}

._3kp_._3kq7:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px
}

._3kp_:before {
  content: counter(ol0) ". ";
  counter-increment: ol0
}

._3kp_._3kq2:before {
  content: counter(ol1, lower-alpha) ". ";
  counter-increment: ol1
}

._3kp_._3kq3:before {
  content: counter(ol2, lower-roman) ". ";
  counter-increment: ol2
}

._3kp_._3kq4:before {
  content: counter(ol3) ". ";
  counter-increment: ol3
}

._3kp_._3kq5:before {
  content: counter(ol4, lower-alpha) ". ";
  counter-increment: ol4
}

._3kq1._3kq0 {
  counter-reset: ol0
}

._3kq2._3kq0 {
  counter-reset: ol1
}

._3kq3._3kq0 {
  counter-reset: ol2
}

._3kq4._3kq0 {
  counter-reset: ol3
}

._3kq5._3kq0 {
  counter-reset: ol4
}

._4438 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 100%
}

._6a-v {
  opacity: .4
}

._3f0q {
  margin-top: 20px
}

._442_ {
  padding-bottom: 5px
}

._4430._5e4h {
  border-color: #ccd0d5;
  border-radius: 18px;
  box-sizing: border-box;
  width: 100%
}

._4430 ._5e4k {
  border-radius: 18px
}

._2yme {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%
}

._2ymf {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

#bootloader_EsP5w {
  height: 42px;
}

.bootloader_EsP5w {
  display: block !important;
}