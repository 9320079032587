.fbcomment body {
  background:#fff;
  color:#1c1e21;
  direction:ltr;
  line-height:1.34;
  margin:0;
  padding:0;
  unicode-bidi:embed;
}

.fbcomment a {
  color:#385898 !important;
  cursor:pointer;
  text-decoration:none;
}

.fbcomment button {
  margin:0;
}

.fbcomment a:hover {
  text-decoration:underline;
}

.fbcomment img {
  border:0;
}

.fbcomment td,
.fbcomment td.label {
  text-align:left;
}

.fbcomment dd {
  color:#000;
}

.fbcomment dt {
  color:#606770;
}

.fbcomment ul {
  list-style-type:none;
  margin:0;
  padding:0;
}

.fbcomment abbr {
  border-bottom:none;
  text-decoration:none;
}

.fbcomment hr {
  background:#dadde1;
  border-width:0;
  color:#dadde1;
  height:1px;
}

.fbcomment ._li._li._li {
  overflow:initial;
}

.fbcomment ._910i._li._li._li {}

.fbcomment ._9053 ._li._li._li {}

.fbcomment ._72b0 {
  position:relative;
  z-index:0;
}

.fbcomment .registration ._li._9bpz {
  background-color:#f0f2f5;
}

.fbcomment ._li ._9bp- {
  padding-top:5px;
  text-align:center;
}

.fbcomment > ._li ._9bp- .fb_logo {
  height:100px;
}

.fbcomment body.plugin {
  background:transparent;
  font-family:Helvetica,Arial,sans-serif;
  line-height:1.28;
  /* overflow:hidden;
  */

-webkit-text-size-adjust:none;
}

.plugin,
.plugin button,
.plugin label,
.plugin textarea {
  font-size:11px;
}

._4qba {
  font-style:inherit;
}

._4qbb,
._4qbc,
._4qbd {
  background:none;
  font-style:inherit;
  padding:0;
  width:auto;
}

._4qbd {
  border-bottom:1px solid #f99;
}

._4qbb,
._4qbc {
  border-bottom:1px solid #999;
}

._4qbb:hover,
._4qbc:hover,
._4qbd:hover {
  background-color:#fcc;
  border-top:1px solid #ccc;
  cursor:help;
}

#bootloader_RufnW {
  height:42px;
}

.bootloader_RufnW {
  display:block !important;
}

overflow-x:hidden