._m {
  position: relative
}

._m:focus-within {
  opacity: .6;
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

._m ._3jk {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

._m ._n {
  bottom: 0;
  cursor: inherit;
  font-size: 1000px !important;
  height: 300px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0
}

._10 {
  height: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 202
}

.platform_dialog ._10 {
  position: absolute
}

._1yv {
  box-shadow: 0 2px 26px rgba(0, 0, 0, .3), 0 0 0 1px rgba(0, 0, 0, .1);
  margin: 0 auto 40px;
  position: relative
}

._t {
  background-color: #fff;
  position: relative
}

._1yw {
  background-color: #6d84b4;
  border: 1px solid #365899;
  border-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold
}

._13,
._14 {
  border-color: #555;
  border-style: solid;
  border-width: 0 1px
}

._13:first-child {
  border-top-width: 1px
}

._13:last-child {
  border-bottom-width: 1px
}

._14 {
  border-bottom-width: 1px
}

._569t ._54ng {
  background-color: #fff;
  border: 1px solid #777;
  border-bottom: 2px solid #293e6a
}

._569t ._54nf {
  padding: 3px 0 4px
}

._569t ._54ak {
  border-bottom: 1px solid #ddd;
  margin: 5px 7px 6px;
  padding-top: 1px
}

._569t ._54nc {
  border: solid #fff;
  border-width: 1px 0;
  color: #111;
  font-weight: normal;
  line-height: 16px;
  padding: 1px 16px 1px 22px
}

._569t ._5arm ._54nc {
  cursor: default;
  opacity: .55
}

._569t ._54ne ._54nc {
  background-color: #4267b2;
  border-color: #29487d;
  color: #fff;
  -webkit-font-smoothing: antialiased
}

._569t ._54nd ._54nc {
  /* background: url(../../rsrc.php/v3/yq/r/kXT5i7nB3hl.html) left 4px no-repeat; */
  font-weight: bold
}

.highContrast ._569t ._54nd ._54nc::before {
  content: '\2713';
  left: 6px;
  position: absolute
}

._569t ._54nd._54ne ._54nc {
  background-color: #4267b2;
  background-position: left -56px
}

._569t ._54ah {
  color: #999
}

._569t ._54nh {
  max-width: 300px
}

._569t ._54hx {
  border-top: 1px solid #777;
  position: absolute;
  right: 0;
  top: -1px
}

.uiContextualLayerAboveLeft ._569t ._54hx,
.uiContextualLayerAboveRight ._569t ._54hx {
  border-top: 2px solid #293e6a;
  top: auto
}

.uiContextualLayerAboveRight ._569t ._54hx,
.uiContextualLayerBelowRight ._569t ._54hx {
  left: 0;
  right: auto
}

.uiOverlay {
  position: relative;
  z-index: 202
}

.uiOverlayContent {
  background: #fff;
  border: 1px solid #8c8c8c;
  border: 1px solid rgba(0, 0, 0, .45);
  border-bottom: 1px solid #666;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
  position: relative
}

.uiOverlayFooter {
  text-align: right
}

.uiOverlayFooterGrid {
  width: 100%
}

.uiOverlayFooterMessage {
  width: 100%
}

.uiOverlayFooterButtons {
  white-space: nowrap
}

._59s7 {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 26px rgba(0, 0, 0, .3), 0 0 0 1px rgba(0, 0, 0, .1);
  font-family: Helvetica, Arial, sans-serif;
  margin: 0 auto 40px;
  position: relative
}

._4t2a {
  background-color: #fff;
  border-radius: 3px;
  position: relative
}

._4-i0 {
  background-color: #f5f6f7;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 3px 3px 0 0;
  color: #1d2129;
  font-weight: bold;
  line-height: 19px;
  padding: 10px 12px
}

._4-i0 ._ohe {
  max-width: 100%
}

._2gb3 ._ohe {
  max-width: calc(100% - 40px)
}

._4-i0 ._52c9 {
  color: #1d2129;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

._2g9z {
  padding: 6px 8px
}

._4-i0 ._2g9- {
  padding: 4px
}

._2g9_ {
  margin-top: 4px
}

._2qes {
  float: left
}

._2qet {
  display: inline-block;
  line-height: 25px;
  margin-left: 10px;
  vertical-align: middle
}

._4-i0._5dwa {
  line-height: 12px
}

._4-i0._5dwa ul {
  float: left
}

div._4-i2 {
  background-color: #fff;
  word-wrap: break-word
}

div._4-i2 div[role="document"] {
  outline: none
}

._5pfh {
  overflow-y: auto
}

._pig {
  padding: 12px
}

._4-i2:first-child {
  border-radius: 3px 3px 0 0
}

._4-i2:last-child {
  border-radius: 0 0 3px 3px
}

._4-i0.accessible_elem:first-child+._4-i2:last-child,
._4-i2:only-child {
  border-radius: 3px
}

div._5a8u {
  background-color: #fff;
  padding: 12px 0
}

html ._27qq {
  border-radius: 0 0 3px 3px;
  margin: 0;
  padding: 12px 12px
}

._3thl {
  overflow: hidden
}

html ._2z1w {
  padding-left: 12px;
  padding-right: 12px
}

._5lnf {
  border-top: 1px solid #dddfe2;
  margin: 0 12px;
  padding: 8px 0
}

._15v0 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center
}

._316b {
  text-shadow: 0 0 4px rgba(0, 0, 0, .5)
}

._15v1 {
  box-sizing: border-box;
  height: 100%;
  padding: 12px;
  width: 100%
}

._4cgy {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle
}

._1lid {
  animation: SUISpinnerRotation 550ms linear infinite;
  -webkit-backface-visibility: hidden;
  display: block;
  will-change: transform
}

@keyframes SUISpinnerRotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

._8036>._4cgy~._4cgy {
  display: none
}

._3qn7 {
  display: flex
}

._8xlz {
  min-height: 0;
  min-width: 0
}

._2fyi {
  flex-direction: row
}

._2fyh {
  flex-direction: column
}

._6xqq {
  flex-direction: row-reverse
}

._6xqp {
  flex-direction: column-reverse
}

._61-0 {
  justify-content: flex-start
}

._61-1 {
  justify-content: center
}

._61-2 {
  justify-content: flex-end
}

._61-3 {
  justify-content: space-between
}

._6twk {
  justify-content: space-around
}

._6twl {
  justify-content: space-evenly
}

._3qng {
  align-items: center
}

._3qnf {
  align-items: flex-start
}

._3qnu {
  align-items: flex-end
}

._1a9e {
  align-items: stretch
}

._7is_ {
  align-items: baseline
}

._4tau {
  flex-wrap: wrap
}

._4tav {
  flex-wrap: wrap-reverse
}

#bootloader_mJvja {
  height: 42px;
}

.bootloader_mJvja {
  display: block !important;
}